import { useMutation } from "@tanstack/react-query";
import { deleteEduIntern } from "../../services/Edu/interns.services";

const useDeleteIntern = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: (internId: string) => deleteEduIntern(internId),
    onSuccess,
  });
};

export default useDeleteIntern;
