import { IEduProjects } from "../../types/types.projects";

export enum ProjectType {
  Shadow = "Shadow",
  Internal = "Internal",
  External = "External",
}

export interface IProjectTable {
  setProject: React.Dispatch<React.SetStateAction<IEduProjects | undefined>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}
