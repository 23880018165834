import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getGroups } from "../../../services/getGroups.services";
import { IGetGroups, IGetGroupsParams } from "../../../types/types.groups";
import { GROUPS_QUERY_KEY } from "./useGetGroups.config";

export default function useGetGroups({
  page = 1,
  pageSize = 20,
  searchQuery = "",
  startDate = "",
  endDate = "",
}: IGetGroupsParams) {
  const queryClient = useQueryClient();
  const { data: groups } = useQuery<IGetGroups>({
    queryKey: [
      GROUPS_QUERY_KEY,
      page,
      pageSize,
      searchQuery,
      startDate,
      endDate,
    ],
    queryFn: () => getGroups(page, pageSize, searchQuery, startDate, endDate),
    onError: () => {
      queryClient.setQueryData([GROUPS_QUERY_KEY], null);
    },
    keepPreviousData: true,
  });

  return groups;
}
