import { AxiosResponse } from "axios";
import adminAxiosInstance from "../../api/admin/admin.instance";
import {
  IGetTeamLeadInterDependency,
  IUpdateTeamLeadParams,
} from "../../types/types.teamleads";
import { TEAMLEADS_URL } from "../../api/constants";
import { downloadFile } from "../../helpers/downloadFile";

export const updateTeamlead = async (body: IUpdateTeamLeadParams) => {
  const { data }: AxiosResponse = await adminAxiosInstance.put(
    `${TEAMLEADS_URL}`,
    {
      ...body,
    }
  );
  return data;
};

export const getTeamLeadInterDependencies = async (teamLeadId: string) => {
  const { data }: AxiosResponse =
    await adminAxiosInstance.get<IGetTeamLeadInterDependency>(
      `${TEAMLEADS_URL}/${teamLeadId}/interDependencies`
    );
  return data;
};

export const activateTeamLead = async (
  teamLeadId: string,
  callbackUrl: string
) => {
  const { data }: AxiosResponse = await adminAxiosInstance.post(
    `${TEAMLEADS_URL}/${teamLeadId}/activate`,
    { callbackUrl }
  );

  return data;
};

export const exportTeamLeads = async () => {
  const response = await adminAxiosInstance.get(`${TEAMLEADS_URL}/export`, {
    responseType: "blob",
    headers: {
      Accept: "*/*",
    },
  });

  downloadFile(response, "EduTeamLeads.xlsx");
};
