import { useMutation } from "@tanstack/react-query";
import { createEduIntern } from "../../services/Edu/interns.services";
import { IEduInternFormPayload } from "../../types/types.eduInterns";

const useAddIntern = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: (intern: IEduInternFormPayload) => createEduIntern(intern),
    onSuccess,
  });
};

export default useAddIntern;
