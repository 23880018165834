import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { TUserAnalytics } from "../../services/getUserAnalytics.services";

interface Props {
  interns: TUserAnalytics["getUsersAnalytics"];
}

const GroupAnalyticsTable = ({ interns }: Props) => {
  return (
    <>
      <Paper className="overflow-hidden">
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="bg-gray-100 font-bold">
              <TableRow>
                <TableCell style={{ minWidth: "100px", fontWeight: "bold" }}>
                  Name
                </TableCell>

                <TableCell style={{ minWidth: "100px" }} align="right">
                  <span className="font-bold">Percentage of total points</span>{" "}
                  <span className="font-light">(Max: 100)</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {interns?.map((intern) => {
                return (
                  <TableRow
                    className="cursor-pointer"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={intern.userId}
                  >
                    <TableCell>
                      {intern.firstName} {intern.lastName}
                    </TableCell>
                    <TableCell align="right">
                      {intern.componentsPercentageOfTotalPoints} %
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default GroupAnalyticsTable;
