import { Button } from "@mui/material";
import Modal from "../Modal";
import { IDeleteModal } from "./DeleteModal.config";

const DeleteModal = ({ onClose, showModal, handleSubmit }: IDeleteModal) => {
  return (
    <Modal onClose={onClose} showModal={showModal} title="Delete Modal">
      <div>
        <h1>Are you sure you want to delete item?</h1>
        <div className="flex flex-row justify-center gap-10 mt-12">
          <Button variant="outlined" onClick={() => handleSubmit()}>
            Submit
          </Button>
          <Button onClick={() => onClose()} variant="outlined" color="error">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
