import { useQuery } from "@tanstack/react-query";
import { SURVEY_CONFIGURATIONS_QUERY_KEY } from "./useSurveyConfigurations.config";
import { getSurveyConfigurations } from "../../../services/Edu/surveyConfiguration.services";

const useSurveyConfigurations = (id: string) => {
  return useQuery({
    queryKey: [SURVEY_CONFIGURATIONS_QUERY_KEY, id],
    queryFn: () => getSurveyConfigurations(id),
  });
};

export default useSurveyConfigurations;
