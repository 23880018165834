import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IAddCourseOverview } from "../../types/types.courseOverviews";
import { addCourseOverviewSubjects } from "../../services/AddCourseOverviewSubjects.services";
import { OVERVIEWS_QUERY_KEY } from "../queries/useGetCourseOverviewSubjects/useGetCourseOverviewSubjects.config";

const useAddCourseOverviewSubject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IAddCourseOverview) => addCourseOverviewSubjects(body),
    onSuccess: () => {
      queryClient.refetchQueries([OVERVIEWS_QUERY_KEY]);
    },
  });
};

export default useAddCourseOverviewSubject;
