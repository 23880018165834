import React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
  ITeamLeadInterDependecyTableProps,
  TEAM_LEAD_DEPENDENCY_TABLE_CONFIG,
} from "./TeamLeadInterDependecyTable.config";

const TeamLeadInterDependecyTable = ({
  data,
}: ITeamLeadInterDependecyTableProps) => {
  return (
    <Paper className="overflow-hidden">
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="bg-gray-100 font-bold">
            <TableRow>
              {TEAM_LEAD_DEPENDENCY_TABLE_CONFIG.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((dep) => {
              return (
                <TableRow
                  className="cursor-pointer"
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={dep.email}
                >
                  <TableCell>{dep.firstName}</TableCell>
                  <TableCell>{dep.lastName}</TableCell>
                  <TableCell>{dep.email}</TableCell>
                  <TableCell>{dep.type}</TableCell>
                  <TableCell>{dep.group?.name || ""}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TeamLeadInterDependecyTable;
