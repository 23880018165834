import { IStack } from "./types.stacks";

export const eduSquadLeadSeniorities = [1, 2, 3] as const;

export const eduSquadLeadSeniorityOptions = [
  {
    id: "eduSquadLead-junior",
    label: "Junior",
    value: eduSquadLeadSeniorities[0],
  },
  {
    id: "eduSquadLead-middle",
    label: "Middle",
    value: eduSquadLeadSeniorities[1],
  },
  {
    id: "eduSquadLead-senior",
    label: "Senior",
    value: eduSquadLeadSeniorities[2],
  },
];

export interface IEduSquadLead {
  eduStack: IStack;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirthUtc: string;
  idNumber: string;
  eduSquadLeadSeniority: 1 | 2 | 3;
  userId: string | null;
}
