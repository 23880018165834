import { useParams } from "react-router-dom";
import { IGetMentor } from "../../types/types.mentors";
import { getMentor } from "../../services/Edu/mentors.services";
import { useQuery } from "@tanstack/react-query";
import MentorInternsTable from "../../components/Mentor/MentorInternsTable";

const MentorInterns = () => {
  const { id = "" } = useParams<{ id: string }>();

  const { data: { eduMentor } = {} } = useQuery<IGetMentor>({
    queryKey: ["getMentor"],
    queryFn: () => getMentor(id),
    enabled: !!id,
  });

  const { firstName = "", lastName = "", eduInterns } = eduMentor || {};

  return (
    <section className="p-5">
      <div className="flex justify-between  items-center">
        <h1 className="py-5 text-3xl font-bold">{`${firstName} ${lastName} - Interns`}</h1>
      </div>
      <MentorInternsTable interns={eduInterns || []} />
    </section>
  );
};

export default MentorInterns;
