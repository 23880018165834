import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { GROUP_URL } from "../api/constants";
import { IAddGroup } from "../types/types.groups";

export const addGroup = async (body: IAddGroup) => {
  const { data }: AxiosResponse<IAddGroup> =
    await adminAxiosInstance.post(`${GROUP_URL}`,{
      ...body
    });
  return data;
};
