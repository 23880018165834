import React, { FC } from "react";
import { ISurveyQuestionAnswer } from "../../types/types.survey";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import useDeleteSurveyQuestionAnswer from "../../hooks/mutations/useDeleteSurveyQuestionAnswer";
import { toast } from "react-toastify";

interface AnswerProps {
  answer: ISurveyQuestionAnswer;
  onChangeText: (answerId: string, text: string) => void;
  onChangeCorrectness: (answerId: string, isCorrect: boolean) => void;
  onDeleteAnswer: (answerId: string) => void;
}

const Answer: FC<AnswerProps> = ({
  answer,
  onChangeCorrectness,
  onChangeText,
  onDeleteAnswer,
}) => {
  const { mutateAsync, isLoading } = useDeleteSurveyQuestionAnswer();

  const deleteAnswer = async () => {
    // check if question or answer is local or not first
    if (!answer.id.includes("local")) {
      // delete on server first
      try {
        await mutateAsync(answer.id);

        toast.success("Answer deleted successfully", {
          position: "bottom-right",
          autoClose: 3000,
          closeOnClick: true,
        });
      } catch (ex) {
        console.warn(ex);

        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 3000,
          closeOnClick: true,
        });
      }
    }
    onDeleteAnswer(answer.id);
  };
  return (
    <div className="bg-slate-200 rounded-md p-2">
      <div className="flex items-center gap-4">
        <TextField
          value={answer.value}
          onChange={(e) => onChangeText(answer.id, e.target.value)}
          label="Answer"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={answer.isCorrect}
              onChange={(e) => onChangeCorrectness(answer.id, e.target.checked)}
            />
          }
          label="Correct"
          labelPlacement="start"
        />
      </div>
      <div className="mt-4 flex items-center justify-end">
        <Button
          variant="outlined"
          color="error"
          disabled={isLoading}
          onClick={deleteAnswer}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default Answer;
