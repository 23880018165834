import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { MENTOR_URL } from "../api/constants";
import { IGetMentors } from "../types/types.mentors";

export const getMentors = async (page?: number, pageSize?: number, searchQuery?: string, status?: string, seniority?: string) => {
  const { data }: AxiosResponse<IGetMentors> = await adminAxiosInstance.get(
    `${MENTOR_URL}`,
    {
      params: {
        page,
        pageSize,
        searchQuery,
        status,
        seniority,
      },
    }
  );
  return data;
};
