import Modal from "../Modal";
import { IGroupModal } from "./GroupModal.config";
import { Button, Input } from "@mui/material";
import useAddGroup from "../../hooks/mutations/useAddGroup";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import useUpdateGroup from "../../hooks/mutations/useUpdateGroup";
import { dateFormatter } from "../../helpers";

const GroupModal = ({
  showModal,
  title,
  groupInfo,
  onClose,
  setGroupInfo,
}: IGroupModal) => {
  const { mutate: addGroup } = useAddGroup();
  const { mutate: updateGroup } = useUpdateGroup();

  const { handleSubmit, register, reset, setValue } = useForm({
    defaultValues: {
      name: "",
      description: "",
      startDate: "",
      endDate: "",
    },
  });

  const handleCancelClick = () => {
    setGroupInfo(undefined);
    reset();
    onClose(false);
  };

  const onSubmit = (body: any) => {
    if (groupInfo) {
      updateGroup({ ...body, id: groupInfo.id });
    } else {
      addGroup({ ...body });
    }

    reset();
    onClose(false);
  };

  useEffect(() => {
    if (groupInfo) {
      setValue("name", groupInfo.name);
      setValue("description", groupInfo.description);
      setValue("startDate", dateFormatter(groupInfo.startDate));
      setValue("endDate", dateFormatter(groupInfo.endDate));
    }
  }, [setValue, groupInfo]);

  if (!showModal) return null;

  return (
    <Modal showModal={showModal} onClose={handleCancelClick} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-16">
          <div className="flex flex-col">
            <label htmlFor="name">Name</label>
            <Input id="name" required {...register("name")} />
          </div>
          <div className="flex flex-col">
            <label htmlFor="description">Description</label>
            <Input id="description" required {...register("description")} />
          </div>
          <div className="flex flex-row gap-10">
            <div className="flex flex-col">
              <label htmlFor="description">Start Date</label>
              <Input type="date" {...register("startDate")} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="description">End Date</label>
              <Input type="date" {...register("endDate")} />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end gap-3 mt-10">
          <Button type="submit" variant="contained">
            Submit
          </Button>
          <Button variant="outlined" color="error" onClick={handleCancelClick}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default GroupModal;
