import { useQuery } from "@tanstack/react-query";
import { getUser } from "../../../services/user.services";

const USER_QUERY_KEY = "USER";

const useUser = (userId: string) => {
  return useQuery({
    queryKey: [USER_QUERY_KEY, userId],
    queryFn: () => getUser(userId),
    enabled: !!userId,
  });
};

export default useUser;
