import { ISquadLead, ISquadLeadFormPayload } from "../types/types.squadLeads";
import { dateFormatter } from "./dateFormatter";

export function transformSquadLeadObjectForForm(
  mentor: ISquadLead
): ISquadLeadFormPayload {
  const {
    firstName,
    lastName,
    email,
    idNumber,
    phoneNumber,
    id,
    eduStack: stack,
    dateOfBirthUtc,
    eduSquadLeadSeniority,
  } = mentor;

  return {
    id,
    firstName,
    lastName,
    email,
    idNumber,
    phoneNumber,
    dateOfBirthUtc: dateFormatter(dateOfBirthUtc),
    eduStackId: stack?.id || null,
    eduSquadLeadSeniority,
  };
}
