import { ChangeEvent, useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";
import RatingInput from "./RatingInput";
import { RatingsTableProps } from "./RatingsTable.config";
import { dateFormatter } from "../../helpers";

const RatingsTable = ({ interns, components }: RatingsTableProps) => {
  const [currentDate, setCurrentDate] = useState(dateFormatter(new Date().toString()));
  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentDate(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          width: 150,
          marginLeft: "auto",
          marginBottom: "25px",
        }}
      >
        <div className="flex flex-row gap-10 mt-2">
          <div className="flex flex-col">
            <label htmlFor="date">Date</label>
            <Input
              type="date"
              id="date"
              value={currentDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
      </Box>
      <Paper className="overflow-hidden">
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className="bg-gray-100 font-bold">
              <TableRow>
                <TableCell style={{ minWidth: "100px" }}></TableCell>
                {components.map((component) => (
                  <TableCell key={component.id} style={{ minWidth: "100px" }}>
                    <span className="font-bold">{component.name}</span>{" "}
                    <span className="font-light">
                      (Max: {component.maxPoint})
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {interns.map((intern) => {
                return (
                  <TableRow
                    className="cursor-pointer"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={intern.id}
                  >
                    <TableCell>
                      {intern.firstName} {intern.lastName}
                    </TableCell>
                    {components.map((component) => {
                      return (
                        <TableCell key={component.id}>
                          <RatingInput
                            componentId={component.id}
                            userId={intern.userId}
                            date={currentDate}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default RatingsTable;
