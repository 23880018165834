import React, { FC, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { produce } from "immer";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

import {
  ISurveyQuestion,
  ISurveyQuestionAnswer,
  SurveyQuestionType,
  SurveyType,
} from "../../types/types.survey";
import Question from "./Question.component";
import useCreateSurveyQuestions from "../../hooks/mutations/useCreateSurveyQuestion";
import useUpdateSurveyQuestion from "../../hooks/mutations/useUpdateSurveyQuestion";
import { objectEquals } from "../../helpers";

import { SURVEYS_QUERY_KEY } from "../../hooks/queries/surveys/useSurveys.config";
import {
  CreateSurveyQuestionAnswerPayload,
  UpdateSurveyQuestionAnswerPayload,
} from "../../services/Edu/surveyQuestionAnswers.services";
import useCreateSurveyQuestionAnswers from "../../hooks/mutations/useCreateSurveyQuestionAnswers";
import useUpdateSurveyQuestionAnswers from "../../hooks/mutations/useUpdateSurveyQuestionAnswers";
import useUploadQuestionAttachment from "../../hooks/mutations/useUploadQuestionAttachment";

interface SurveyQuestionsEditorProps {
  questions: ISurveyQuestion[];
  surveyId: string;
  surveyType: SurveyType;
}

const SurveyQuestionsEditor: FC<SurveyQuestionsEditorProps> = ({
  questions: serverQuestions,
  surveyId,
  surveyType,
}) => {
  const [questions, setQuestions] = useState<
    (ISurveyQuestion & { localAttachment?: File })[]
  >([]);

  const queryClient = useQueryClient();

  useEffect(() => {
    setQuestions(serverQuestions);
  }, [serverQuestions]);

  const { mutateAsync: createQuestions, isLoading: isCreatingQuestions } =
    useCreateSurveyQuestions();
  const { mutateAsync: updateQuestions, isLoading: isUpdatingQuestions } =
    useUpdateSurveyQuestion();

  const { mutateAsync: uploadAttachment } = useUploadQuestionAttachment();

  const { mutateAsync: createAnswers, isLoading: isCreatingAnswers } =
    useCreateSurveyQuestionAnswers();
  const { mutateAsync: updateAnswers, isLoading: isUpdatingAnswers } =
    useUpdateSurveyQuestionAnswers();

  const handleAddNewQuestion = () => {
    const newQuestion: ISurveyQuestion = {
      id: `local-question-${uuidv4()}`,
      questionType: SurveyQuestionType.MultipleChoice,
      text: "",
      surveyQuestionAnswers: [],
    };

    setQuestions((prev) => [...prev, newQuestion]);
  };

  const handleAddNewQuestionAnswer = (questionId: string) => {
    const changeQuestionIndex = questions.findIndex((q) => q.id === questionId);

    if (changeQuestionIndex === -1) return;

    const newQuestionAnswer: ISurveyQuestionAnswer = {
      id: `local-answer-${uuidv4()}`,
      value: "",
      isCorrect: false,
    };

    const updatedQuestions = produce(questions, (draft) => {
      draft[changeQuestionIndex].surveyQuestionAnswers.push(newQuestionAnswer);
    });

    setQuestions(updatedQuestions);
  };

  const handleQuestionTextChange = (id: string, text: string) => {
    const changeIndex = questions.findIndex((q) => q.id === id);

    if (changeIndex === -1) return;

    const updatedQuestions = produce(questions, (draft) => {
      draft[changeIndex].text = text;
    });

    setQuestions(updatedQuestions);
  };

  const handleQuestionTypeChange = (id: string, type: SurveyQuestionType) => {
    const changeIndex = questions.findIndex((q) => q.id === id);

    if (changeIndex === -1) return;

    const updatedQuestions = produce(questions, (draft) => {
      draft[changeIndex].questionType = type;
    });

    setQuestions(updatedQuestions);
  };

  const handleQuestionDelete = (questionId: string) => {
    const deleteQuestionIndex = questions.findIndex((q) => q.id === questionId);

    if (deleteQuestionIndex === -1) return;

    const updatedQuestions = produce(questions, (draft) => {
      draft.splice(deleteQuestionIndex, 1);
    });

    setQuestions(updatedQuestions);
  };

  const handleAnswerTextChange = (
    questionId: string,
    answerId: string,
    text: string
  ) => {
    const changeQuestionIndex = questions.findIndex((q) => q.id === questionId);

    if (changeQuestionIndex === -1) return;

    const changeAnswerIndex = questions[
      changeQuestionIndex
    ].surveyQuestionAnswers.findIndex((a) => a.id === answerId);

    const updatedQuestions = produce(questions, (draft) => {
      draft[changeQuestionIndex].surveyQuestionAnswers[
        changeAnswerIndex
      ].value = text;
    });

    setQuestions(updatedQuestions);
  };

  const handleAnswerDelete = (questionId: string, answerId: string) => {
    const questionIndex = questions.findIndex((q) => q.id === questionId);

    if (questionIndex === -1) return;

    const deleteAnswerIndex = questions[
      questionIndex
    ].surveyQuestionAnswers.findIndex((a) => a.id === answerId);

    if (deleteAnswerIndex === -1) return;

    const updatedQuestions = produce(questions, (draft) => {
      draft[questionIndex].surveyQuestionAnswers.splice(deleteAnswerIndex, 1);
    });

    setQuestions(updatedQuestions);
  };

  const handleAnswerCorrectnessChange = (
    questionId: string,
    answerId: string,
    isCorrect: boolean
  ) => {
    const changeQuestionIndex = questions.findIndex((q) => q.id === questionId);

    if (changeQuestionIndex === -1) return;

    const changeAnswerIndex = questions[
      changeQuestionIndex
    ].surveyQuestionAnswers.findIndex((a) => a.id === answerId);

    const updatedQuestions = produce(questions, (draft) => {
      draft[changeQuestionIndex].surveyQuestionAnswers[
        changeAnswerIndex
      ].isCorrect = isCorrect;
    });

    setQuestions(updatedQuestions);
  };

  const handleFileSelect = (questionId: string, file: File) => {
    const updatedQuestions = produce(questions, (draft) => {
      const questionToUpdate = draft.find((q) => q.id === questionId);

      if (!questionToUpdate) {
        return;
      }

      questionToUpdate.localAttachment = file;
    });

    setQuestions(updatedQuestions);
  };

  const uploadNewQuestions = async () => {
    const newQuestions = questions.filter(({ id }) => id.includes("local"));

    const requests = await Promise.allSettled(
      newQuestions.map(async ({ questionType, text, id, localAttachment }) => {
        const surveyQuestionAnswers =
          questions.find((q) => q.id === id)?.surveyQuestionAnswers || [];

        return await createQuestions({
          surveyId,
          questionType,
          text,
          surveyQuestionAnswers,
          attachment: localAttachment,
        });
      })
    );

    if (requests.some((req) => req.status === "rejected")) {
      throw new Error("Error while creating questions");
    }
  };

  const updateExistingQuestions = async () => {
    const existingQuestions = questions.filter(
      ({ id }) => !id.includes("local")
    );

    const editedExistingQuestions = existingQuestions.filter((q) => {
      const serverQuestion = serverQuestions.find((sq) => sq.id === q.id);

      if (!serverQuestion) return false;

      return (
        q.text !== serverQuestion.text ||
        q.questionType !== serverQuestion.questionType ||
        !!q.localAttachment
      );
    });

    try {
      await Promise.allSettled(
        editedExistingQuestions
          .filter((q) => !!q.localAttachment)
          .map(
            async (q) =>
              await uploadAttachment({
                Id: q.id,
                Attachment: q.localAttachment!,
              })
          )
      );

      await updateQuestions(
        editedExistingQuestions.map(({ id, text, questionType }) => ({
          id,
          payload: { text, questionType },
        }))
      );
    } catch (error) {
      throw new Error("Could not update questions");
    }
  };

  const uploadNewAnswers = async () => {
    const newAnswers = questions
      .filter((q) => !q.id.includes("local"))
      .map((q) => {
        return q.surveyQuestionAnswers
          .filter((a) => a.id.includes("local"))
          .map((a) => ({ ...a, surveyQuestionId: q.id }));
      })
      .flat();

    const newAnswersPayload: CreateSurveyQuestionAnswerPayload[] =
      newAnswers.map(({ surveyQuestionId, isCorrect, value }) => ({
        surveyQuestionId,
        isCorrect,
        value,
      }));

    try {
      await createAnswers(newAnswersPayload);
    } catch (error) {
      throw new Error("Could not create answers");
    }
  };

  const updateExistingAnswers = async () => {
    const existingAnswers = questions
      .map((q) => {
        return q.surveyQuestionAnswers.filter((a) => !a.id.includes("local"));
      })
      .flat();

    const serverAnswers = serverQuestions
      .map((sq) => sq.surveyQuestionAnswers)
      .flat();

    const editedExistingAnswers = existingAnswers.filter((a) => {
      const serverAnswer = serverAnswers.find((sa) => sa.id === a.id);

      if (!serverAnswer) return false;

      return !objectEquals(a, serverAnswer);
    });

    const existingAnswersPayload: UpdateSurveyQuestionAnswerPayload[] =
      editedExistingAnswers.map(({ id, isCorrect, value }) => ({
        answerId: id,
        isCorrect,
        value,
      }));

    try {
      await updateAnswers(existingAnswersPayload);
    } catch (error) {
      throw new Error("Could not update answers");
    }
  };

  const handleSaveClick = async () => {
    try {
      const requests = await Promise.allSettled([
        await uploadNewQuestions(),
        await updateExistingQuestions(),
        await uploadNewAnswers(),
        await updateExistingAnswers(),
      ]);

      if (requests.some((req) => req.status === "rejected")) {
        throw new Error("An error occurred");
      }

      toast.success(
        `${
          surveyType === SurveyType.Exam ? "Exam" : "Survey"
        }  updated successfully`,
        {
          position: "bottom-right",
          autoClose: 3000,
          closeOnClick: true,
        }
      );
    } catch (error) {
      toast.error(
        `Error while updating ${
          surveyType === SurveyType.Exam ? "exam" : "survey"
        }`,
        {
          position: "bottom-right",
          autoClose: 3000,
          closeOnClick: true,
        }
      );
    } finally {
      queryClient.refetchQueries([SURVEYS_QUERY_KEY]);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      {questions.map((q) => (
        <Question
          key={q.id}
          question={q}
          onChangeText={handleQuestionTextChange}
          onChangeType={handleQuestionTypeChange}
          onChangeAnswerText={handleAnswerTextChange}
          onChangeAnswerCorrectness={handleAnswerCorrectnessChange}
          onDeleteQuestion={handleQuestionDelete}
          onAddAnswer={handleAddNewQuestionAnswer}
          onDeleteAnswer={handleAnswerDelete}
          onFileSelect={handleFileSelect}
        />
      ))}
      <div className="mt-4 flex items-center justify-end gap-4">
        <Button variant="outlined" onClick={handleAddNewQuestion}>
          Add new question
        </Button>
        <Button
          disabled={
            isCreatingQuestions ||
            isUpdatingQuestions ||
            isCreatingAnswers ||
            isUpdatingAnswers
          }
          variant="contained"
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default SurveyQuestionsEditor;
