import { ISquadLead } from "../../../types/types.squadLeads";

interface Column {
  id: keyof ISquadLead;
  label: string;
  minWidth?: number;
  align?: "right";
}
export const SQUAD_LEADS_TABLE_CONFIG: Column[] = [
  { id: "firstName", label: "First Name", minWidth: 150 },
  { id: "lastName", label: "Last Name", minWidth: 150 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "eduStack", label: "Stack", minWidth: 100 },
  {
    id: "phoneNumber",
    label: "Phone Number",
    minWidth: 170,
    align: "right",
  },
  {
    id: "idNumber",
    label: "ID-Number",
    minWidth: 170,
    align: "right",
  },
];
