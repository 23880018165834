import { Editor } from "@tinymce/tinymce-react";
import { useParams } from "react-router-dom";
import useGetCourseOverviewSubject from "../../hooks/queries/useGetCourseOverviewSubject";
import useUpdateCourseOverviewSubject from "../../hooks/mutations/useUpdateCourseOverviewSubject";
import { useForm, Controller } from "react-hook-form";
import { Button, Checkbox, Input } from "@mui/material";
import { useEffect, useState } from "react";
import {initialTable} from "../../helpers/initialTable";

type FormData = {
  name: string;
  description: string;
  eduStackId: string;
  position: number;
  subjectId: string;
};

const CourseOverviewSubjectForm = () => {
  const { subjectId = "", id = "" } = useParams<{
    subjectId: string;
    id: string;
  }>();
  const {
    data: subject,
    isLoading,
    error,
  } = useGetCourseOverviewSubject(subjectId);
  const { handleSubmit, register, reset, setValue, control } =
    useForm<FormData>({
      defaultValues: {
        name: "",
        description: "",
        position: 0,
        eduStackId: id,
        subjectId,
      },
    });

  const [checked, setChecked] = useState(false);

  const { mutate: updateSubject } = useUpdateCourseOverviewSubject();

  useEffect(() => {
    if (subject) {
      setValue("name", subject?.courseOverview?.name);
      setValue("description", subject?.courseOverview?.description);
      setValue("position", subject?.courseOverview?.position);
    }
  }, [subject, setValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  }

  const onSubmit = (body: FormData) => {
    updateSubject(body);
  };

  if (isLoading) return <div>...</div>;
  if (error) return <div>error</div>;

  return (
    <section className="flex justify-between p-9 items-center">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col">
            <label htmlFor="name">Name</label>
            <Input id="name" required {...register("name")} />
          </div>
          <div className="flex flex-col">
            <label htmlFor="priority">Priority</label>
            <Input
              id="priority"
              required
              type="number"
              {...register("position")}
            />
          </div>
          <div className="flex items-center">
            <label htmlFor="table">Create table</label>
            <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="description">Description</label>
            <Controller
              name="description"
              control={control}
              rules={{ required: "required" }}
              render={({ field: { onChange } }) => (
                <Editor
                  id="description"
                  apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                  cloudChannel="6-dev"
                  initialValue={!checked ? (subject?.courseOverview?.description || "") : initialTable}
                  init={{
                    menubar: true,
                    height: 400,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "code",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | removeformat | forecolor | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help",
                  }}
                  onEditorChange={onChange}
                />
              )}
            ></Controller>
          </div>
        </div>
        <div className="flex flex-row justify-end gap-3 mt-10">
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </div>
      </form>
    </section>
  );
};

export default CourseOverviewSubjectForm;
