import { AxiosResponse } from "axios";
import adminAxiosInstance from "../../api/admin/admin.instance";
import { SURVEY_URL } from "../../api/constants";
import { IPaginationInput } from "../../types/types.pagination";
import {
  IPaginatedSurveys,
  ISurvey,
  ISurveyFormPayload,
  createSurveyPayload,
} from "../../types/types.survey";

export const getPaginatedSurveys = async ({
  currentPage,
  pageSize,
  searchQuery,
  type
}: IPaginationInput): Promise<IPaginatedSurveys> => {
  const { data }: AxiosResponse<IPaginatedSurveys> =
    await adminAxiosInstance.get(SURVEY_URL, {
      params: { page: currentPage, pageSize, searchQuery, type },
    });

  return data;
};

export const createSurvey = async (payload: createSurveyPayload) => {
  const { data } = await adminAxiosInstance.post(SURVEY_URL, payload);

  return data;
};

export const updateSurvey = async (
  surveyId: string,
  payload: ISurveyFormPayload
) => {
  const { data } = await adminAxiosInstance.put(
    `${SURVEY_URL}/${surveyId}`,
    payload
  );

  return data;
};

export const deleteSurvey = async (id: string) => {
  const { data } = await adminAxiosInstance.delete(`${SURVEY_URL}/${id}`);

  return data;
};

export const getSurvey = async (id: string) => {
  const { data }: AxiosResponse<{ survey: ISurvey }> =
    await adminAxiosInstance.get(`${SURVEY_URL}/${id}`);

  return data;
};
