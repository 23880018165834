import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  UploadQuestionAttachmentParams,
  uploadQuestionAttachment,
} from "../../services/Edu/surveyQuestions.services";

const useUploadQuestionAttachment = () => {
  return useMutation<void, AxiosError, UploadQuestionAttachmentParams>({
    mutationFn: uploadQuestionAttachment,
  });
};

export default useUploadQuestionAttachment;
