import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { TEAMLEADS_URL } from "../api/constants";

export const deleteTeamlead = async (id: string) => {
  const { data }: AxiosResponse = await adminAxiosInstance.delete(
    `${TEAMLEADS_URL}/${id}`
  );
  return data;
};
