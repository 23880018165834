import { IEduIntern, IEduInternFormPayload } from "../types/types.eduInterns";
import { dateFormatter } from "./dateFormatter";

export function transformInternObjectForForm(
  intern: IEduIntern
): IEduInternFormPayload {
  const {
    firstName,
    lastName,
    email,
    idNumber,
    phoneNumber,
    eduInternStatus,
    userStatus,
    id,
    eduStack: stack,
    eduProjects,
    region,
    eduGroup,
    eduTeamLead,
    eduSquadLead,
    eduMentor,
    internshipEndDateUtc,
    internshipStartDateUtc,
    dateOfBirthUtc,
  } = intern;

  return {
    id,
    firstName,
    lastName,
    email,
    idNumber,
    phoneNumber,
    eduInternStatus,
    userStatus,
    stackId: stack?.id || null,
    projectIds: eduProjects?.map((project) => project.id) || null,
    regionId: region?.id || null,
    groupId: eduGroup?.id || null,
    teamLeadId: eduTeamLead?.id || null,
    squadLeadId: eduSquadLead?.id || null,
    mentorId: eduMentor?.id || null,
    dateOfBirth: dateFormatter(dateOfBirthUtc),
    internshipEndDate: dateFormatter(internshipEndDateUtc),
    internshipStartDate: dateFormatter(internshipStartDateUtc),
  };
}
