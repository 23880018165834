import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PROJECTS_QUERY_KEY } from "../queries/useGetProjects/useGetProjects.config";
import { updateProject } from "../../services/updateProject.services";
import { IUpdateProject } from "../../types/types.projects";

const useUpdateProject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IUpdateProject) => updateProject(body),
    onSuccess: () => {
      queryClient.refetchQueries([PROJECTS_QUERY_KEY]);
    },
  });
};

export default useUpdateProject;
