import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { PROJECT_URL } from "../api/constants";
import { IGetProjectsResponse } from "../types/types.projects";
import { IEduProjects } from "../types/types.projects";

export const getProjects = async ({ page = 1, pageSize = 20 }) => {
  const { data }: AxiosResponse<IGetProjectsResponse> =
    await adminAxiosInstance.get(
      `${PROJECT_URL}?page=${page}&pageSize=${pageSize}`
    );
  return data;
};

export const getProject = async (projectId: string) => {
  const { data }: AxiosResponse<{ eduProject: IEduProjects }> =
    await adminAxiosInstance.get(`${PROJECT_URL}/${projectId}`);

  return data;
};
