import { useQuery } from "@tanstack/react-query";
import { SQUAD_LEAD_QUERY_KEY } from "./useGetSquadLead.config";
import { getSquadLead } from "../../../services/Edu/squadLeads.services";

const useGetSquadLead = (id: string) => {
  return useQuery({
    queryKey: [SQUAD_LEAD_QUERY_KEY, id],
    queryFn: () => getSquadLead(id),
  });
};

export default useGetSquadLead;
