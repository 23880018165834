import { Button, Input, InputLabel, MenuItem, Select } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import { IStackModal } from "./StackModal.config";
import useAddStack from "../../hooks/mutations/useAddStack";
import useUpdateStack from "../../hooks/mutations/useUpdateStack";

const StackModal = ({ title, showModal, onClose, stack }: IStackModal) => {
  const [stackId, setStackId] = useState("");
  const { handleSubmit, register, reset, setValue } = useForm();

  const { mutate: addStack } = useAddStack();
  const { mutate: updateStack } = useUpdateStack();
  const handleCloseModal = () => {
    setStackId("");
    reset();
    onClose();
  };

  const onSubmit = (body: any) => {
    if (stack) {
      updateStack({ ...body, eduStackType: +body.eduStackType });
    } else {
      addStack({ ...body, eduStackType: +body.eduStackType });
    }
    handleCloseModal();
  };

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStackId(event.target.value);
  };

  useEffect(() => {
    if (stack) {
      for (const [key, value] of Object.entries(stack)) {
        setValue(key, value);
      }
      setStackId(stack.eduStackType.toString());
    }
  }, [setValue, stack]);

  return (
    <Modal onClose={handleCloseModal} showModal={showModal} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-16">
          <div className="flex flex-row gap-10">
            <div className="flex flex-col">
              <label htmlFor="name">Name</label>
              <Input id="name" required {...register("name")} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="description">Description</label>
              <Input id="description" required {...register("description")} />
            </div>
          </div>
          <div>
            <InputLabel id="demo-simple-select-label">Stack</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={stackId}
              label="Stack"
              className="w-full"
              {...register("eduStackType", {
                onChange: handleSelectChange,
              })}
            >
              <MenuItem value="1">Digital Marketing</MenuItem>
              <MenuItem value="2">Software</MenuItem>
            </Select>
          </div>
        </div>
        <div className="flex flex-row justify-end gap-3 mt-14">
          <Button type="submit" variant="contained">
            Submit
          </Button>
          <Button variant="outlined" color="error" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default StackModal;
