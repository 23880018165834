import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { PROJECT_URL } from "../api/constants";

export const deleteProject = async (id: string) => {
  const { data }: AxiosResponse = await adminAxiosInstance.delete(
    `${PROJECT_URL}/${id}`
  );
  return data;
};
