import { AxiosResponse } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import adminAxiosInstance from "../../api/admin/admin.instance";
import { getAuthData } from "../../services/auth.services";
import useJWT from "../useJWT";
import { IUseAuth, IAuthRequestBody } from "./useAuth.config";
import { IAdminUser, IUser } from "../../types/types.user";
import { ADMIN_USER_QUERY_KEY } from "../queries/useAdminUser/useAdminUser.config";
import { clearUserPersistenceStatus, setStoredUser } from "../../helpers";
import { USER_URL } from "../../api/constants";

export const useAuth = (): IUseAuth => {
  const decodeJWT = useJWT();
  const queryClient = useQueryClient();

  async function authServerCall({
    email,
    password,
  }: IAuthRequestBody): Promise<IAdminUser> {
    const { token } = await getAuthData(email, password);
    const { role, userId } = decodeJWT(token);

    const { data: userData }: AxiosResponse<{ user: IUser }> =
      await adminAxiosInstance.get(`${USER_URL}/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    const adminUser: IAdminUser = {
      email: userData.user.email,
      firstName: userData.user.firstName,
      lastName: userData.user.lastName,
      status: userData.user.status,
      userType: userData.user.userType,
      phoneNumber: userData.user.phoneNumber,
      id: userId,
      token,
      role,
    };

    setStoredUser(adminUser);
    return adminUser;
  }

  function signout(): void {
    queryClient.setQueryData([ADMIN_USER_QUERY_KEY], null);
    clearUserPersistenceStatus();
  }

  return {
    authServerCall,
    signout,
  };
};
