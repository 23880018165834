import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PROJECTS_QUERY_KEY } from "../queries/useGetProjects/useGetProjects.config";
import { deleteProject } from "../../services/deleteProject.services";

const useDeleteProject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteProject(id),
    onSuccess: () => {
      queryClient.refetchQueries([PROJECTS_QUERY_KEY]);
    },
  });
};

export default useDeleteProject;
