import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OVERVIEWS_QUERY_KEY } from "../queries/useGetCourseOverviewSubjects/useGetCourseOverviewSubjects.config";
import { deleteCourseOverviewSubject } from "../../services/deleteCourseOverviewSubject";

const useDeleteCourseOverviewSubject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteCourseOverviewSubject(id),
    onSuccess: () => {
      queryClient.refetchQueries([OVERVIEWS_QUERY_KEY]);
    },
  });
};

export default useDeleteCourseOverviewSubject;
