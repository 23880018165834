import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import useEduInterns from "../../hooks/queries/useEduInterns/useEduInterns";
import useGetGroups from "../../hooks/queries/useGetGroups";
import useGetStacks from "../../hooks/queries/useGetStacks";
import { IGroup } from "../../types/types.groups";
import { IStack } from "../../types/types.stacks";

type Seniority = "Intern" | "Mentor" | "Squad lead" | "Team lead";

interface SurveyParticipantsSelectorProps {
  selectedUserIds: string[];
  onChange: (val: string[]) => void;
}

const SurveyParticipantsSelector: FC<SurveyParticipantsSelectorProps> = ({
  selectedUserIds,
  onChange,
}) => {
  // STATE
  const [groupPageSize, setGroupPageSize] = useState(20);
  const [stackPageSize, setStackPageSize] = useState(20);
  const [internPageSize, setInternPageSize] = useState(20);

  const [selectedStack, setSelectedStack] = useState<IStack>();
  const [selectedGroup, setSelectedGroup] = useState<IGroup>();
  const [seniority, setSeniority] = useState<Seniority>();

  // HOOKS

  const groups = useGetGroups({ page: 1, pageSize: groupPageSize });
  const stacks = useGetStacks({ page: 1, pageSize: stackPageSize });
  const interns = useEduInterns(1, internPageSize);

  // EFFECTS

  useEffect(() => {
    if (seniority === "Intern") {
      return;
    }
    setSelectedGroup(undefined);
  }, [seniority]);

  useEffect(() => {
    switch (seniority) {
      case "Intern":
        if (!selectedGroup) {
          return;
        }
        return onChange(
          selectedGroup.eduInterns
            .filter(({ userId }) => userId)
            .map(({ userId }) => userId) as string[]
        );
      case "Mentor":
        return (
          selectedStack &&
          onChange(
            selectedStack?.eduMentors
              .filter(({ userId }) => userId)
              .map(({ userId }) => userId) as string[]
          )
        );
      case "Squad lead":
        selectedStack && onChange([selectedStack.eduSquadLead.userId!]);
        return;
      case "Team lead":
        selectedStack && onChange([selectedStack.eduTeamLead.userId!]);
    }
  }, [seniority, selectedStack, selectedGroup, onChange]);

  useEffect(() => {
    if (!groups) return;
    setGroupPageSize(groups.total);
  }, [groups]);

  useEffect(() => {
    if (!stacks) return;
    setStackPageSize(stacks.total);
  }, [stacks]);

  useEffect(() => {
    if (!interns || !interns.data?.total) return;
    setInternPageSize(interns.data.total);
  }, [interns]);

  // VARIABLES

  const selectableUsers: any = useMemo(() => {
    switch (seniority) {
      case "Intern":
        if (!selectedGroup) {
          return [];
        }

        return selectedGroup.eduInterns || [];
      case "Mentor":
        return selectedStack?.eduMentors || [];
      case "Squad lead":
        return selectedStack?.eduSquadLead && selectedStack.eduSquadLead.userId
          ? [selectedStack.eduSquadLead]
          : [];
      case "Team lead":
        return selectedStack?.eduTeamLead && selectedStack?.eduTeamLead.userId
          ? [selectedStack.eduTeamLead]
          : [];
    }
  }, [selectedGroup, selectedStack, seniority]);

  // FUNCTIONS

  return (
    <>
      <h3 className="text-xl font-bold mb-4">Survey participants</h3>
      <FormControl fullWidth>
        <InputLabel id="seniority-select-label">Seniority</InputLabel>
        <Select
          labelId="seniority-select-label"
          id="seniority-select"
          value={seniority}
          label="Seniority"
          onChange={(e) => setSeniority(e.target.value as Seniority)}
        >
          <MenuItem value={"Intern"}>Intern</MenuItem>
          <MenuItem value={"Mentor"}>Mentor</MenuItem>
          <MenuItem value={"Squad lead"}>Squad lead</MenuItem>
          <MenuItem value={"Team lead"}>Team lead</MenuItem>
        </Select>
      </FormControl>
      <Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Stack"
            placeholder="Select stack"
          />
        )}
        options={stacks?.eduStacks || []}
        getOptionLabel={(options) => options.name}
        onChange={(_, value) => {
          value && setSelectedStack(value);
        }}
      />
      <Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Group"
            placeholder="Select group"
          />
        )}
        disabled={seniority !== "Intern"}
        options={groups?.eduGroups || []}
        value={selectedGroup}
        getOptionLabel={(options) => options.name}
        onChange={(_, value) => {
          value && setSelectedGroup(value);
        }}
      />
      <Autocomplete
        multiple
        filterSelectedOptions
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Participants"
            placeholder="Select participants"
          />
        )}
        value={(selectableUsers || []).filter((user: any) =>
          selectedUserIds.includes(user.userId || "")
        )}
        options={
          selectableUsers
            ? selectableUsers.filter((user: any) => user.userId)
            : []
        }
        getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
        onChange={(_, value) => {
          value && onChange(value.map((user) => user.userId));
        }}
      />
    </>
  );
};

export default SurveyParticipantsSelector;
