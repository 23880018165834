import {
  IMentor,
  eduMentorSeniorities,
  eduMentorStatuses,
} from "../../../types/types.mentors";

interface Column {
  id: keyof IMentor;
  label: string;
  minWidth?: number;
  align?: "right";
}

export const MENTORS_TABLE_CONFIG: Column[] = [
  { id: "firstName", label: "First Name", minWidth: 150 },
  { id: "lastName", label: "Last Name", minWidth: 150 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "eduStack", label: "Stack", minWidth: 100 },
  {
    id: "phoneNumber",
    label: "Phone Number",
    minWidth: 170,
    align: "right",
  },
  {
    id: "idNumber",
    label: "ID-Number",
    minWidth: 170,
    align: "right",
  },
  {
    id: "eduMentorSeniority",
    label: "Seniority",
    minWidth: 170,
    align: "right",
  },
  {
    id: "eduMentorStatus",
    label: "Status",
    minWidth: 170,
    align: "right",
  },
];

export const mentorSeniorityDefinitions = {
  [eduMentorSeniorities[0]]: "Junior",
  [eduMentorSeniorities[1]]: "Middle",
  [eduMentorSeniorities[2]]: "Senior",
};

export const mentorStatusDefinitions = {
  [eduMentorStatuses[0]]: "Mentorship",
  [eduMentorStatuses[1]]: "MentorshipSuspended",
  [eduMentorStatuses[2]]: "MentorshipInProgress",
};

export const DEFAULT_PAGE_SIZE = 20;
