import { useMutation } from "@tanstack/react-query";
import {
  CreateSurveyQuestionAnswerPayload,
  createSurveyQuestionAnswers,
} from "../../services/Edu/surveyQuestionAnswers.services";

const useCreateSurveyQuestionAnswers = () => {
  return useMutation({
    mutationFn: (payloads: CreateSurveyQuestionAnswerPayload[]) =>
      createSurveyQuestionAnswers(payloads),
  });
};

export default useCreateSurveyQuestionAnswers;
