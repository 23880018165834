import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { STACK_URL } from "../api/constants";
import { IStack, IStacksRes } from "../types/types.stacks";

export const getStacks = async (page: number, pageSize: number, searchQuery: string, type: string) => {
  const { data }: AxiosResponse<IStacksRes> = await adminAxiosInstance.get(
    `${STACK_URL}?page=${page}&pageSize=${pageSize}&searchQuery=${searchQuery}&type=${type}`
  );
  return data;
};

export const getStack = async (id: string) => {
  const { data }: AxiosResponse<{ eduStack: IStack }> =
    await adminAxiosInstance.get(`${STACK_URL}/${id}`);

  return data;
};
