import {
  Button,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import { dateFormatter } from "../../helpers";
import useAddTeamlead from "../../hooks/mutations/useAddTeamlead";
import useUpdateTeamlead from "../../hooks/mutations/useUpdateTeamlead";
import useGetStacks from "../../hooks/queries/useGetStacks";
import { ITeamleadModal } from "./TeamleadModal.config";

const TeamleadModal = ({
  title,
  showModal,
  onClose,
  teamleadInfo,
}: ITeamleadModal) => {
  const [stackId, setStackId] = useState("");

  const [stackPageSize, setStackPageSize] = useState(20);

  const stacks = useGetStacks({ page: 1, pageSize: stackPageSize });
  const { mutate: addTeamlead } = useAddTeamlead();
  const { mutate: updateTeamlead } = useUpdateTeamlead();

  const { handleSubmit, register, reset, setValue } = useForm();

  useEffect(() => {
    if (!stacks) return;
    setStackPageSize(stacks.total);
  }, [stacks]);

  const handleCloseModal = () => {
    reset();
    onClose();
  };

  const onSubmit = (body: any) => {
    if (teamleadInfo) {
      updateTeamlead(body);
    } else {
      addTeamlead(body);
    }
    handleCloseModal();
  };

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStackId(event.target.value);
  };

  useEffect(() => {
    if (stacks?.eduStacks[0]) {
      setStackId(stacks?.eduStacks[0].id);
    }
  }, [stacks]);

  useEffect(() => {
    if (teamleadInfo) {
      for (const [key, value] of Object.entries(teamleadInfo)) {
        setValue(key, value);
      }
      setValue("dateOfBirth", dateFormatter(teamleadInfo.dateOfBirthUtc));
    }
  }, [setValue, teamleadInfo]);

  return (
    <Modal onClose={handleCloseModal} showModal={showModal} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-16">
          <div className="flex flex-row gap-10">
            <div className="flex flex-col">
              <label htmlFor="firstName">FirstName</label>
              <Input id="firstName" required {...register("firstName")} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="lastName">Last Name</label>
              <Input id="lastName" required {...register("lastName")} />
            </div>
          </div>
          <div className="flex flex-row gap-10">
            <div className="flex flex-col">
              <label htmlFor="email">Email</label>
              <Input id="email" type="email" required {...register("email")} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="phoneNumber">Phone Number</label>
              <Input
                id="phoneNumber"
                type="number"
                required
                {...register("phoneNumber")}
              />
            </div>
          </div>

          <div className="flex flex-row gap-10">
            <div className="flex flex-col">
              <label htmlFor="idNumber">Id Number</label>
              <Input
                id="idNumber"
                type="number"
                required
                {...register("idNumber")}
              />
            </div>
            <div className="flex flex-col w-full">
              <label htmlFor="dateOfBirth">Date Of Birth</label>
              <Input
                type="date"
                id="dateOfBirth"
                {...register("dateOfBirth")}
              />
            </div>
          </div>

          <div className="flex flex-row gap-10 justify-between items-center">
            <div className="flex flex-col w-full">
              <InputLabel id="demo-simple-select-label">Stack</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stackId}
                label="Stack"
                {...register("stackId", {
                  onChange: handleSelectChange,
                })}
              >
                {stacks?.eduStacks?.map((stack) => (
                  <MenuItem key={stack.id} value={stack.id}>
                    {stack.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end gap-3 mt-14">
          <Button type="submit" variant="contained">
            Submit
          </Button>
          <Button variant="outlined" color="error" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default TeamleadModal;
