import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { deleteQuestionAttachment } from "../../services/Edu/surveyQuestions.services";

const useDeleteQuestionAttachment = () => {
  return useMutation<void, AxiosError, string>({
    mutationFn: deleteQuestionAttachment,
  });
};

export default useDeleteQuestionAttachment;
