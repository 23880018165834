import adminAxiosInstance from "../../api/admin/admin.instance";
import { SURVEY_SUBMISSION_URL } from "../../api/constants";
import { ISurveySubmission } from "../../types/types.survey";
import { SetQuestionScoreParams } from "../../types/types.surveySubmission";

export const getSurveySubmission = async (submissionId: string) => {
  const { data } = await adminAxiosInstance.get<{
    surveySubmission: ISurveySubmission;
  }>(`${SURVEY_SUBMISSION_URL}/${submissionId}`);

  return data;
};

export const setSubmissionQuestionScore = async (
  submissionId: string,
  payload: SetQuestionScoreParams
) => {
  const { data } = await adminAxiosInstance.patch(
    `${SURVEY_SUBMISSION_URL}/${submissionId}/set-question-answer-score`,
    payload
  );

  return data;
};
