import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createSurveyPayload } from "../../types/types.survey";
import { createSurvey } from "../../services/Edu/surveys.services";
import { SURVEYS_QUERY_KEY } from "../queries/surveys/useSurveys.config";

const useCreateSurvey = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (body: createSurveyPayload) => createSurvey(body),
    onSettled: () => {
      client.refetchQueries([SURVEYS_QUERY_KEY]);
    },
  });
};

export default useCreateSurvey;
