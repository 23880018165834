import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateTeamlead } from "../../services/updateTeamlead.services";
import { IUpdateTeamLeadParams } from "../../types/types.teamleads";
import { TEAMLEADS_QUERY_KEY } from "../queries/useGetTeamleads/useGetTeamleads.config";

const useUpdateTeamlead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IUpdateTeamLeadParams) => updateTeamlead(body),
    onSuccess: () => {
      queryClient.refetchQueries([TEAMLEADS_QUERY_KEY]);
    },
  });
};

export default useUpdateTeamlead;
