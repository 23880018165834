import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PROJECTS_QUERY_KEY } from "./useGetProjects.config";
import {
  IGetProjectsResponse,
  IUseGetProjects,
} from "../../../types/types.projects";
import { getProjects } from "../../../services/getProjects.services";

export default function useGetProjects(params: IUseGetProjects) {
  const queryClient = useQueryClient();
  const { data: projects } = useQuery<IGetProjectsResponse>(
    [PROJECTS_QUERY_KEY, params],
    {
      queryFn: () => getProjects(params),
      onError: () => {
        queryClient.setQueryData([PROJECTS_QUERY_KEY], null);
      },
    }
  );

  return projects;
}
