import { AxiosResponse } from "axios";
import adminAxiosInstance from "../../api/admin/admin.instance";
import { SQUAD_LEAD_URL } from "../../api/constants";
import {
  IGetSquadLead,
  IGetSquadLeads,
  ISquadLeadFormPayload,
} from "../../types/types.squadLeads";
import { downloadFile } from "../../helpers/downloadFile";

export const getSquadLeads = async (
  page?: number,
  pageSize?: number,
  searchQuery?: string
) => {
  const { data }: AxiosResponse<IGetSquadLeads> = await adminAxiosInstance.get(
    `${SQUAD_LEAD_URL}`,
    {
      params: {
        page,
        pageSize,
        searchQuery,
      },
    }
  );
  return data;
};

export const getSquadLead = async (id: string) => {
  const { data }: AxiosResponse<IGetSquadLead> = await adminAxiosInstance.get(
    `${SQUAD_LEAD_URL}/${id}`
  );

  return data;
};

export const createSquadLead = async (payload: ISquadLeadFormPayload) => {
  const { data }: AxiosResponse<void> = await adminAxiosInstance.post(
    `${SQUAD_LEAD_URL}`,
    payload
  );
  return data;
};

export const editSquadLead = async (payload: ISquadLeadFormPayload) => {
  const { data }: AxiosResponse<void> = await adminAxiosInstance.put(
    `${SQUAD_LEAD_URL}/${payload.id}`,
    payload
  );
  return data;
};

export const deleteSquadLead = async (squadLeadId: string) => {
  const { data }: AxiosResponse<void> = await adminAxiosInstance.delete(
    `${SQUAD_LEAD_URL}/${squadLeadId}`
  );
  return data;
};

export const exportSquadLeads = async () => {
  const response = await adminAxiosInstance.get(`${SQUAD_LEAD_URL}/export`, {
    responseType: "blob",
    headers: {
      Accept: "*/*",
    },
  });

  downloadFile(response, "EduSquadLeads.xlsx");
};
