import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { COURSE_OVERVIEW_URL } from "../api/constants";
import {
  ICourseOverview,
  ICourseOverviewUpdate,
} from "../types/types.courseOverviews";

export const updateCourseOverviewSubject = async ({
  name,
  description,
  eduStackId,
  position,
  subjectId,
}: ICourseOverviewUpdate) => {
  const { data }: AxiosResponse<ICourseOverview> = await adminAxiosInstance.put(
    `${COURSE_OVERVIEW_URL}/${subjectId}`,
    {
      name,
      description,
      eduStackId,
      position,
    },
  );
  return data;
};
