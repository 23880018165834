import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { GROUP_URL } from "../api/constants";
import { IGetGroup, IGetGroups } from "../types/types.groups";

export const getGroups = async (page: number, pageSize: number, searchQuery: string, startDate: string, endDate: string) => {
  const { data }: AxiosResponse<IGetGroups> = await adminAxiosInstance.get(
    `${GROUP_URL}?page=${page}&pageSize=${pageSize}&searchQuery=${searchQuery}&startDate=${startDate}&endDate=${endDate}`
  );
  return data;
};

export const getGroup = async (groupId: string) => {
  const { data }: AxiosResponse<IGetGroup> = await adminAxiosInstance.get(
    `${GROUP_URL}/${groupId}`
  );

  return data;
};
