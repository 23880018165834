import React, { FC, useEffect, useState } from "react";
import Modal from "../Modal/Modal.component";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ISurvey,
  ISurveyFormPayload,
  SurveyType,
} from "../../types/types.survey";
import {
  Autocomplete,
  Button,
  FormControl,
  FormLabel,
  Select,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import useCreateSurvey from "../../hooks/mutations/useCreateSurvey";
import { toast } from "react-toastify";
import useGetGroups from "../../hooks/queries/useGetGroups/useGetGroups";
import useGetStacks from "../../hooks/queries/useGetStacks/useGetStacks";
import { IGroup } from "../../types/types.groups";
import { IStack } from "../../types/types.stacks";
import useUpdateSurvey from "../../hooks/mutations/useUpdateSurvey";
import useEduInterns from "../../hooks/queries/useEduInterns/useEduInterns";
import { IEduIntern } from "../../types/types.eduInterns";

interface CreateSurveyModalProps {
  showModal: boolean;
  onClose: () => void;
  survey?: ISurvey;
}

const CreateSurveyModal: FC<CreateSurveyModalProps> = ({
  showModal,
  onClose,
  survey,
}) => {
  const title = survey?.id ? "Edit survey" : "Create new survey";

  const [stackUserIds, setStackUserIds] = useState<string[]>([]);
  const [groupUserIds, setGroupUserIds] = useState<string[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);

  useEffect(() => {
    if (!survey || !survey.users) return;

    setUserIds(survey.users.map((user) => user.id));
  }, [survey]);

  const [groupPageSize, setGroupPageSize] = useState(20);
  const [stackPageSize, setStackPageSize] = useState(20);
  const [internPageSize, setInternPageSize] = useState(20);

  const groups = useGetGroups({ page: 1, pageSize: groupPageSize });
  const stacks = useGetStacks({ page: 1, pageSize: stackPageSize });
  const interns = useEduInterns(1, internPageSize);

  const { handleSubmit, control, setValue, reset } =
    useForm<ISurveyFormPayload>({
      defaultValues: {
        title: survey ? survey.title : "",
        surveyType: survey ? survey.surveyType : SurveyType["Review Intern"],
      },
    });

  useEffect(() => {
    if (!survey) return;
    setValue("surveyType", survey.surveyType);
    setValue("title", survey.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey]);

  const { mutateAsync: createSurvey, isLoading: isCreatingSurvey } =
    useCreateSurvey();

  const { mutateAsync: updateSurvey, isLoading: isUpdatingSurvey } =
    useUpdateSurvey();

  const handleSelectStack = async (value: IStack[]) => {
    if (value.length === 0) {
      setStackUserIds([]);
      return;
    }

    const lastElement = value[value.length - 1];

    const stackInternIds = lastElement.eduInterns
      .map((intern) => intern.userId)
      .filter(Boolean) as string[];

    setStackUserIds((prev) => [...prev, ...stackInternIds]);
  };

  const handleSelectGroup = async (value: IGroup[]) => {
    if (value.length === 0) {
      setGroupUserIds([]);
      return;
    }

    const lastElement = value[value.length - 1];

    const stackInternIds = lastElement.eduInterns
      .map((intern) => intern.userId)
      .filter(Boolean) as string[];

    setGroupUserIds((prev) => [...prev, ...stackInternIds]);
  };

  const handleSelectUser = async (value: IEduIntern[]) => {
    if (value.length === 0) {
      setUserIds([]);
      return;
    }

    const lastElement = value[value.length - 1];

    if (!lastElement.userId) return;

    setUserIds((prev) => [...prev, lastElement.userId!]);
  };

  const submitHandler: SubmitHandler<ISurveyFormPayload> = async (
    data,
    event
  ) => {
    try {
      event?.preventDefault();

      const uniqueIds = new Set([...stackUserIds, ...groupUserIds, ...userIds]);

      const processedData = {
        ...data,
        userIds: Array.from(uniqueIds),
      };

      if (survey) {
        await updateSurvey(
          { id: survey.id, payload: processedData },
          {
            onSuccess: () => {
              toast.success("Survey successfully updated!!", {
                position: "bottom-right",
                closeOnClick: true,
                autoClose: 3000,
              });
            },
          }
        );

        reset();
        onClose();
        return;
      }

      await createSurvey(processedData);

      toast.success("Survey successfully created", {
        position: "bottom-right",
        closeOnClick: true,
        autoClose: 3000,
      });
      reset();
      onClose();
    } catch (ex) {
      console.warn(ex);
      toast.error("Error while creating survey", {
        position: "bottom-right",
        closeOnClick: true,
        autoClose: 3000,
      });
    }
  };

  return (
    <Modal
      title={title}
      showModal={showModal}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <form
        className="flex flex-col gap-5 w-full"
        onSubmit={handleSubmit(submitHandler)}
      >
        <Controller
          name="title"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              autoFocus
              {...field}
              label="Title"
              type="text"
              fullWidth
            />
          )}
        />
        <Controller
          name="surveyType"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl>
              <FormLabel className="mb-2">Type</FormLabel>
              <Select
                defaultValue={SurveyType["Review Intern"]}
                id="survey-type"
                {...field}
              >
                <MenuItem value={SurveyType["Review Intern"]}>
                  Review Intern
                </MenuItem>
                <MenuItem value={SurveyType["Review Mentor"]}>
                  Review Mentor
                </MenuItem>
                <MenuItem value={SurveyType.Exam}>Exam</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <div className="flex gap-2 flex-row items-center justify-end">
          <Button
            className="block"
            type="button"
            variant="outlined"
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            disabled={isCreatingSurvey || isUpdatingSurvey}
            type="submit"
            variant="contained"
            color="primary"
          >
            {survey ? "Save" : "Submit"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateSurveyModal;
