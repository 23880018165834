import { IAdminUser } from "../types/types.user";

const USER_LOCALSTORAGE_KEY = "edu_admin";
const USER_LOCALSTORAGE_PERSIST_KEY = "persist";

export function getStoredToken(): string | null {
  const storedUser: string | null =
    localStorage.getItem(USER_LOCALSTORAGE_KEY) || null;
  const result: string | null = storedUser
    ? JSON.parse(storedUser)?.token
    : null;
  return result;
}

export function getStoredUser(): IAdminUser | null {
  const persistenceStatus = getUserPersistenceStatus();
  if (persistenceStatus) {
    const storedUser = localStorage.getItem(USER_LOCALSTORAGE_KEY);
    const result: IAdminUser | null = storedUser
      ? JSON.parse(storedUser)
      : null;
    return result;
  } else {
    return null;
  }
}

export function setStoredUser(data: IAdminUser): void {
  localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(data));
}

export function clearStoredUser(): void {
  localStorage.removeItem(USER_LOCALSTORAGE_KEY);
}

export function getUserPersistenceStatus(): boolean {
  const persistStatus = localStorage.getItem(USER_LOCALSTORAGE_PERSIST_KEY);
  if (persistStatus && !!JSON.parse(persistStatus)) {
    return true;
  }
  return false;
}

export function setUserPersistenceStatus(status: boolean): void {
  localStorage.setItem(USER_LOCALSTORAGE_PERSIST_KEY, JSON.stringify(status));
}

export function clearUserPersistenceStatus(): void {
  localStorage.removeItem(USER_LOCALSTORAGE_PERSIST_KEY);
}
