import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getStacks } from "../../../services/getStacks.services";
import { IGetStacksParams, IStacksRes } from "../../../types/types.stacks";
import { STACK_QUERY_KEY } from "./useGetStack.config";

export default function useGetStacks({
  page = 1,
  pageSize = 20,
  searchQuery = "",
  type = "",
}: IGetStacksParams) {
  const queryClient = useQueryClient();
  const { data: stacks } = useQuery<IStacksRes>({
    queryKey: [STACK_QUERY_KEY, page, pageSize, searchQuery, type],
    queryFn: () => getStacks(page, pageSize, searchQuery, type),
    onError: () => {
      queryClient.setQueryData([STACK_QUERY_KEY], null);
    },
    keepPreviousData: true,
  });

  return stacks;
}
