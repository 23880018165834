import { IEduIntern } from "./types.eduInterns";
import { IInterDependency } from "./types.interdependency";
import { IPaginatedDataResponse } from "./types.pagination";
import { IStack } from "./types.stacks";

export const eduMentorStatuses = [1, 2, 3] as const;
export const eduMentorSeniorities = [1, 2, 3] as const;

export type EduMentorStatus = (typeof eduMentorStatuses)[number];
export type EduMentorSeniority = (typeof eduMentorSeniorities)[number];

export interface IMentor {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirthUtc: string;
  idNumber: string;
  eduMentorSeniority: EduMentorSeniority;
  eduMentorStatus: EduMentorStatus;
  eduInterns: IEduIntern[] | null;
  eduStack: IStack | null;
  userId: string | null;
}
export interface IGetMentors extends IPaginatedDataResponse {
  eduMentors: IMentor[];
}

export interface IMentorFormPayload {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  idNumber: string;
  mentorSeniority: EduMentorSeniority;
  mentorStatus: EduMentorStatus;
  stackId: string | null;
}

export interface IGetMentor {
  eduMentor: IMentor;
}
