import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, TablePagination } from "@mui/material";
import DeleteModal from "../DeleteModal";
import { useState } from "react";
import { IStackDetailsTable } from "./StackDetailsTable.config";
import useGetCourseOverviewSubjects from "../../hooks/queries/useGetCourseOverviewSubjects";
import useDeleteCourseOverviewSubject from "../../hooks/mutations/useDeleteCourseOverviewSubject";
import { Link } from "react-router-dom";

const StackDetailsTable = ({ id }: IStackDetailsTable) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [subjectId, setSubjectId] = useState("");

  const { data: details } = useGetCourseOverviewSubjects({
    page: currentPage,
    eduStackId: id,
  });
  const { mutate: deleteCourseOverviewSubject } =
    useDeleteCourseOverviewSubject();

  const handleDeleteClick = (id: string) => {
    setSubjectId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    deleteCourseOverviewSubject(subjectId);
    setShowDeleteModal(false);
  };

  return (
    <>
      <DeleteModal
        showModal={showDeleteModal}
        handleSubmit={handleDeleteConfirm}
        onClose={() => setShowDeleteModal(false)}
      />
      <Paper className="overflow-hidden">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Priority</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {details?.courseOverviews?.map((component) => (
                <TableRow
                  key={component.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {component.name}
                  </TableCell>
                  <TableCell>{component.position}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      style={{ marginRight: "10px" }}
                      component={Link}
                      to={`${component.id}`}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteClick(component.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={details?.total || 1}
          rowsPerPage={details?.pageSize || 20}
          page={currentPage - 1}
          onPageChange={(_, newPage: number) => setCurrentPage(newPage + 1)}
          rowsPerPageOptions={[0]}
        />
      </Paper>
    </>
  );
};

export default StackDetailsTable;
