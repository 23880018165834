import adminAxiosInstance from "../../api/admin/admin.instance";
import { SURVEY_QUESTION_URL } from "../../api/constants";
import { SurveyQuestionType, SurveyType } from "../../types/types.survey";

export interface CreateSurveyQuestionPayload {
  text: string;
  questionType: SurveyQuestionType;
  surveyQuestionAnswers?: { value: string; isCorrect: boolean }[];
  surveyId: string;
  attachment?: File;
}

export interface UpdateSurveyQuestionPayload {
  text: string;
  questionType: SurveyQuestionType;
}

export interface SurveyQuestionAnswer {
  id: string;
  text: string;
  isCorrect: boolean;
}

export interface SurveyReference {
  id: string;
  title: string;
  surveyType: SurveyType;
}
export interface SurveyQuestion {
  id: string;
  text: string;
  questionType: SurveyQuestionType;
  surveyQuestionAnswers: SurveyQuestionAnswer[];
  survey: SurveyReference;
}

export interface GetSurveyQuestionsPayload {
  page: number;
  pageSize: number;
  total: number;
  surveyQuestions: SurveyQuestion[];
}

export interface UploadQuestionAttachmentParams {
  Id: string;
  Attachment: File;
}

export const createSurveyQuestion = async (
  payload: CreateSurveyQuestionPayload
) => {
  const formData = new FormData();

  const { surveyId, surveyQuestionAnswers, questionType, text, attachment } =
    payload;

  formData.append("SurveyId", surveyId);
  attachment && formData.append("Attachment", attachment);
  surveyQuestionAnswers?.forEach((questionAnswer, index) => {
    Object.entries(questionAnswer).forEach(([key, value]) => {
      formData.append(
        `SurveyQuestionAnswers[${index}].${key}`,
        value.toString()
      );
    });
  });
  formData.append("Text", text);
  formData.append("QuestionType", questionType.toString());

  const { data } = await adminAxiosInstance.post(
    `${SURVEY_QUESTION_URL}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  return data;
};

export const deleteSurveyQuestion = async (questionId: string) => {
  const { data } = await adminAxiosInstance.delete(
    `${SURVEY_QUESTION_URL}/${questionId}`
  );

  return data;
};

export const updateSurveyQuestion = async (
  questions: { id: string; payload: UpdateSurveyQuestionPayload }[]
) => {
  try {
    const requests = questions.map(
      async (question) =>
        await adminAxiosInstance.put(
          `${SURVEY_QUESTION_URL}/${question.id}`,
          question.payload
        )
    );

    await Promise.allSettled(requests);
  } catch (ex) {
    console.warn(ex);
  }
};

export const getSurveyQuestions = async ({
  page = 1,
  pageSize = 20,
}: {
  page: number;
  pageSize: number;
}) => {
  const { data } = await adminAxiosInstance.get<GetSurveyQuestionsPayload>(
    `${SURVEY_QUESTION_URL}?page=${page}&pageSize=${pageSize}`
  );

  return data;
};

export const uploadQuestionAttachment = async ({
  Id,
  Attachment,
}: UploadQuestionAttachmentParams) => {
  const formData = new FormData();

  formData.append("Id", Id);
  formData.append("Attachment", Attachment);

  const { data } = await adminAxiosInstance.post(
    `${SURVEY_QUESTION_URL}/attachment`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return data;
};

export const deleteQuestionAttachment = async (questionId: string) => {
  const { data } = await adminAxiosInstance.delete(
    `${SURVEY_QUESTION_URL}/${questionId}/attachment`
  );

  return data;
};
