import React from "react";
import Modal from "../../Modal";
import { EduInternsInfoModalProps } from "./EduInternsInfoModal.config";
import { Button } from "@mui/material";
import FormattedData from "../../FormattedData";

const EduInternsInfoModal = ({
  showModal,
  onClose,
  title,
  intern,
}: EduInternsInfoModalProps) => {
  if (!intern) return null;

  return (
    <Modal showModal={showModal} onClose={onClose} title={title}>
      <div className="w-full px-20 gap-y-5 flex flex-col">
        <div className="flex flex-row justify-between w-full">
          <span>First name:</span>
          <span className="text-gray-300">{intern?.firstName}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Last name:</span>
          <span className="text-gray-300">{intern?.lastName}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Email:</span>
          <span className="text-gray-300">{intern?.email}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Phone Number:</span>
          <span className="text-gray-300">{intern?.phoneNumber}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Birth Date:</span>
          <span className="text-gray-300">
            <FormattedData>{intern?.dateOfBirthUtc}</FormattedData>
          </span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Id Number:</span>
          <span className="text-gray-300">{intern?.idNumber}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Stack:</span>
          <span className="text-gray-300">{intern.eduStack?.name}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Mentor:</span>
          <span className="text-gray-300">
            {intern.eduMentor
              ? `${intern.eduMentor?.firstName} ${intern.eduMentor?.lastName}`
              : "No mentor"}
          </span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Squad Lead:</span>
          <span className="text-gray-300">
            {intern.eduSquadLead
              ? `${intern.eduSquadLead?.firstName} ${intern.eduSquadLead?.lastName}`
              : "No squad lead"}
          </span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Team Lead:</span>
          <span className="text-gray-300">
            {intern.eduTeamLead
              ? `${intern.eduTeamLead?.firstName} ${intern.eduTeamLead?.lastName}`
              : "No team lead"}
          </span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Active:</span>
          <span className="text-gray-300">Active</span>
        </div>
      </div>
      <div className="w-full flex flex-row justify-end px-20 mt-20 gap-5">
        <Button variant="outlined" color="error" onClick={() => onClose()}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default EduInternsInfoModal;
