import {
  QueryClientProvider,
  QueryErrorResetBoundary,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "../../react-query/queryClient";
import Navbar from "../Navbar";
import Routes from "../../Routes/AppRoutes";

// log

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <QueryErrorResetBoundary>
        <Navbar />
        <Routes />
      </QueryErrorResetBoundary>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
