import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { COMPONENT_URL } from "../api/constants";
import { IUpdateComponent } from "../types/types.components";

export const updateComponent = async (body: IUpdateComponent) => {
  const { data }: AxiosResponse = await adminAxiosInstance.put(
    `${COMPONENT_URL}/${body.id}`,
    {
      ...body,
    },
  );
  return data;
};
