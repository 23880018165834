import React, { FC, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, TablePagination } from "@mui/material";

import useSurveys from "../../hooks/queries/surveys/useSurveys";
import { ISurvey, SurveyType } from "../../types/types.survey";
import useDeleteSurvey from "../../hooks/mutations/useDeleteSurvey";
import { Link, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDebouncedState } from "../../hooks/debounce/useDebounce";
import { DEFAULT_PAGE_SIZE } from "../Mentor/MentorsTable/MentrosTable.config";

const renderSurveyTypeName = (type: SurveyType) => {
  switch (type) {
    case SurveyType.Exam:
      return "Exam";
    case SurveyType["Review Intern"]:
      return "Review Intern";
    case SurveyType["Review Mentor"]:
      return "Review Mentor";
  }
};

interface SurveysTableProps {
  onPressEdit: (survey: ISurvey) => void;
}

const SurveysTable: FC<SurveysTableProps> = ({ onPressEdit }) => {
  const [params, setParams] = useSearchParams();
  const page = +(params.get("page") || "1");
  const [searchQuery, setSearchQuery] = useDebouncedState("" as string, 500);
  const [type, setType] = useState<string>("");

  const { data, isLoading } = useSurveys(page, 10, searchQuery, type);

  const { mutate: deleteSurvey, isLoading: isDeletingSurvey } =
    useDeleteSurvey();

  const surveys = data?.surveys || [];

  const handlePageChange = (_: any, newPage: number) => {
    setParams((params) => {
      params.set("page", String(newPage + 1));
      return params;
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value as string);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  if (!data || isLoading) {
    return <div />;
  }

  return (
    <>
      <Box
        sx={{
          width: 300,
          marginLeft: "auto",
          marginBottom: "20px",
        }}
      >
        <TextField
          id="outlined-basic4"
          label="Search"
          variant="outlined"
          onChange={handleNameChange}
        />
        <FormControl fullWidth sx={{ marginTop: "10px" }}>
          <InputLabel id="type-select-label">Type</InputLabel>
          <Select
            labelId="type-select-label"
            id="type-select"
            value={type}
            label="Status"
            onChange={handleTypeChange}
          >
            <MenuItem value={""}>All</MenuItem>
            <MenuItem value={1}>Review Mentor</MenuItem>
            <MenuItem value={2}>Review Intern</MenuItem>
            <MenuItem value={3}>Exam</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Paper className="overflow-hidden">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Type</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surveys.map((survey) => (
                <TableRow
                  key={survey.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {survey.title}
                  </TableCell>
                  <TableCell>
                    {renderSurveyTypeName(survey.surveyType)}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      style={{ marginRight: "8px" }}
                      onClick={() => onPressEdit(survey)}
                    >
                      Edit
                    </Button>

                    <Link to={`/survey/${survey.id}/configurations`}>
                      <Button variant="outlined" style={{ marginRight: "8px" }}>
                        Configuration
                      </Button>
                    </Link>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => deleteSurvey(survey.id)}
                      disabled={isDeletingSurvey}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={data.total}
          rowsPerPage={data.pageSize}
          page={page - 1}
          onPageChange={handlePageChange}
          rowsPerPageOptions={[0]}
        />
      </Paper>
    </>
  );
};

export default SurveysTable;
