import { useQuery } from "@tanstack/react-query";
import { SURVEYS_QUERY_KEY } from "./useSurveys.config";
import { getPaginatedSurveys } from "../../../services/Edu/surveys.services";

const useSurveys = (currentPage: number = 1, pageSize: number = 20, searchQuery: string = '', type: string = '') => {
  return useQuery({
    queryKey: [SURVEYS_QUERY_KEY, { currentPage, searchQuery, type }],
    keepPreviousData: true,
    queryFn: () => getPaginatedSurveys({ currentPage, pageSize, searchQuery, type }),
  });
};

export default useSurveys;
