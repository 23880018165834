import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { STACK_URL } from "../api/constants";
import { IAddStackParams } from "../types/types.stacks";

export const addStack = async (body: IAddStackParams) => {
  const { data }: AxiosResponse = await adminAxiosInstance.post(
    `${STACK_URL}`,
    {
      ...body,
    }
  );
  return data;
};
