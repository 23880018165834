import Button from "@mui/material/Button";
import { useState } from "react";
import GroupModal from "../../components/GroupModal";
import GroupTable from "../../components/GroupTable";
import { IGroupItem } from "../../types/types.groups";

const Group = () => {
  const [showModal, setShowModal] = useState(false);
  const [groupInfo, setGroupInfo] = useState<IGroupItem>();

  const handleAddGroupClick = () => {
    setGroupInfo(undefined);
    setShowModal(true);
  };

  return (
    <>
      <section className="flex justify-between p-5 items-center">
        <h1 className="py-5 text-3xl font-bold">Groups</h1>
        <Button variant="contained" onClick={handleAddGroupClick}>
          Add Group
        </Button>
      </section>
      <section className="p-5">
        <GroupModal
          title={groupInfo ? "Update Group" : "Add Group"}
          groupInfo={groupInfo}
          showModal={showModal}
          onClose={setShowModal}
          setGroupInfo={setGroupInfo}
        />
        <GroupTable setShowModal={setShowModal} setGroupInfo={setGroupInfo} />
      </section>
    </>
  );
};

export default Group;
