import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { COMPONENT_URL } from "../api/constants";
import { IGetComponentsResponse } from "../types/types.components";
import { DEFAULT_PAGE_SIZE } from "../components/Mentor/MentorsTable/MentrosTable.config";

export const getComponents = async ({
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const { data }: AxiosResponse<IGetComponentsResponse> =
    await adminAxiosInstance.get(`${COMPONENT_URL}`, {
      params: { page, pageSize },
    });
  return data;
};
