import React from "react";
import { IEduIntern } from "../../../types/types.eduInterns";

export const INITIAL_MODAL_STATE = {
  show: false,
  type: null,
};
export interface IInternModal {
  show: boolean;
  type: "DELETE" | "PERSIST" | null;
}
interface Column {
  id: keyof IEduIntern;
  label: string;
  minWidth?: number;
  align?: "right";
}

export interface IEduInternsTableProps {
  paginatedInternsData: IEduIntern[];
  currentPage: number;
  pageSize: number;
  total: number;
  onPageChange: (pageNum: number) => void;
  filter: React.ReactNode;
}

export const INTERN_TABLE_CONFIG: Column[] = [
  { id: "firstName", label: "First Name", minWidth: 100 },
  { id: "lastName", label: "Last Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  {
    id: "phoneNumber",
    label: "Phone Number",
    minWidth: 100,
    align: "right",
  },
  {
    id: "eduStack",
    label: "Stack",
    align: "right",
  },
  {
    id: "eduGroup",
    label: "Group",
    align: "right",
    minWidth: 170,
  },
  {
    id: "eduInternStatus",
    label: "Status",
    minWidth: 100,
    align: "right",
  },
  {
    id: "userStatus",
    label: "Active",
    align: "right",
  },
];
