import useAddComponentRating from "../../hooks/mutations/useAddComponentRating";
import { useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import useGetComponentRatings from "../../hooks/queries/useGetComponentRating";
import { useEffect, useState } from "react";
import useUpdateComponentRating from "../../hooks/mutations/useUpdateComponentRating";

export interface RatingInputProps {
  componentId: string;
  userId: string | null;
  date?: string;
}

const RatingInput = ({ componentId, date, userId = "" }: RatingInputProps) => {
  const { data } = useGetComponentRatings({
    componentId,
    userId,
    ratingDate: date,
  });
  const [disabled, setDisabled] = useState(false);
  const [rating] = data?.ratings || [];
  const { point, id } = rating || {};

  const { handleSubmit, register, reset, setValue } = useForm({
    defaultValues: {
      point: 0,
    },
  });
  const { mutate: addComponentRating, isLoading } = useAddComponentRating();
  const { mutate: updateComponentRating } = useUpdateComponentRating();

  useEffect(() => {
    setValue("point", point || 0);
    if (id) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [point, setValue, id, date]);

  const onSubmit = (body: { point: number }) => {
    // if creating new rating, post to addComponentRating, else updateComponentRating
    if (!id) {
      addComponentRating({
        ...body,
        componentId,
        userId,
        ratingDate: date,
      });
    } else {
      updateComponentRating({
        componentId,
        userId,
        point: body.point,
        ratingDate: date,
        id,
      });
    }
  };

  const handleEnableEdit = () => {
    setDisabled(false);
  };

  return (
    <form
      onSubmit={handleSubmit((body) => onSubmit(body))}
      className="flex gap-1"
    >
      <input
        type="number"
        step="any"
        className="p-1 border"
        {...register("point")}
        disabled={disabled}
      />

      <div>
        {!disabled && (
          <IconButton
            type={"submit"}
            sx={{ padding: "4px" }}
            disabled={isLoading}
          >
            <CheckIcon fontSize={"small"} />
          </IconButton>
        )}
        {disabled && (
          <IconButton sx={{ padding: "4px" }} onClick={handleEnableEdit}>
            <EditIcon fontSize={"small"} />
          </IconButton>
        )}
      </div>
    </form>
  );
};

export default RatingInput;
