import React from "react";
import Modal from "../../Modal";
import { Button } from "@mui/material";
import FormattedData from "../../FormattedData";
import { MentorInfoModalProps } from "./MentorInfoModal.config";

const MentorInfoModal = ({
  showModal,
  onClose,
  title,
  mentor,
}: MentorInfoModalProps) => {
  if (!mentor) return null;

  return (
    <Modal showModal={showModal} onClose={onClose} title={title}>
      <div className="w-full px-20 gap-y-5 flex flex-col">
        <div className="flex flex-row justify-between w-full">
          <span>First name:</span>
          <span className="text-gray-300">{mentor?.firstName}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Last name:</span>
          <span className="text-gray-300">{mentor?.lastName}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Email:</span>
          <span className="text-gray-300">{mentor?.email}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Phone Number:</span>
          <span className="text-gray-300">{mentor?.phoneNumber}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Birth Date:</span>
          <span className="text-gray-300">
            <FormattedData>{mentor?.dateOfBirthUtc}</FormattedData>
          </span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Id Number:</span>
          <span className="text-gray-300">{mentor?.idNumber}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Stack:</span>
          <span className="text-gray-300">{mentor.eduStack?.name}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Active:</span>
          <span className="text-gray-300">Active</span>
        </div>
      </div>
      <div className="w-full flex flex-row justify-end px-20 mt-20 gap-5">
        <Button variant="outlined" color="error" onClick={() => onClose()}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default MentorInfoModal;
