import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IAddStackParams } from "../../types/types.stacks";
import { addStack } from "../../services/addStack.services";
import { STACK_QUERY_KEY } from "../queries/useGetStacks/useGetStack.config";

const useAddStack = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IAddStackParams) => addStack(body),
    onSuccess: () => {
      queryClient.refetchQueries([STACK_QUERY_KEY]);
    },
  });
};

export default useAddStack;
