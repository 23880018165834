import axios, { AxiosRequestConfig } from "axios";
import { BASE_AUTH_URL } from "../constants";

const authConfig: AxiosRequestConfig = {
  baseURL: BASE_AUTH_URL,
  method: "POST",
  headers: { "Content-Type": "application/json" },
};

export type AuthResponseType = { success: true; token: string };
export const authAxiosInstance = axios.create(authConfig);
