import { Button } from "@mui/material";
import Modal from "../Modal";
import FormattedData from "../FormattedData";
import { ITeamleadInfoModal } from "./TeamleadInfoModal.config";
import { Link } from "react-router-dom";

const TeamleadModal = ({
  showModal,
  onClose,
  title,
  teamleadInfo,
}: ITeamleadInfoModal) => {
  if (!teamleadInfo) return null;

  return (
    <Modal showModal={showModal} onClose={onClose} title={title}>
      <div className="w-full px-20 gap-y-5 flex flex-col">
        <div className="flex flex-row justify-between w-full">
          <span>First name:</span>
          <span className="text-gray-300">{teamleadInfo?.firstName}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Last name:</span>
          <span className="text-gray-300">{teamleadInfo?.lastName}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Email:</span>
          <span className="text-gray-300">{teamleadInfo?.email}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Phone Number:</span>
          <span className="text-gray-300">{teamleadInfo?.phoneNumber}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Birth Date:</span>
          <span className="text-gray-300">
            <FormattedData>{teamleadInfo?.dateOfBirthUtc}</FormattedData>
          </span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Id Number:</span>
          <span className="text-gray-300">{teamleadInfo?.idNumber}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Stack:</span>
          <span className="text-gray-300">{teamleadInfo.eduStack.name}</span>
        </div>
        <div className="flex flex-row justify-between w-full">
          <span>Active:</span>
          <span className="text-gray-300">Active</span>
        </div>
      </div>
      <div className="w-full flex flex-row justify-end px-20 mt-20 gap-5">
        <Link to={`/team-leads/dependency/${teamleadInfo.id}`}>
          <Button variant="contained">Interdependency</Button>
        </Link>
        <Button variant="outlined" color="error" onClick={() => onClose()}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default TeamleadModal;
