import { useQuery } from "@tanstack/react-query";

import { getGroup } from "../../../services/getGroups.services";
import { GROUP_QUERY_KEY } from "./useGetGroups.config";

const useGetGroup = (groupId: string) => {
  return useQuery({
    queryKey: [GROUP_QUERY_KEY, groupId],
    queryFn: () => getGroup(groupId),
  });
};

export default useGetGroup;
