import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GROUPS_QUERY_KEY } from "../queries/useGetGroups/useGetGroups.config";
import { deleteGroup } from "../../services/deleteGroup.services";

const useDeleteGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteGroup(id),
    onSuccess: () => {
      queryClient.refetchQueries([GROUPS_QUERY_KEY]);
    },
  });
};

export default useDeleteGroup;
