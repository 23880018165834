import Modal from "../../Modal";
import { IEduInternsModalProps } from "./EduInternsModal.config";
import EduInternForm from "../EduInternsForm/EduInternsForm.component";
import DeleteModal from "../../DeleteModal/DeleteModal.component";

const EduInternsModal = ({
  type,
  onClose,
  eduIntern,
  onDeleteIntern,
  onEditIntern,
  onCreateIntern,
  isLoading,
}: IEduInternsModalProps) => {
  const title = eduIntern ? "Edit an intern" : "Create a new intern";
  const internId = eduIntern?.id || null;

  if (type === "PERSIST") {
    return (
      <Modal title={title} onClose={onClose} showModal>
        <EduInternForm
          onClose={onClose}
          eduIntern={eduIntern || null}
          onEditIntern={onEditIntern}
          onCreateIntern={onCreateIntern}
          isLoading={isLoading}
        />
      </Modal>
    );
  }

  if (type === "DELETE" && internId) {
    return (
      <DeleteModal
        onClose={onClose}
        handleSubmit={() => onDeleteIntern(internId)}
        showModal
      />
    );
  }

  return null;
};

export default EduInternsModal;
