import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createSurveyConfiguration } from "../../services/Edu/surveyConfiguration.services";
import { CreateSurveyConfigurationParams } from "../../types/types.surveyConfiguration";
import { SURVEY_CONFIGURATIONS_QUERY_KEY } from "../queries/surveys/useSurveyConfigurations.config";

interface MutationProps {
  id: string;
  payload: CreateSurveyConfigurationParams;
}

const useCreateSurveyConfiguration = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({ id, payload }: MutationProps) =>
      createSurveyConfiguration(id, payload),
    onSettled: (_, __, { id }) => {
      client.invalidateQueries([SURVEY_CONFIGURATIONS_QUERY_KEY, id]);
    },
  });
};

export default useCreateSurveyConfiguration;
