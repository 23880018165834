import React, { FC, useState } from "react";
import { Button, IconButton, MenuItem, Select } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";

import { toast } from "react-toastify";
import Answer from "./Answer.component";
import useDeleteSurveyQuestion from "../../hooks/mutations/useDeleteSurveyQuestion";
import { ISurveyQuestion, SurveyQuestionType } from "../../types/types.survey";
import useDeleteQuestionAttachment from "../../hooks/mutations/useDeleteQuestionAttachment";
import { useQueryClient } from "@tanstack/react-query";
import { SURVEYS_QUERY_KEY } from "../../hooks/queries/surveys/useSurveys.config";
import { Editor } from "@tinymce/tinymce-react";

interface QuestionsProps {
  question: ISurveyQuestion;
  onChangeText: (surveyId: string, text: string) => void;
  onChangeType: (surveyId: string, type: SurveyQuestionType) => void;
  onChangeAnswerText: (
    questionId: string,
    answerId: string,
    text: string
  ) => void;
  onChangeAnswerCorrectness: (
    questionId: string,
    answerId: string,
    isCorrect: boolean
  ) => void;
  onDeleteQuestion: (questionId: string) => void;
  onAddAnswer: (questionId: string) => void;
  onDeleteAnswer: (questionId: string, answerId: string) => void;
  onFileSelect: (questionId: string, file: File) => void;
}

const Question: FC<QuestionsProps> = ({
  question,
  onChangeText,
  onChangeType,
  onChangeAnswerText,
  onChangeAnswerCorrectness,
  onDeleteQuestion,
  onAddAnswer,
  onDeleteAnswer,
  onFileSelect,
}) => {
  const [attachmentEditing, setAttachmentEditing] = useState(false);

  const queryClient = useQueryClient();

  const { mutateAsync: deleteQuestion, isLoading: isDeletingQuestion } =
    useDeleteSurveyQuestion();
  const { mutate: deleteAttachment, isLoading: isDeletingAttachment } =
    useDeleteQuestionAttachment();

  const handleDeleteQuestion = async () => {
    try {
      if (!question.id.includes("local")) {
        // delete in DB first
        await deleteQuestion(question.id);
        toast.success("Question successfully deleted", {
          position: "bottom-right",
          autoClose: 3000,
          closeOnClick: true,
        });
      }

      onDeleteQuestion(question.id);
    } catch (ex) {
      console.warn(ex);
    }
  };

  const handleDeleteAttachment = async () => {
    deleteAttachment(question.id, {
      onSuccess: () => {
        toast.success("Attachment deleted", {
          position: "bottom-right",
          autoClose: 3000,
          closeOnClick: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries([SURVEYS_QUERY_KEY]);
      },
    });
  };

  return (
    <div className="flex flex-col bg-slate-100 p-8 gap-4 rounded-md">
      <div className="h-[400px]">
        <Editor
          apiKey="rzlhy3ngrilr0am9s4m2odpact4vuo6qrf78vvlz2ftlvm06"
          cloudChannel="6-dev"
          value={question.text}
          onEditorChange={(a) => onChangeText(question.id, a)}
          init={{
            menubar: true,
            height: "100%",
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "code",
              "help",
              "wordcount",
              "codesample",
            ],
            toolbar:
              "undo redo | formatselect |  fontfamily  | removeformat | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help",
            font_family_formats:
              "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde" +
              "; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino;" +
              "Helvetica=Helvetica Neue, sans-serif; Impact=impact,chicago; Symbol=symbol; Poppins=Poppins, sans-serif; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times;" +
              " Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
          }}
        />
      </div>
      <Select
        value={question.questionType}
        onChange={(e) => {
          onChangeType(question.id, e.target.value as SurveyQuestionType);
        }}
      >
        <MenuItem value={SurveyQuestionType.MultipleChoice}>
          Multiple Choice
        </MenuItem>
        <MenuItem value={SurveyQuestionType.OpenEnded}>Open ended</MenuItem>
        <MenuItem value={SurveyQuestionType.Rating}>Rating</MenuItem>
      </Select>
      <div className="py-8 flex items-center gap-8">
        <p>Attachment: </p>
        {question.attachment ? (
          attachmentEditing ? (
            <input
              type="file"
              onChange={(e) =>
                e.target.files && onFileSelect(question.id, e.target.files[0])
              }
            />
          ) : (
            <div className="flex items-center gap-4">
              <a
                href={question.attachment.downloadUrl}
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 underline"
              >
                Download
              </a>
              <IconButton onClick={() => setAttachmentEditing(true)}>
                <Edit />
              </IconButton>
              <IconButton
                onClick={handleDeleteAttachment}
                disabled={isDeletingAttachment}
              >
                <Delete />
              </IconButton>
            </div>
          )
        ) : (
          <input
            type="file"
            onChange={(e) =>
              e.target.files && onFileSelect(question.id, e.target.files[0])
            }
          />
        )}
      </div>
      <div className="flex items-center justify-end gap-4">
        {question.questionType === SurveyQuestionType.MultipleChoice && (
          <Button variant="outlined" onClick={() => onAddAnswer(question.id)}>
            Add answer
          </Button>
        )}
        <Button
          variant="outlined"
          color="error"
          onClick={handleDeleteQuestion}
          disabled={isDeletingQuestion}
        >
          Delete
        </Button>
      </div>
      {question.questionType === SurveyQuestionType.MultipleChoice
        ? question.surveyQuestionAnswers.map((answer) => (
            <Answer
              key={answer.id}
              answer={answer}
              onChangeText={(answerId, text) =>
                onChangeAnswerText(question.id, answerId, text)
              }
              onChangeCorrectness={(answerId, isCorrect) =>
                onChangeAnswerCorrectness(question.id, answerId, isCorrect)
              }
              onDeleteAnswer={(answerId) =>
                onDeleteAnswer(question.id, answerId)
              }
            />
          ))
        : null}
    </div>
  );
};

export default Question;
