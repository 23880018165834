import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IAdminUser } from "../../../types/types.user";
import { getAdminUser } from "../../../services/user.services";
import { ADMIN_USER_QUERY_KEY } from "./useAdminUser.config";
import {
  getStoredUser,
  clearStoredUser,
  setStoredUser,
} from "../../../helpers";

export default function useAdminUser() {
  const queryClient = useQueryClient();
  const { data: adminUser } = useQuery<IAdminUser | null>({
    queryKey: [ADMIN_USER_QUERY_KEY],
    queryFn: () =>
      getAdminUser(
        queryClient.getQueryData<IAdminUser>([ADMIN_USER_QUERY_KEY]) ?? null
      ),
    initialData: getStoredUser,
    onSuccess: (received: IAdminUser | null) => {
      if (!received) {
        clearStoredUser();
      } else {
        setStoredUser(received);
      }
    },
    onError: () => {
      queryClient.setQueryData([ADMIN_USER_QUERY_KEY], null);
    },
  });

  return adminUser;
}
