import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import useAddIntern from "../../../hooks/mutations/useAddIntern";
import useUpdateIntern from "../../../hooks/mutations/useUpdateIntern";
import useDeleteIntern from "../../../hooks/mutations/useDeleteIntern";
import EduInternsModal from "../EduInternsModal/EduInternsModal.component";
import {
  EduInternStatusDefinitions,
  IEduIntern,
} from "../../../types/types.eduInterns";
import { EDU_INTERNS_QUERY_KEY } from "../../../hooks/queries/useEduInterns/useEduInterns.config";
import LockIconOpen from "@mui/icons-material/LockOpen";
import { toast } from "react-toastify";
import {
  IEduInternsTableProps,
  INTERN_TABLE_CONFIG,
  IInternModal,
  INITIAL_MODAL_STATE,
} from "./EduInternsTable.config";
import { activateIntern } from "../../../services/Edu/interns.services";
import { AxiosError } from "axios";
import { WEB_ACTIOVATION_URL } from "../../../config";
import EduInternsInfoModal from "../EduInternsInfoModal/EduInternsInfoModal.components";
import useExportInterns from "../../../hooks/mutations/useExportInterns";

const EduInternsTable = ({
  paginatedInternsData,
  currentPage,
  pageSize,
  total,
  onPageChange,
  filter,
}: IEduInternsTableProps) => {
  const queryClient = useQueryClient();

  const [desiredIntern, setDesiredIntern] = useState<IEduIntern>();

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [internInfo, setInternInfo] = useState<IEduIntern | undefined>(
    undefined
  );

  const openInfoModal = (intern: IEduIntern) => {
    setInternInfo(intern);
    setShowInfoModal(true);
  };

  const closeInfoModal = () => {
    setShowInfoModal(false);
    setInternInfo(undefined);
  };

  const [internsModal, setInternsModal] =
    useState<IInternModal>(INITIAL_MODAL_STATE);

  const handleCloseModal = () => {
    setDesiredIntern(undefined);
    setInternsModal(INITIAL_MODAL_STATE);
  };

  const handleInvalidateInterns = () => {
    queryClient.invalidateQueries({
      queryKey: [EDU_INTERNS_QUERY_KEY, { currentPage }],
    });

    handleCloseModal();
  };

  const { mutate: deleteInternMutation, isLoading: deletingIntern } =
    useDeleteIntern(handleInvalidateInterns);
  const { mutate: createInternMutation, isLoading: creatingIntern } =
    useAddIntern(handleInvalidateInterns);
  const { mutate: updateInternMutation, isLoading: updatingIntern } =
    useUpdateIntern(handleInvalidateInterns);

  const { mutate: exportInterns, isLoading: isExporting } = useExportInterns();

  const { mutate: activateInternHandler, isLoading } = useMutation<
    void,
    AxiosError,
    { internId: string }
  >({
    mutationFn: ({ internId }) => activateIntern(internId, WEB_ACTIOVATION_URL),
    onSuccess: () => {
      toast.success("Activation link is sent to intern", {
        position: "bottom-right",
        closeOnClick: true,
        autoClose: 3000,
      });
    },
  });

  const handleSetIntern = (intern: IEduIntern) => {
    // const transformedIntern = transformInternObjectForForm(intern);
    setDesiredIntern(intern);
  };

  const handleOpenPersistInternModal = (intern?: IEduIntern) => {
    if (intern) {
      handleSetIntern(intern);
    }
    setInternsModal({ show: true, type: "PERSIST" });
  };

  const handleOpenDeleteInternModal = (intern: IEduIntern) => {
    handleSetIntern(intern);
    setInternsModal({ show: true, type: "DELETE" });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage);
  };

  const modal = internsModal.show ? (
    <EduInternsModal
      type={internsModal.type}
      eduIntern={desiredIntern}
      onClose={handleCloseModal}
      onEditIntern={updateInternMutation}
      onCreateIntern={createInternMutation}
      onDeleteIntern={deleteInternMutation}
      isLoading={updatingIntern || creatingIntern || deletingIntern}
    />
  ) : null;

  return (
    <>
      {modal}
      <div className="m-5">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold">Interns</h1>
          <div className="py-5 flex items-center justify-between">
            <Button
              onClick={() => handleOpenPersistInternModal()}
              variant="contained"
              color="success"
            >
              Add a new intern
            </Button>
          </div>
          <EduInternsInfoModal
            showModal={showInfoModal}
            onClose={closeInfoModal}
            intern={internInfo}
            title="Intern information"
          />
        </div>
        {filter}
        <div className="flex justify-end mb-4">
          <Button
            variant="contained"
            onClick={() => exportInterns()}
            disabled={isExporting}
          >
            export
          </Button>
        </div>
        <Paper className="overflow-hidden">
          <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="bg-gray-100 font-bold">
                <TableRow>
                  {INTERN_TABLE_CONFIG.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell style={{ minWidth: 170 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedInternsData.map((intern) => {
                  return (
                    <TableRow
                      className="cursor-pointer"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={intern.id}
                    >
                      <TableCell>{intern.firstName}</TableCell>
                      <TableCell>{intern.lastName}</TableCell>
                      <TableCell>{intern.email}</TableCell>
                      <TableCell>{intern.phoneNumber}</TableCell>
                      <TableCell>{intern.eduStack?.name}</TableCell>
                      <TableCell>{intern.eduGroup?.name}</TableCell>
                      <TableCell>
                        {EduInternStatusDefinitions[intern.eduInternStatus]}
                      </TableCell>
                      <TableCell>
                        <span>
                          {intern.userStatus === 1 ? "ACTIVE" : "INACTIVE"}
                        </span>
                        {intern.userStatus === 2 && (
                          <Button
                            variant="text"
                            endIcon={<LockIconOpen />}
                            sx={{ px: 0, fontWeight: 600 }}
                            size="small"
                            color={"success"}
                            onClick={() => {
                              activateInternHandler({ internId: intern.id });
                            }}
                            disabled={isLoading}
                          >
                            Activate
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-1">
                          <Button
                            variant="contained"
                            style={{ marginRight: "10px" }}
                            onClick={() => openInfoModal(intern)}
                          >
                            View
                          </Button>
                          <Button
                            variant="outlined"
                            style={{ marginRight: "10px" }}
                            onClick={() => handleOpenPersistInternModal(intern)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleOpenDeleteInternModal(intern)}
                          >
                            Delete
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={total}
            rowsPerPage={pageSize}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[0]}
          />
        </Paper>
      </div>
    </>
  );
};

export default EduInternsTable;
