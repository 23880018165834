import { AxiosResponse } from "axios";
import adminAxiosInstance from "../../api/admin/admin.instance";
import { SURVEY_CONFIGURATION_URL, SURVEY_URL } from "../../api/constants";
import {
  CreateSurveyConfigurationParams,
  GetSurveyConfigurationQuestionsPayload,
  GetSurveyConfigurationsPayload,
} from "../../types/types.surveyConfiguration";

export const getSurveyConfigurations = async (id: string) => {
  const { data }: AxiosResponse<GetSurveyConfigurationsPayload> =
    await adminAxiosInstance.get(`${SURVEY_URL}/${id}/configurations`, {
        params: { pageSize: 20 },
    });

  return data;
};

export const createSurveyConfiguration = async (
  surveyId: string,
  payload: CreateSurveyConfigurationParams
) => {
  await adminAxiosInstance.post(
    `${SURVEY_URL}/${surveyId}/configuration`,
    payload
  );
};

export const getSurveyConfigurationQuestions = async (configId: string) => {
  const { data } =
    await adminAxiosInstance.get<GetSurveyConfigurationQuestionsPayload>(
      `${SURVEY_CONFIGURATION_URL}/${configId}/questions`
    );

  return data;
};
