export const initialTable = "<table>\n" +
    "        <thead>\n" +
    "            <tr>\n" +
    "                <th>Evaluation of</th>\n" +
    "                <th>Assessment Mode</th>\n" +
    "                <th>Period</th>\n" +
    "                <th>Grading</th>\n" +
    "            </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "            <tr>\n" +
    "                <td>Tech exam</td>\n" +
    "                <td>Test/Assignment</td>\n" +
    "                <td>Monthly</td>\n" +
    "                <td>At least 70%</td>\n" +
    "            </tr>\n" +
    "            <tr>\n" +
    "                <td>Soft skills</td>\n" +
    "                <td>Mock Interview/Presentation\n" +
    "on tech related topic via\n" +
    "grading rubric</td>\n" +
    "                <td>III & VI months</td>\n" +
    "                <td>At least 70%</td>\n" +
    "            </tr>\n" +
    "            <tr>\n" +
    "                <td>Training attendance</td>\n" +
    "                <td>Check-ins</td>\n" +
    "                <td>I-II-III-IV months</td>\n" +
    "                <td>At least 80%</td>\n" +
    "            </tr>\n" +
    "            <tr>\n" +
    "                <td>Attendance at office</td>\n" +
    "                <td>Dally Check-ins</td>\n" +
    "                <td>Daily</td>\n" +
    "                <td>At least 80%</td>\n" +
    "            </tr>\n" +
    "            <tr>\n" +
    "                <td>Event participation</td>\n" +
    "                <td>Stack/field presentation\n" +
    "Live coding practice\n" +
    "Hackathon</td>\n" +
    "                <td>I-II-III-IV months</td>\n" +
    "                <td>At least 80%</td>\n" +
    "            </tr>\n" +
    "            <tr>\n" +
    "                <td>Real project</td>\n" +
    "                <td>PM Feedback</td>\n" +
    "                <td>IV-V-VI months</td>\n" +
    "                <td>Positive feedback</td>\n" +
    "            </tr>\n" +
    "        </tbody>\n" +
    "    </table>"
