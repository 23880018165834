import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { Button, TablePagination } from "@mui/material";
import DeleteModal from "../DeleteModal";
import { useState } from "react";
import useGetStacks from "../../hooks/queries/useGetStacks/useGetStacks";
import { IStack } from "../../types/types.stacks";
import { IStackTable, StackType } from "./StackTable.config";
import useDeleteStack from "../../hooks/mutations/useDeleteStack";
import { useDebouncedState } from "../../hooks/debounce/useDebounce";
import { Link, useSearchParams } from "react-router-dom";

const StackTable = ({ setShowModal, setStack }: IStackTable) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [stackId, setStackId] = useState("");
  const [searchQuery, setSearchQuery] = useDebouncedState("" as string, 500);
  const [type, setType] = useState<string>("");
  const [params, setParams] = useSearchParams();
  const page = +(params.get("page") || "1");

  const stacks = useGetStacks({ page, searchQuery, type });

  const { mutate: deleteStack } = useDeleteStack();

  const handleEditClick = (stack: IStack) => {
    setStack(stack);
    setShowModal(true);
  };

  const handleDeleteClick = (id: string) => {
    setShowDeleteModal(true);
    setStackId(id);
  };

  const handleDeleteConfirm = () => {
    deleteStack(stackId);
    setShowDeleteModal(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value as string);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  return (
    <>
      <DeleteModal
        showModal={showDeleteModal}
        handleSubmit={handleDeleteConfirm}
        onClose={() => setShowDeleteModal(false)}
      />
      <Box
        sx={{
          width: 300,
          marginLeft: "auto",
          marginBottom: "20px",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          onChange={handleNameChange}
        />
        <FormControl fullWidth sx={{ marginTop: "10px" }}>
          <InputLabel id="type-select-label">Type</InputLabel>
          <Select
            labelId="type-select-label"
            id="type-select"
            value={type}
            label="Status"
            onChange={handleTypeChange}
          >
            <MenuItem value={""}>All</MenuItem>
            <MenuItem value={1}>Digital Marketing</MenuItem>
            <MenuItem value={2}>Software</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Paper className="overflow-hidden">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Details</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stacks?.eduStacks?.map((stack) => (
                <TableRow
                  key={stack.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {stack.name}
                  </TableCell>
                  <TableCell>{stack.description}</TableCell>
                  <TableCell>
                    {stack.eduStackType === 1
                      ? StackType.DIGITAL_MARKETING
                      : StackType.SOFTWARE}
                  </TableCell>
                  <TableCell>
                    <Button sx={{ px: 0 }}>
                      <Link to={`/stacks/${stack.id}/details`}>
                        View Details
                      </Link>
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      style={{ marginRight: "10px" }}
                      onClick={() => handleEditClick(stack)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteClick(stack.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={stacks?.total || 1}
          rowsPerPage={stacks?.pageSize || 20}
          page={page - 1}
          onPageChange={(_, newPage: number) =>
            setParams((params) => {
              params.set("page", String(newPage + 1));
              return params;
            })
          }
          rowsPerPageOptions={[0]}
        />
      </Paper>
    </>
  );
};

export default StackTable;
