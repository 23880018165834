import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { PROJECT_URL } from "../api/constants";
import { IAddProject } from "../types/types.projects";

export const addProject = async (body: IAddProject) => {
  const { data }: AxiosResponse = await adminAxiosInstance.post(
    `${PROJECT_URL}`,
    {
      ...body,
    }
  );
  return data;
};
