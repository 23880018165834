import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { SetQuestionScoreParams } from "../../types/types.surveySubmission";
import { setSubmissionQuestionScore } from "../../services/Edu/surveySubmission.service";

const useSetQuestionScore = () => {
  return useMutation<
    void,
    AxiosError,
    SetQuestionScoreParams & { submissionId: string }
  >({
    mutationFn: ({ submissionId, questionId, score }) =>
      setSubmissionQuestionScore(submissionId, { questionId, score }),
  });
};

export default useSetQuestionScore;
