import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { TEAMLEADS_URL } from "../api/constants";
import { IUpdateTeamLeadParams } from "../types/types.teamleads";

export const updateTeamlead = async (body: IUpdateTeamLeadParams) => {
  const { data }: AxiosResponse = await adminAxiosInstance.put(
    `${TEAMLEADS_URL}/${body.id}`,
    {
      ...body,
    }
  );
  return data;
};
