import { AxiosResponse } from "axios";
import { IAdminUser, IGetUsers, IUser } from "../types/types.user";
import adminAxiosInstance from "../api/admin/admin.instance";
import { USER_URL } from "../api/constants";

export const getUsers = async ({
  page,
  pageSize,
}: {
  page: number;
  pageSize: number;
}) => {
  const { data }: AxiosResponse<IGetUsers> = await adminAxiosInstance.get(
    `${USER_URL}?page=${page}&pageSize=${pageSize}`
  );

  return data;
};

export const getUser = async (userId: string) => {
  const { data: userData }: AxiosResponse<{ user: IUser }> =
    await adminAxiosInstance.get(`${USER_URL}/${userId}`);
  return userData;
};

export const getAdminUser = async (
  user: IAdminUser | null
): Promise<IAdminUser | null> => {
  if (!user) return null;
  const userData = await getUser(user.id);
  return { ...userData.user, role: user.role, token: user.token };
};
