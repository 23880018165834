import { useQuery } from "@tanstack/react-query";
import { PROJECT_QUERY_KEY } from "./useProject.config";
import { getProject } from "../../../services/getProjects.services";

const useProject = (projectId: string) => {
  return useQuery({
    queryKey: [PROJECT_QUERY_KEY, projectId],
    queryFn: () => getProject(projectId),
  });
};

export default useProject;
