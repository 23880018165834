import { IStack } from "../../types/types.stacks";

export interface IStackTable {
  setStack: React.Dispatch<React.SetStateAction<IStack | undefined>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum StackType {
  DIGITAL_MARKETING = "Digital Marketing",
  SOFTWARE = "Software",
}
