import { useMutation } from "@tanstack/react-query";
import { updateEduIntern } from "../../services/Edu/interns.services";
import { IEduInternFormPayload } from "../../types/types.eduInterns";

const useUpdateIntern = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: (intern: IEduInternFormPayload) => updateEduIntern(intern),
    onSuccess,
  });
};

export default useUpdateIntern;
