import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";

import useSurveyConfigurationQuestions from "../../hooks/queries/surveys/useSurveyConfigurationQuestions";
import { SurveyQuestionType, SurveyType } from "../../types/types.survey";
import useSurveySubmission from "../../hooks/queries/surveySubmission/useSurveySubmission";
import useUser from "../../hooks/queries/useUsers/useUser";
import { useSurvey } from "../../hooks/queries/surveys";
import { Button } from "@mui/material";
import { SurveyConfigurationQuestion } from "../../types/types.surveyConfiguration";
import { SubmissionRatingModal } from "../../components/SurveySubmission";

const SubmissionAnswers = () => {
  const { id = "", configId = "", submissionId = "" } = useParams();

  const [selectedQuestion, setSelectedQuestion] =
    useState<SurveyConfigurationQuestion>();

  const { data: questionsData } = useSurveyConfigurationQuestions(configId);
  const { data: submissionData } = useSurveySubmission(submissionId);
  const { data: userData } = useUser(
    submissionData?.surveySubmission.userId ?? ""
  );
  const { data: surveyData } = useSurvey(id);

  const totalScore = useMemo(() => {
    if (!questionsData) {
      return 0;
    }

    return questionsData.surveyQuestionConfigurations.reduce(
      (a, b) => a + b.score,
      0
    );
  }, [questionsData]);

  const getQuestionAnswer = (questionId: string) => {
    if (!submissionData) {
      return;
    }

    const { surveySubmission } = submissionData;

    return surveySubmission.surveyQuestionSubmissions.find(
      (q) => q.questionId === questionId
    );
  };

  const isMultipleChoiceAnswerSelected = (
    questionId: string,
    answerId: string
  ) => {
    if (!submissionData) {
      return false;
    }

    const { surveySubmission } = submissionData;

    const question = surveySubmission.surveyQuestionSubmissions.find(
      (q) => q.questionId === questionId
    );

    if (!question) {
      return false;
    }

    return question.answerIds.includes(answerId);
  };

  if (!questionsData || !submissionData || !userData || !surveyData) {
    return null;
  }

  return (
    <div className="p-5">
      <h1 className="font-bold text-3xl mb-4">{surveyData.survey.title}</h1>
      <div className="flex items-center justify-between">
        <h2 className="text-xl">
          {userData.user.firstName} {userData.user.lastName}'s submission
        </h2>
        {surveyData.survey.surveyType === SurveyType.Exam && (
          <p className="text-lg font-bold">
            Score - {submissionData.surveySubmission.totalScore}/{totalScore}{" "}
            points
          </p>
        )}
      </div>
      <ol className="m-5 list-decimal list">
        {questionsData.surveyQuestionConfigurations.map((q) => (
          <div key={q.id} className="py-4">
            <div className="flex flex-row justify-between align-center">
              <li className="font-bold text-xl">{q.text}</li>
              {surveyData.survey.surveyType === SurveyType.Exam && (
                <p>
                  {getQuestionAnswer(q.id)?.score ?? 0}/{q.score} points
                </p>
              )}
            </div>
            {q.questionType === SurveyQuestionType.MultipleChoice ? (
              <ul className="list-disc ml-4 pt-4">
                {q.answers.map((a) => (
                  <div key={a.id} className="flex flex-row align-center gap-4">
                    <li
                      className={`${
                        isMultipleChoiceAnswerSelected(q.id, a.id)
                          ? "font-bold"
                          : ""
                      }`}
                    >
                      {a.value}
                    </li>
                    {isMultipleChoiceAnswerSelected(q.id, a.id) ? (
                      a.isCorrect ? (
                        <Check className="text-green-500" />
                      ) : (
                        <Close className="text-red-500" />
                      )
                    ) : null}
                  </div>
                ))}
              </ul>
            ) : (
              <>
                <p className="pt-4">
                  {getQuestionAnswer(q.id)?.answer ??
                    "User did not answer this question"}
                </p>
                {getQuestionAnswer(q.id)?.attachment ? (
                  <p className="mt-4">
                    Attachment:{" "}
                    <a
                      href={getQuestionAnswer(q.id)?.attachment?.downloadUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500 underline"
                    >
                      Download
                    </a>
                  </p>
                ) : null}
              </>
            )}
            {surveyData.survey.surveyType === SurveyType.Exam &&
              q.questionType === SurveyQuestionType.OpenEnded && (
                <div className="flex flex-row justify-end">
                  <Button onClick={() => setSelectedQuestion(q)}>Rate</Button>
                </div>
              )}
          </div>
        ))}
      </ol>
      <SubmissionRatingModal
        question={selectedQuestion}
        onClose={() => setSelectedQuestion(undefined)}
        answer={
          getQuestionAnswer(selectedQuestion?.id ?? "")?.answer ??
          "User did not answer this question"
        }
        submissionId={submissionId}
      />
    </div>
  );
};

export default SubmissionAnswers;
