import { useMutation } from "@tanstack/react-query";
import { deleteSurveyQuestion } from "../../services/Edu/surveyQuestions.services";

const useDeleteSurveyQuestion = () => {
  return useMutation({
    mutationFn: (questionId: string) => deleteSurveyQuestion(questionId),
  });
};

export default useDeleteSurveyQuestion;
