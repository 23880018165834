import { useState, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { EDU_INTERNS_QUERY_KEY } from "../../hooks/queries/useEduInterns/useEduInterns.config";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";

import useAdminUser from "../../hooks/queries/useAdminUser/useAdminUser";
import useEduInterns from "../../hooks/queries/useEduInterns/useEduInterns";

import EduInternsTable from "../../components/EduInterns/EduInternsTable/EduInternsTable.component";
import { getPaginatedEduInterns } from "../../services/Edu/interns.services";
import { useDebouncedState } from "../../hooks/debounce/useDebounce";

const PAGE_SIZE = 20;

const EduInterns = () => {
  const admin = useAdminUser();
  const queryClient = useQueryClient();
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useDebouncedState("" as string, 500);
  const [status, setStatus] = useState<string>("");

  const [params, setParams] = useSearchParams();
  const page = +(params.get("page") || "1");

  const {
    data: paginatedEduInterns,
    isLoading,
    isError,
    maxPostPage,
  } = useEduInterns(page, 20, searchQuery, status);
  console.log(page, maxPostPage);

  const handleChangeCurrentPage = (pageNum: number) => {
    setParams((params) => {
      params.set("page", String(pageNum + 1));
      return params;
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value as string);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  useEffect(() => {
    if (page < maxPostPage) {
      console.log("eeeeee");
      const nextPage = page + 1;
      queryClient.prefetchQuery({
        queryKey: [EDU_INTERNS_QUERY_KEY, { currentPage: nextPage }],
        queryFn: () =>
          getPaginatedEduInterns({
            currentPage: nextPage,
            pageSize: PAGE_SIZE,
          }),
      });
    }
  }, [page, queryClient, maxPostPage]);

  const errorMessage = (
    <h1 className="text-lg text-center text-error-100 pt-4">
      There was an error during loading. Try again.{" "}
    </h1>
  );

  if (admin?.role === "SkillWill") {
    return <Navigate to="/" />;
  }

  const filter = (
    <Box
      sx={{
        width: 300,
        marginLeft: "auto",
        marginBottom: "20px",
      }}
    >
      <TextField
        id="outlined-basic"
        label="Search"
        variant="outlined"
        onChange={handleNameChange}
      />
      <FormControl fullWidth sx={{ marginTop: "10px" }}>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Status"
          onChange={handleStatusChange}
        >
          <MenuItem value={""}>All</MenuItem>
          <MenuItem value={1}>NotStarted</MenuItem>
          <MenuItem value={2}>InternInProcess</MenuItem>
          <MenuItem value={3}>Canceled</MenuItem>
          <MenuItem value={4}>ReadyToLevelUp</MenuItem>
          <MenuItem value={5}>Junior</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );

  if (isLoading)
    return <h1 className="text-xl text-center pt-4">Loading Interns...</h1>;

  if (isError) return errorMessage;

  return (
    <>
      <EduInternsTable
        paginatedInternsData={paginatedEduInterns!.eduInterns}
        currentPage={page}
        pageSize={paginatedEduInterns!.pageSize}
        total={paginatedEduInterns!.total}
        onPageChange={handleChangeCurrentPage}
        filter={filter}
      />
    </>
  );
};

export default EduInterns;
