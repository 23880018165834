import { AxiosResponse } from "axios";
import { authAxiosInstance, AuthResponseType } from "../api/auth/auth.instance";
import { AUTH_URL } from "../api/constants";

export const getAuthData = async (email: string, password: string) => {
  const { data }: AxiosResponse<AuthResponseType> = await authAxiosInstance(
    AUTH_URL,
    {
      data: { username: email, password },
    }
  );

  return data;
};
