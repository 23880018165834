import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getComponents } from "../../../services/getComponents.services";
import { COMPONENTS_QUERY_KEY } from "./useGetComponents.config";
import {
  IGetComponentsResponse,
  IUseGetComponents,
} from "../../../types/types.components";

export default function useGetComponents(params: IUseGetComponents) {
  const queryClient = useQueryClient();
  return  useQuery<IGetComponentsResponse>(
    [COMPONENTS_QUERY_KEY, params],
    {
      queryFn: () => getComponents(params),
      onError: () => {
        queryClient.setQueryData([COMPONENTS_QUERY_KEY], null);
      },
    },
  );
}
