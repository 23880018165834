import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IAddComponent } from "../../types/types.components";
import { addComponent } from "../../services/addComponent.services";
import { COMPONENTS_QUERY_KEY } from "../queries/useGetComponents/useGetComponents.config";

const useAddComponent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IAddComponent) => addComponent(body),
    onSuccess: () => {
      queryClient.refetchQueries([COMPONENTS_QUERY_KEY]);
    },
  });
};

export default useAddComponent;
