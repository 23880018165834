import React, { useEffect, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import useAdminUser from "../../hooks/queries/useAdminUser/useAdminUser";
import useSignin from "../../hooks/mutations/useSignin";
import { setUserPersistenceStatus } from "../../helpers";

const SERVER_ERROR = "There was an error contacting the server.";
const INVALID_CREDENTIALS = "Invalid email or password";

const Login = () => {
  const adminUser = useAdminUser();
  const { mutate, error, isLoading, reset } = useSignin();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [persist, setPersist] = useState<boolean>(false);

  useEffect(() => {
    reset();
  }, [email, password, reset]);

  useEffect(() => {
    setUserPersistenceStatus(persist);
  }, [persist]);

  if (!!adminUser) {
    return <Navigate to="/" />;
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersist(event.target.checked);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({ email, password });
  };

  let errorText = "";

  if (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.data?.message) {
        errorText = error.response?.data?.message;
      } else if (error.response?.status === 403) {
        errorText = INVALID_CREDENTIALS;
      } else {
        errorText = SERVER_ERROR;
      }
    }
  }

  return (
    <section className="flex flex-col w-full pt-[200px] sm:w-[400px] mx-auto">
      <h1 className="text-gray-700 px-6 sm:px-0 font-bold text-3xl mb-6">
        Sign in
      </h1>
      <p className="text-error-100 text-sm mt-2 mb-5" aria-live="assertive">
        {errorText}
      </p>
      <form
        className="flex flex-col bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit}
      >
        <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
          Email
        </label>
        <input
          className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="email"
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
        <label
          htmlFor="password"
          className="block text-gray-700 font-bold my-2"
        >
          Password
        </label>
        <input
          className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="password"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          required
        />
        <label htmlFor="checkbox" className="inline-flex items-center my-2">
          <input
            type="checkbox"
            checked={persist}
            onChange={handleCheckboxChange}
            className="form-checkbox h-5 w-5 text-gray-600"
          />
          <span className="ml-2 text-gray-700">Trust this device</span>
        </label>
        <button
          disabled={isLoading}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold mt-5 py-3 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Sign In
        </button>
      </form>
    </section>
  );
};

export default Login;
