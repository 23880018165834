import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GROUPS_QUERY_KEY } from "../queries/useGetGroups/useGetGroups.config";
import { updateGroup } from "../../services/updateGroup.services";
import { IUpdateGroup } from "../../types/types.groups";

const useUpdateGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IUpdateGroup) => updateGroup(body),
    onSuccess: () => {
      queryClient.refetchQueries([GROUPS_QUERY_KEY]);
    },
  });
};

export default useUpdateGroup;
