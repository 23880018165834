import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getTeamLeadInterDependencies } from "../../services/Edu/teamLeads.services";
import Button from "@mui/material/Button";
import TeamLeadInterDependecyTable from "../../components/TeamLead/TeamLeadInterDependecyTable";
import { IGetTeamLeadInterDependency } from "../../types/types.teamleads";

const SquadLeadInterDependency = () => {
  const { id = "" } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { data: { teamLeadInterDependencies = [] } = {} } =
    useQuery<IGetTeamLeadInterDependency>({
      queryKey: ["getTeamLeadInterDependencies"],
      queryFn: () => getTeamLeadInterDependencies(id),
      enabled: !!id,
    });

  return (
    <section className="p-5">
      <div className="flex justify-between  items-center">
        <h1 className="py-5 text-3xl font-bold">Inter Dependencies</h1>
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </div>
      <TeamLeadInterDependecyTable data={teamLeadInterDependencies} />
    </section>
  );
};

export default SquadLeadInterDependency;
