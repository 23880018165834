import { useQuery } from "@tanstack/react-query";
import { SURVEYS_QUERY_KEY } from "./useSurveys.config";
import { getSurvey } from "../../../services/Edu/surveys.services";

const useSurvey = (id: string) => {
  return useQuery({
    queryKey: [SURVEYS_QUERY_KEY, id],
    queryFn: () => getSurvey(id),
  });
};

export default useSurvey;
