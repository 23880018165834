import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GROUPS_QUERY_KEY } from "../queries/useGetGroups/useGetGroups.config";
import { addGroup } from "../../services/addGroup.services";
import { IAddGroup } from "../../types/types.groups";

const useAddGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IAddGroup) => addGroup(body),
    onSuccess: () => {
      queryClient.refetchQueries([GROUPS_QUERY_KEY]);
    },
  });
};

export default useAddGroup;
