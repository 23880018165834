import { useQuery } from "@tanstack/react-query";
import { getSurveyConfigurationQuestions } from "../../../services/Edu/surveyConfiguration.services";

const SURVEY_CONFIGURATION_QUESTIONS_QUERY_KEY =
  "SURVEY_CONFIGURATION_QUESTIONS";

const useSurveyConfigurationQuestions = (configId: string) => {
  return useQuery({
    queryKey: [SURVEY_CONFIGURATION_QUESTIONS_QUERY_KEY, configId],
    queryFn: () => getSurveyConfigurationQuestions(configId),
  });
};

export default useSurveyConfigurationQuestions;
