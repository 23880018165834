import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PROJECTS_QUERY_KEY } from "../queries/useGetProjects/useGetProjects.config";
import { addProject } from "../../services/addProject.services";
import { IAddProject } from "../../types/types.projects";

const useAddProject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IAddProject) => addProject(body),
    onSuccess: () => {
      queryClient.refetchQueries([PROJECTS_QUERY_KEY]);
    },
  });
};

export default useAddProject;
