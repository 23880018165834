import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import useGetSquadLeads from "../../../hooks/queries/useGetSquadLeads";
import useModalState from "../../../hooks/useModalState";
import { ISquadLead } from "../../../types/types.squadLeads";
import { SQUAD_LEADS_TABLE_CONFIG } from "./squadLeadTable.config";
import SquadLeedModal from "../SquadLeadModal";
import { Link, useSearchParams } from "react-router-dom";
import useExportSquadLeads from "../../../hooks/mutations/useExportSquadLeads";

const SquadLeadTable = () => {
  const { modalState, updateModalState } = useModalState<ISquadLead>();
  const [params, setParams] = useSearchParams();
  const page = +(params.get("page") || "1");

  const {
    data: { eduSquadLeads = [], total = 0, pageSize = 0 } = {},

    updateSearchQuery,
  } = useGetSquadLeads({ page });

  const { mutate: exportSquadLeads, isLoading: isExporting } =
    useExportSquadLeads();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSearchQuery(event.target.value as string);
  };

  return (
    <>
      <div className="m-5">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold">Squad leads</h1>
          <div className="py-5 flex items-center justify-between">
            <Button
              onClick={() => updateModalState({ type: "PERSIST", open: true })}
              variant="contained"
              color="success"
            >
              Add new squad lead
            </Button>
          </div>
        </div>
        <Box
          component="form"
          sx={{
            width: 300,
            marginLeft: "auto",
            marginBottom: "20px",
          }}
        >
          <TextField
            id="outlined-basic1"
            label="Search"
            variant="outlined"
            onChange={handleNameChange}
          />
        </Box>
        <div className="flex justify-end mb-4">
          <Button
            variant="contained"
            onClick={() => exportSquadLeads()}
            disabled={isExporting}
          >
            export
          </Button>
        </div>
        <Paper className="overflow-hidden">
          <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="bg-gray-100 font-bold">
                <TableRow>
                  {SQUAD_LEADS_TABLE_CONFIG.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell style={{ minWidth: 170 }}>Interns</TableCell>
                  <TableCell style={{ minWidth: 170 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eduSquadLeads.map((lead) => {
                  return (
                    <TableRow
                      className="cursor-pointer"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={lead.id}
                    >
                      <TableCell>{lead.firstName}</TableCell>
                      <TableCell>{lead.lastName}</TableCell>
                      <TableCell>{lead.email}</TableCell>
                      <TableCell>{lead.eduStack?.name}</TableCell>
                      <TableCell>{lead.phoneNumber}</TableCell>
                      <TableCell>{lead.idNumber}</TableCell>
                      <TableCell>
                        <Link to={`/squad-lead/${lead.id}/interns`}>
                          <Button>View Interns</Button>
                        </Link>
                      </TableCell>

                      <TableCell>
                        <div className="flex gap-1">
                          <Button
                            variant="outlined"
                            style={{ marginRight: "10px" }}
                            onClick={() =>
                              updateModalState({
                                type: "PERSIST",
                                open: true,
                                payload: lead,
                              })
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() =>
                              updateModalState({
                                type: "DELETE",
                                open: true,
                                payload: lead,
                              })
                            }
                          >
                            Delete
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={total}
            rowsPerPage={pageSize}
            page={page - 1}
            onPageChange={(_, page) =>
              setParams((params) => {
                params.set("page", String(page + 1));
                return params;
              })
            }
            rowsPerPageOptions={[0]}
          />
        </Paper>
      </div>
      {modalState.open && (
        <SquadLeedModal
          squadLead={modalState.payload}
          type={modalState.type}
          onClose={() => updateModalState({ open: false })}
        />
      )}
    </>
  );
};

export default SquadLeadTable;
