import {
  IMentor,
  eduMentorSeniorities,
  eduMentorStatuses,
} from "../../../types/types.mentors";

export interface IMentorForm {
  mentor?: IMentor;
  onClose: () => void;
}

export const eduMentorSeniorityOptions = [
  {
    key: crypto.randomUUID(),
    label: "Junior",
    value: eduMentorSeniorities[0],
  },
  {
    key: crypto.randomUUID(),
    label: "Middle",
    value: eduMentorSeniorities[1],
  },
  {
    key: crypto.randomUUID(),
    label: "Senior",
    value: eduMentorSeniorities[2],
  },
];

export const eduMentorStatusOptions = [
  {
    key: crypto.randomUUID(),
    label: "Mentorship",
    value: eduMentorStatuses[0],
  },
  {
    key: crypto.randomUUID(),
    label: "MentorshipSuspended",
    value: eduMentorStatuses[1],
  },
  {
    key: crypto.randomUUID(),
    label: "MentorshipInProgress",
    value: eduMentorStatuses[2],
  },
];
