import jwtDecode from "jwt-decode";
import { JWTToken, IDecodedJWT } from "./useJWT.config";

const useJWT = () => {
  function decodeJWT(token: JWTToken) {
    const { userId, username, role }: IDecodedJWT = jwtDecode(token);

    return {
      userName: username,
      userId,
      role,
    };
  }

  return decodeJWT;
};

export default useJWT;
