import { Routes, Route } from "react-router-dom";
import Login from "../pages/Login/Login";
import Home from "../pages/Home/Home";
import RequireAuth from "../components/RequireAuth";
import Missing from "../pages/Missing/Missing";
import Layout from "../components/Layout";
import Group from "../pages/Group/Group";
import EduInterns from "../pages/EduIntern/EduInterns.page";
import Stack from "../pages/Stack/Stack";
import Project from "../pages/Project/Project";
import Mentors from "../pages/Mentor";
import SquadLeads from "../pages/SquadLead";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Teamleads from "../pages/Teamleads";
import SquadLeadInterDependency from "../pages/SquadLeadInterDependency";
import MentorInterDependencies from "../pages/MentorInterns";
import Surveys from "../pages/Surveys/Surveys.page";
import { GroupInterns } from "../pages/GroupInterns";
import { SquadLeadInterns } from "../pages/SquadLeadInterns";
import ProjectInterns from "../pages/ProjectInterns";
import { SurveySubmissions } from "../pages/SurveySubmissions";
import SurveyConfiguration from "../pages/SurveyQuestions/SurveyConfiguration.page";
import { SubmissionAnswers } from "../pages/SubmissionAnswers";
import Components from "../pages/Components/Components";
import GroupRatings from "../pages/GroupRatings/GroupRatings.page";
import StackDetails from "../pages/StackDetails";
import CourseOverviewSubjectForm from "../pages/CourseOverviewSubjectForm/CourseOverviewSubjectForm";
import GroupAnalytics from "../pages/GroupAnalytics";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="login" element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Home />} />
            <Route path="/groups" element={<Group />} />
            <Route path="/group/:id/interns" element={<GroupInterns />} />
            <Route path="/group/:id/ratings" element={<GroupRatings />} />
            <Route path="/group/:id/analytics" element={<GroupAnalytics />} />
            <Route path="interns" element={<EduInterns />} />
            <Route path="mentors" element={<Mentors />} />
            <Route
              path="mentors/:id/interns"
              element={<MentorInterDependencies />}
            />
            <Route path="squad-leads" element={<SquadLeads />} />
            <Route
              path="/squad-lead/:id/interns"
              element={<SquadLeadInterns />}
            />
            <Route path="/team-leads" element={<Teamleads />} />
            <Route
              path="/team-leads/dependency/:id"
              element={<SquadLeadInterDependency />}
            />
            <Route path="/stacks" element={<Stack />} />
            <Route path="/stacks/:id/details" element={<StackDetails />} />
            <Route
              path="/stacks/:id/details/:subjectId"
              element={<CourseOverviewSubjectForm />}
            />
            <Route path="/projects" element={<Project />} />
            <Route path="/projects/:id/interns" element={<ProjectInterns />} />
            <Route path="/surveys" element={<Surveys />} />
            <Route path="/survey/:id/configurations">
              <Route index element={<SurveyConfiguration />} />
              <Route path=":configId/submissions">
                <Route index element={<SurveySubmissions />} />
                <Route
                  path=":submissionId/answers"
                  element={<SubmissionAnswers />}
                />
              </Route>
            </Route>
            <Route path="/components" element={<Components />} />
          </Route>
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};

export default AppRoutes;
