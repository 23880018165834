import { IInterDependency } from "../../../types/types.interdependency";

export interface ITeamLeadInterDependecyTableProps {
  data: IInterDependency[];
}

interface Column {
  id: keyof IInterDependency;
  label: string;
  minWidth?: number;
  align?: "right";
}

export const TEAM_LEAD_DEPENDENCY_TABLE_CONFIG: Column[] = [
  { id: "firstName", label: "First Name", minWidth: 150 },
  { id: "lastName", label: "Last Name", minWidth: 150 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "type", label: "Type", minWidth: 100 },
  { id: "group", label: "Group", minWidth: 100 },
];
