import { IMentor, IMentorFormPayload } from "../types/types.mentors";
import { dateFormatter } from "./dateFormatter";

export function transformMentorObjectForForm(
  mentor: IMentor
): IMentorFormPayload {
  const {
    firstName,
    lastName,
    email,
    idNumber,
    phoneNumber,
    id,
    eduStack: stack,
    dateOfBirthUtc,
    eduMentorSeniority,
    eduMentorStatus,
  } = mentor;

  return {
    id,
    firstName,
    lastName,
    email,
    idNumber,
    phoneNumber,
    stackId: stack?.id || null,
    dateOfBirth: dateFormatter(dateOfBirthUtc),
    mentorSeniority: eduMentorSeniority,
    mentorStatus: eduMentorStatus,
  };
}
