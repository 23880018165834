import Button from "@mui/material/Button";
import { useState } from "react";
import { IComponent } from "../../types/types.components";
import StackDetailsModal from "../../components/StackDetailsModal";
import StackDetailsTable from "../../components/StackDetailsTable";
import { useParams } from "react-router-dom";

const StackDetails = () => {
  const { id = "" } = useParams<{ id: string }>();
  const [showModal, setShowModal] = useState(false);

  const handleAddGroupClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <section className="flex justify-between p-5 items-center">
        <h1 className="py-5 text-3xl font-bold">Course overview</h1>
        <Button variant="contained" onClick={handleAddGroupClick}>
          Add subject
        </Button>
      </section>
      <section className="p-5">
        <StackDetailsModal
          title={"Add Subject"}
          showModal={showModal}
          onClose={setShowModal}
          eduStackId={id}
        />
      </section>
      <section className="p-5">
        <StackDetailsTable id={id} setShowModal={setShowModal} />
      </section>
    </>
  );
};

export default StackDetails;
