import adminAxiosInstance from "../api/admin/admin.instance";

type TUserAnalyticsComponents = {
  id: string;
  name: string;
  maxPoint: number;
  maxPercent: number;
  points: number;
  percentageOfPoints: number;
};

export type TUserAnalytics = {
  getUsersAnalytics: [
    {
      components: TUserAnalyticsComponents[];
      exams: null;
      reviewInterns: null;
      componentsTotalPoints: number;
      componentsRecivedTotalPoints: number;
      componentsPercentageOfTotalPoints: number;
      examsTotalPoints: number;
      examsRecivedTotalPoints: number;
      examsPercentageOfTotalPoints: number;
      overallPoints: number;
      overallRecivedPoints: number;
      overallPercentageOfRating: 2;
      userId: string;
      firstName: string;
      lastName: string;
      email: string;
      imageUrl: string;
    }
  ];
};

export const getUserAnalyticsService = (groupId: string, ratingDate: string) =>
  adminAxiosInstance.get<TUserAnalytics>(
    `/EduGroup/${groupId}/users-anatalytic`,
    { params: { ratingDate } }
  );
