import React, { FC } from "react";
import { Autocomplete, IconButton, Input, TextField } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ISurveyQuestion } from "../../types/types.survey";

export interface SurveyQuestionScore {
  surveyQuestionId: string;
  text: string;
  score: number;
}

interface ConfigQuestionSelectorProps {
  options: ISurveyQuestion[];
  selectedQuestions: SurveyQuestionScore[];
  onScoreChange: (questionId: string, value: number) => void;
  onAdd: (question: ISurveyQuestion) => void;
  onRemove: (questionId: string) => void;
}

const ConfigQuestionSelector: FC<ConfigQuestionSelectorProps> = ({
  selectedQuestions,
  onScoreChange,
  onRemove,
  onAdd,
  options,
}) => {
  return (
    <div>
      <h3 className="text-xl font-bold mb-4">Question scores</h3>
      <Autocomplete
        disablePortal
        className="my-8"
        options={options}
        getOptionLabel={({ text }) => text}
        onChange={(_, value) => value && onAdd(value)}
        renderInput={(params) => (
          <TextField {...params} label="Select a question" />
        )}
      />
      <div className="flex flex-col gap-4">
        {selectedQuestions.map((qs) => (
          <div
            key={qs.surveyQuestionId}
            className="flex align-center justify-between gap-3"
          >
            <p className="text-lg font-bold w-[80%] p-2 border-gray-100 border-2 rounded-md ">
              {qs.text}
            </p>
            <div className="flex gap-4 w-[20%] self-end pl-2">
              <Input
                value={qs.score}
                type="number"
                onChange={(e) =>
                  onScoreChange(qs.surveyQuestionId, +e.target.value)
                }
              />
              <IconButton onClick={() => onRemove(qs.surveyQuestionId)}>
                <Delete />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConfigQuestionSelector;
