import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAdminUser from "../../hooks/queries/useAdminUser/useAdminUser";

const RequireAuth = () => {
  const location = useLocation();
  const adminUser = useAdminUser();

  if (!!adminUser) return <Outlet />;
  else return <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
