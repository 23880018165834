import { Button, Input } from "@mui/material";
import Modal from "../Modal";
import { StackDetailsModalType } from "./StackDetailsModal.config";
import { useForm } from "react-hook-form";
import useAddCourseOverviewSubject from "../../hooks/mutations/useAddCourseOverviewSubject";
import { IAddCourseOverview } from "../../types/types.courseOverviews";

const StackDetailsModal = ({
  showModal,
  title,
  onClose,
  eduStackId,
}: StackDetailsModalType) => {
  const { mutate: addCourseOverview } = useAddCourseOverviewSubject();
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      name: "",
      position: 0,
      eduStackId,
    },
  });
  const handleCancelClick = () => {
    reset();
    onClose(false);
  };

  const onSubmit = (body: IAddCourseOverview) => {
    addCourseOverview({ ...body });

    reset();
    onClose(false);
  };

  if (!showModal) return null;

  return (
    <Modal showModal={showModal} title={title} onClose={handleCancelClick}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col">
            <label htmlFor="name">Name</label>
            <Input id="name" required {...register("name")} />
          </div>
          <div className="flex flex-col">
            <label htmlFor="priority">Priority</label>
            <Input
              id="priority"
              required
              type="number"
              {...register("position")}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end gap-3 mt-10">
          <Button type="submit" variant="contained">
            Submit
          </Button>
          <Button variant="outlined" color="error" onClick={handleCancelClick}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default StackDetailsModal;
