import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSurvey } from "../../services/Edu/surveys.services";
import { SURVEYS_QUERY_KEY } from "../queries/surveys/useSurveys.config";
import { ISurveyFormPayload } from "../../types/types.survey";

interface UpdateSurveyMutationParams {
  id: string;
  payload: ISurveyFormPayload;
}

const useUpdateSurvey = () => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({ id, payload }: UpdateSurveyMutationParams) =>
      updateSurvey(id, payload),
    onSettled: () => {
      client.invalidateQueries([SURVEYS_QUERY_KEY]);
    },
  });
};

export default useUpdateSurvey;
