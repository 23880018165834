import { IEduIntern } from "../../../types/types.eduInterns";

export interface IMentorInternsTableProps {
  interns: IEduIntern[];
  groupName?: string;
}

interface Column {
  id: keyof IEduIntern;
  label: string;
  minWidth?: number;
  align?: "right";
}

export const MENTOR_INTERNS_TABLE_CONFIG: Column[] = [
  { id: "firstName", label: "First Name", minWidth: 150 },
  { id: "lastName", label: "Last Name", minWidth: 150 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "phoneNumber", label: "Phone number", minWidth: 100 },
  { id: "eduGroup", label: "Group", minWidth: 100 },
];
