import { IEduIntern } from "./types.eduInterns";

export enum SurveyType {
  "Review Mentor" = 1,
  "Review Intern" = 2,
  "Exam" = 3,
}

export enum SurveyQuestionType {
  MultipleChoice = 1,
  OpenEnded = 2,
  Rating = 3,
}

export interface ISurveyQuestionAnswer {
  id: string;
  value: string;
  isCorrect: boolean;
}

export interface ISurveyQuestion {
  id: string;
  text: string;
  questionType: SurveyQuestionType;
  surveyQuestionAnswers: ISurveyQuestionAnswer[];
  attachment?: {
    downloadUrl: string;
    extension: string;
  };
}

export interface ISurvey {
  id: string;
  title: string;
  surveyType: number;
  surveyQuestions: ISurveyQuestion[];
  users: IEduIntern[] | null;
}

export interface IPaginatedSurveys {
  page: number;
  pageSize: number;
  total: number;
  surveys: ISurvey[];
}

export interface ISurveyFormPayload {
  title: string;
  surveyType: SurveyType;
}

export interface createSurveyPayload {
  title: string;
  surveyType: SurveyType;
  userIds?: string[];
}

export interface ISurveySubmission {
  id: string;
  surveyConfigurationId: string;
  userId: string;
  totalScore: string;
  startDateTime: string;
  finishDateTime: string;
  status: number;
  statusText: string;
  surveyQuestionSubmissions: ISurveyQuestionSubmission[];
}

export interface Attachment {
  downloadUrl: string;
  extension: string;
}
export interface ISurveyQuestionSubmission {
  id: string;
  questionId: string;
  surveySubmissionId: string;
  answer: string;
  score: number;
  answerIds: string[];
  attachment: Attachment | null;
}
