import React from "react";
import { useParams } from "react-router-dom";
import useProject from "../../hooks/queries/useProject/useProject";
import MentorInternsTable from "../../components/Mentor/MentorInternsTable/MentorInternsTable.component";

const ProjectInterns = () => {
  const { id = "" } = useParams();

  const { data, isLoading } = useProject(id);

  if (!data || isLoading) {
    return <div />;
  }

  return (
    <div className="m-5">
      <h1 className="text-3xl font-bold py-8">
        {data.eduProject.name} - Interns
      </h1>
      <MentorInternsTable interns={data.eduProject.eduInterns || []} />
    </div>
  );
};

export default ProjectInterns;
