import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, TablePagination } from "@mui/material";
import DeleteModal from "../DeleteModal";
import { useState } from "react";
import useGetProjects from "../../hooks/queries/useGetProjects/useGetProjects";
import { IProjectTable, ProjectType } from "./ProjectTable.config";
import { IEduProjects } from "../../types/types.projects";
import useDeleteProject from "../../hooks/mutations/useDeleteProject";
import { Link, useSearchParams } from "react-router-dom";

const ProjectTable = ({ setProject, setShowModal }: IProjectTable) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [params, setParams] = useSearchParams();
  const page = +(params.get("page") || "1");
  const [projectId, setProjectId] = useState("");

  const projects = useGetProjects({ page });
  const { mutate: deleteProject } = useDeleteProject();

  const handleEditClick = (project: IEduProjects) => {
    setProject(project);
    setShowModal(true);
  };

  const handleDeleteClick = (id: string) => {
    setShowDeleteModal(true);
    setProjectId(id);
  };

  const handleDeleteConfirm = () => {
    deleteProject(projectId);
    setShowDeleteModal(false);
  };

  return (
    <>
      <DeleteModal
        showModal={showDeleteModal}
        handleSubmit={handleDeleteConfirm}
        onClose={() => setShowDeleteModal(false)}
      />
      <Paper className="overflow-hidden">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Type </TableCell>
                <TableCell>Interns </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects?.eduProjects?.map((project: any) => (
                <TableRow
                  key={project.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {project.name}
                  </TableCell>
                  <TableCell>{project.description}</TableCell>
                  <TableCell>{Object.keys(ProjectType)[1]}</TableCell>
                  <TableCell>
                    <Link to={`/projects/${project.id}/interns`}>
                      <Button>View Interns</Button>
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      style={{ marginRight: "10px" }}
                      onClick={() => handleEditClick(project)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteClick(project.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={projects?.total || 1}
          rowsPerPage={projects?.pageSize || 20}
          page={page - 1}
          onPageChange={(_, newPage: number) =>
            setParams((params) => {
              params.set("page", String(newPage + 1));
              return params;
            })
          }
          rowsPerPageOptions={[0]}
        />
      </Paper>
    </>
  );
};

export default ProjectTable;
