import React from "react";
import { useParams } from "react-router-dom";

import { useGetGroup } from "../../hooks/queries/useGetGroup";
import MentorInternsTable from "../../components/Mentor/MentorInternsTable/MentorInternsTable.component";

const GroupInterns = () => {
  const { id = "" } = useParams<{ id: string }>();

  const { data, isLoading } = useGetGroup(id);

  if (!data || isLoading) {
    return <div />;
  }

  return (
    <div className="p-5">
      <h1 className="text-3xl font-bold mb-8">{`${data.eduGroup.name} - Interns`}</h1>
      <MentorInternsTable
        interns={data.eduGroup.eduInterns}
        groupName={data.eduGroup.name}
      />
    </div>
  );
};

export default GroupInterns;
