import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { COURSE_OVERVIEW_URL } from "../api/constants";
import {
  IGetCourseOverviewsResponse,
  IUseGetCourseOverviews,
} from "../types/types.courseOverviews";

export const getCourseOverviewSubjects = async ({
  eduStackId,
  page = 1,
  pageSize = 20,
}: IUseGetCourseOverviews) => {
  const { data }: AxiosResponse<IGetCourseOverviewsResponse> =
    await adminAxiosInstance.get(`${COURSE_OVERVIEW_URL}`, {
      params: { page, pageSize, eduStackId },
    });
  return data;
};
