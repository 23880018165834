import { Outlet } from "react-router-dom";
import useAdminUser from "../../hooks/queries/useAdminUser/useAdminUser";
import Sidebar from "../Sidebar";

const Layout = () => {
  const adminUser = useAdminUser();

  return (
    <main className={`${adminUser ? "mt-[100px]" : ""}`}>
      {adminUser ? <Sidebar /> : null}
      <div className={`${adminUser ? "w-4/5 ml-[20%]" : ""}`}>
        <Outlet />
      </div>
    </main>
  );
};

export default Layout;
