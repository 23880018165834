import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addTeamlead } from "../../services/addTeamlead.services";
import { IAddTeamLeadsParams } from "../../types/types.teamleads";
import { TEAMLEADS_QUERY_KEY } from "../queries/useGetTeamleads/useGetTeamleads.config";

const useAddTeamlead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IAddTeamLeadsParams) => addTeamlead(body),
    onSuccess: () => {
      queryClient.refetchQueries([TEAMLEADS_QUERY_KEY]);
    },
  });
};

export default useAddTeamlead;
