import { useQuery } from "@tanstack/react-query";
import { STACK_QUERY_KEY } from "./useGetStack.config";
import { getStack } from "../../../services/getStacks.services";

const useGetStack = (id: string | undefined) => {
  return useQuery({
    queryKey: [STACK_QUERY_KEY, id],
    queryFn: () => getStack(id!),
    enabled: !!id,
  });
};

export default useGetStack;
