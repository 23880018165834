import { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import LockIconOpen from "@mui/icons-material/LockOpen";
import {
  DEFAULT_PAGE_SIZE,
  MENTORS_TABLE_CONFIG,
  mentorSeniorityDefinitions,
  mentorStatusDefinitions,
} from "./MentrosTable.config";
import useGetMentors from "../../../hooks/queries/useGetMentors/useGetMentors";
import MentorModal from "../MentorModal";
import useModalState from "../../../hooks/useModalState";
import { IMentor } from "../../../types/types.mentors";
import MentorInfoModal from "../MentorInfoModal";
import { useMutation } from "@tanstack/react-query";
import { activateMentor } from "../../../services/Edu/mentors.services";
import { WEB_ACTIOVATION_URL } from "../../../config";
import { toast } from "react-toastify";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import useExportMentors from "../../../hooks/mutations/useExportMentors";

const MentorsTable = () => {
  const { modalState, updateModalState } = useModalState<IMentor>();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [mentorInfo, setMentorInfo] = useState<IMentor | undefined>(undefined);

  const [params, setParams] = useSearchParams();
  const page = +(params.get("page") || "1");

  const {
    data: { eduMentors = [], pageSize = 0, total = 0 } = {},

    updateSearchQuery,
    updateStatus,
    status,
    seniority,
    updateSeniority,
  } = useGetMentors({ page });

  const { mutate: activateMentorHandler, isLoading: isActivatingMentor } =
    useMutation({
      mutationFn: (mentorId: string) =>
        activateMentor(mentorId, WEB_ACTIOVATION_URL),
      onSuccess: () => {
        toast.success("Activation link is sent to mentor", {
          position: "bottom-right",
          closeOnClick: true,
          autoClose: 3000,
        });
      },
    });

  const { mutate: exportMentors, isLoading: isExporting } = useExportMentors();

  const handleViewClick = (mentor: IMentor) => {
    setMentorInfo(mentor);
    setShowInfoModal(true);
  };

  const handleInfoModalClose = () => {
    setShowInfoModal(false);
    setMentorInfo(undefined);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSearchQuery(event.target.value as string);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    updateStatus(event.target.value as string);
  };

  const handleSeniorityChange = (event: SelectChangeEvent) => {
    updateSeniority(event.target.value as string);
  };

  return (
    <>
      <div className="m-5">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold">Mentors</h1>
          <div className="py-5 flex items-center justify-between">
            <Button
              onClick={() => updateModalState({ type: "PERSIST", open: true })}
              variant="contained"
              color="success"
            >
              Add new mentor
            </Button>
          </div>
          <MentorInfoModal
            showModal={showInfoModal}
            onClose={handleInfoModalClose}
            title={"Mentor Information"}
            mentor={mentorInfo}
          />
        </div>
        <Box
          sx={{
            width: 300,
            marginLeft: "auto",
            marginBottom: "20px",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={handleNameChange}
          />

          <FormControl fullWidth sx={{ marginTop: "10px" }}>
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              label="Status"
              value={status}
              onChange={handleStatusChange}
            >
              <MenuItem value={""}>All</MenuItem>
              <MenuItem value={1}>Mentorship</MenuItem>
              <MenuItem value={2}>MentorshipSuspended</MenuItem>
              <MenuItem value={3}>MentorshipInProgress</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: "10px" }}>
            <InputLabel id="seniority-select-label">Seniority</InputLabel>
            <Select
              labelId="seniority-select-label"
              id="seniority-select"
              label="Seniority"
              value={seniority}
              onChange={handleSeniorityChange}
            >
              <MenuItem value={""}>All</MenuItem>
              <MenuItem value={3}>Senior</MenuItem>
              <MenuItem value={2}>Middle</MenuItem>
              <MenuItem value={1}>Junior</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <div className="flex justify-end mb-4">
          <Button
            variant="contained"
            onClick={() => exportMentors()}
            disabled={isExporting}
          >
            export
          </Button>
        </div>
        <Paper className="overflow-hidden">
          <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="bg-gray-100 font-bold">
                <TableRow>
                  {MENTORS_TABLE_CONFIG.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell style={{ minWidth: 170 }}>Interns</TableCell>
                  <TableCell style={{ minWidth: 170 }}>Active</TableCell>
                  <TableCell style={{ minWidth: 170 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eduMentors.map((mentor) => {
                  return (
                    <TableRow
                      className="cursor-pointer"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={mentor.id}
                    >
                      <TableCell>{mentor.firstName}</TableCell>
                      <TableCell>{mentor.lastName}</TableCell>
                      <TableCell>{mentor.email}</TableCell>
                      <TableCell>{mentor.eduStack?.name || ""}</TableCell>
                      <TableCell>{mentor.phoneNumber}</TableCell>
                      <TableCell>{mentor.idNumber}</TableCell>
                      <TableCell>
                        {mentorSeniorityDefinitions[mentor.eduMentorSeniority]}
                      </TableCell>
                      <TableCell>
                        {mentorStatusDefinitions[mentor.eduMentorStatus]}
                      </TableCell>
                      <TableCell>
                        <Button sx={{ px: 0 }}>
                          <Link to={`/mentors/${mentor.id}/interns`}>
                            View interns
                          </Link>
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          endIcon={<LockIconOpen />}
                          sx={{ px: 0, fontWeight: 600 }}
                          size="small"
                          color={"success"}
                          onClick={() => {
                            activateMentorHandler(mentor.id);
                          }}
                          disabled={isActivatingMentor}
                        >
                          Activate
                        </Button>
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-1">
                          <Button
                            variant="contained"
                            style={{ marginRight: "10px" }}
                            onClick={() => handleViewClick(mentor)}
                          >
                            View
                          </Button>
                          <Button
                            variant="outlined"
                            style={{ marginRight: "10px" }}
                            onClick={() =>
                              updateModalState({
                                type: "PERSIST",
                                open: true,
                                payload: mentor,
                              })
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() =>
                              updateModalState({
                                type: "DELETE",
                                open: true,
                                payload: mentor,
                              })
                            }
                          >
                            Delete
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {total > pageSize ? (
            <TablePagination
              component="div"
              count={total}
              rowsPerPage={DEFAULT_PAGE_SIZE}
              page={page - 1}
              onPageChange={(_, page) => {
                setParams((params) => {
                  params.set("page", String(page + 1));
                  return params;
                });
              }}
              rowsPerPageOptions={[0]}
            />
          ) : null}
        </Paper>
      </div>
      {modalState.open && (
        <MentorModal
          mentor={modalState.payload}
          type={modalState.type}
          onClose={() => updateModalState({ open: false })}
        />
      )}
    </>
  );
};

export default MentorsTable;
