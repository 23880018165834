import { useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Radio,
  RadioGroup,
  FormLabel,
  Button,
  Select,
  MenuItem,
} from "@mui/material";

import {
  IEduInternFormProps,
  INTERN_FORM_FIELDS,
} from "./EduInternsForm.config";

import {
  EduInternStatus,
  IEduInternFormPayload,
} from "../../../types/types.eduInterns";
import {
  dateToUtcFormatter,
  transformInternObjectForForm,
} from "../../../helpers";
import useGetStacks from "../../../hooks/queries/useGetStacks";
import useGetGroups from "../../../hooks/queries/useGetGroups";
import useGetMentors from "../../../hooks/queries/useGetMentors";
import useGetRegions from "../../../hooks/queries/useRegions";
import useGetProjects from "../../../hooks/queries/useGetProjects/useGetProjects";

const EduInternForm = ({
  eduIntern,
  onClose,
  onCreateIntern,
  onEditIntern,
  isLoading,
}: IEduInternFormProps) => {
  const editInternFormValues = useMemo(
    () => (eduIntern ? transformInternObjectForForm(eduIntern) : {}),
    [eduIntern]
  );

  const stacks = useGetStacks({
    page: 1,
    pageSize: 50,
  });

  const groups = useGetGroups({
    page: 1,
    pageSize: 50,
  });
  const { data: { eduMentors = [] } = {} } = useGetMentors({
    page: 1,
    pageSize: 150,
  });

  const { data: { regions = [] } = {} } = useGetRegions({
    page: 1,
    pageSize: 50,
  });

  const projects = useGetProjects({ page: 1, pageSize: 50 });
  // console.log(getValues())
  const { control, handleSubmit, getValues } = useForm<IEduInternFormPayload>({
    defaultValues: editInternFormValues,
  });

  const onSubmit = (data: IEduInternFormPayload) => {
    const body: IEduInternFormPayload = { ...data };
    for (let key in body) {
      if (body[key as keyof IEduInternFormPayload] === "") {
        // @ts-ignore
        body[key] = null;
      }
    }

    const {
      dateOfBirth,
      internshipEndDate,
      internshipStartDate,
      userStatus,
      eduInternStatus,
    } = body;
    body.dateOfBirth = dateToUtcFormatter(dateOfBirth);
    body.internshipEndDate = dateToUtcFormatter(internshipEndDate);
    body.internshipStartDate = dateToUtcFormatter(internshipStartDate);

    body.userStatus = !!userStatus && userStatus !== 1 ? 2 : 1;
    body.eduInternStatus = Number(eduInternStatus) as EduInternStatus;

    if (eduIntern) {
      body.id = eduIntern.id;
      onEditIntern(body);
    } else {
      onCreateIntern(body);
    }
  };

  return (
    <form className="flex flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
      {INTERN_FORM_FIELDS.textFields.map((item) => {
        return (
          <Controller
            key={item.fieldName}
            name={item.fieldName}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                required={item.required}
                type={item.type}
                label={item.label}
                InputLabelProps={item.InputLabelProps}
                variant="outlined"
                fullWidth
              />
            )}
          />
        );
      })}
      <Controller
        name="stackId"
        control={control}
        render={({ field }) => (
          <FormControl {...field} disabled>
            <FormLabel id="intern-status-radio-buttons-group">
              Intern Stack
            </FormLabel>
            <Select {...field} readOnly>
              {stacks?.eduStacks.map((stack) => (
                <MenuItem value={stack.id}>{stack.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="groupId"
        control={control}
        render={({ field }) => (
          <FormControl {...field}>
            <FormLabel id="intern-status-radio-buttons-group">
              Intern Group
            </FormLabel>
            <Select {...field}>
              {groups?.eduGroups.map((group) => (
                <MenuItem value={group.id}>{group.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="mentorId"
        control={control}
        render={({ field }) => (
          <FormControl {...field}>
            <FormLabel id="intern-status-radio-buttons-group">
              Intern Mentors
            </FormLabel>
            <Select {...field}>
              {eduMentors.map((mentor) => (
                <MenuItem value={mentor.id}>
                  {mentor.firstName} {mentor.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="regionId"
        control={control}
        render={({ field }) => (
          <FormControl {...field}>
            <FormLabel id="intern-status-radio-buttons-group">
              Intern Region
            </FormLabel>
            <Select {...field}>
              {regions.map((reg) => (
                <MenuItem value={reg.id}>{reg.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="eduInternStatus"
        control={control}
        render={({ field }) => (
          <FormControl {...field} required>
            <FormLabel id="intern-status-radio-buttons-group">
              Intern Status
            </FormLabel>
            <RadioGroup row aria-labelledby="intern-status-radio-buttons-group">
              {INTERN_FORM_FIELDS.internStatus.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  checked={Number(getValues("eduInternStatus")) === item.value}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
      <Controller
        name="projectIds"
        control={control}
        render={({ field }) => (
          <FormControl {...field}>
            <FormLabel id="intern-projects-menu">Projects</FormLabel>
            <Select multiple {...field} value={getValues("projectIds") ?? []}>
              {projects?.eduProjects.map((project) => (
                <MenuItem value={project.id}>{project.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="userStatus"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            {...field}
            disabled
            checked={!!getValues("userStatus") && getValues("userStatus") !== 1}
            control={<Checkbox readOnly />}
            label="Active"
          />
        )}
      />

      <div className="flex gap-2 flex-row items-center justify-end">
        <Button
          className="block"
          type="button"
          variant="outlined"
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          disabled={isLoading}
          type="submit"
          variant="contained"
          color="success"
        >
          SUBMIT
        </Button>
      </div>
    </form>
  );
};

export default EduInternForm;
