import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { COURSE_OVERVIEW_URL } from "../api/constants";
import { IUseGetCourseOverview } from "../types/types.courseOverviews";

export const getCourseOverviewSubject = async (id: string) => {
  const { data }: AxiosResponse<IUseGetCourseOverview> =
    await adminAxiosInstance.get(`${COURSE_OVERVIEW_URL}/${id}`);
  return data;
};
