import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { COURSE_OVERVIEW_URL } from "../api/constants";
import { IAddComponent } from "../types/types.components";
import { IAddCourseOverview } from "../types/types.courseOverviews";

export const addCourseOverviewSubjects = async (body: IAddCourseOverview) => {
  const { data }: AxiosResponse<IAddComponent> = await adminAxiosInstance.post(
    `${COURSE_OVERVIEW_URL}`,
    {
      ...body,
    },
  );
  return data;
};
