import axios, { AxiosRequestConfig } from "axios";
import { BASE_ADMIN_URL } from "../constants";
import { getStoredToken } from "../../helpers";
import { toast } from "react-toastify";

const adminApiConfig: AxiosRequestConfig = {
  baseURL: BASE_ADMIN_URL,
  headers: {
    "Content-Type": "application/json",
  },
};
const adminAxiosInstance = axios.create(adminApiConfig);

adminAxiosInstance.interceptors.request.use(
  (request) => {
    const token = getStoredToken();
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

adminAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status !== 401 && error.response.status !== 403) {
      toast.error(error.response.data?.Errors?.[0] || error.message);
    }
    return Promise.reject(error);
  }
);

export default adminAxiosInstance;
