import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { COMPONENT_RATING_QUERY_KEY } from "../queries/useGetComponentRating/useGetComponentRating.config";
import { IUseUpdateComponentRatings } from "../../types/types.componentRatings";
import { updateComponentRating } from "../../services/UpdateComponentRating.services";

const useUpdateComponentRating = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IUseUpdateComponentRatings) =>
      updateComponentRating(body),
    onSuccess: () => {
      queryClient.refetchQueries([COMPONENT_RATING_QUERY_KEY]);
      toast.success("Component rating updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        closeOnClick: true,
      });
    },
  });
};

export default useUpdateComponentRating;
