import { AxiosResponse } from "axios";
import adminAxiosInstance from "../../api/admin/admin.instance";
import {
  IGetMentor,
  IGetMentors,
  IMentorFormPayload,
} from "../../types/types.mentors";
import { MENTOR_URL } from "../../api/constants";
import { downloadFile } from "../../helpers/downloadFile";

export const getMentors = async (page?: number, pageSize?: number) => {
  const { data }: AxiosResponse<IGetMentors> = await adminAxiosInstance.get(
    `${MENTOR_URL}`,
    {
      params: {
        page,
        pageSize,
      },
    }
  );
  return data;
};

export const createMentor = async (payload: IMentorFormPayload) => {
  const { data }: AxiosResponse<void> = await adminAxiosInstance.post(
    `${MENTOR_URL}`,
    payload
  );
  return data;
};

export const editMentor = async (payload: IMentorFormPayload) => {
  const { data }: AxiosResponse<void> = await adminAxiosInstance.put(
    `${MENTOR_URL}/${payload.id}`,
    payload
  );
  return data;
};

export const deleteMentor = async (mentorId: string) => {
  const { data }: AxiosResponse<IGetMentors> = await adminAxiosInstance.delete(
    `${MENTOR_URL}/${mentorId}`
  );
  return data;
};

export const getMentor = async (mentorId: string) => {
  const { data }: AxiosResponse = await adminAxiosInstance.get<IGetMentor>(
    `${MENTOR_URL}/${mentorId}`
  );
  return data;
};

export const activateMentor = async (mentorId: string, callbackUrl: string) => {
  const { data }: AxiosResponse = await adminAxiosInstance.post(
    `${MENTOR_URL}/${mentorId}/activate`,
    {
      callbackUrl,
    }
  );
  return data;
};

export const exportMentors = async () => {
  const response = await adminAxiosInstance.get(`${MENTOR_URL}/export`, {
    responseType: "blob",
    headers: {
      Accept: "*/*",
    },
  });

  downloadFile(response, "EduMentors.xlsx");
};
