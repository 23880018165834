import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { COMPONENT_URL } from "../api/constants";
import { IAddComponent } from "../types/types.components";

export const addComponent = async (body: IAddComponent) => {
  const { data }: AxiosResponse<IAddComponent> = await adminAxiosInstance.post(
    `${COMPONENT_URL}`,
    {
      ...body,
    },
  );
  return data;
};
