import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IUseGetCourseOverview } from "../../../types/types.courseOverviews";
import { OVERVIEWS_QUERY_KEY } from "../useGetCourseOverviewSubjects/useGetCourseOverviewSubjects.config";
import { getCourseOverviewSubject } from "../../../services/getCourseOverviewSubject.services";

export default function useGetCourseOverviewSubject(id: string) {
  const queryClient = useQueryClient();
  return useQuery<IUseGetCourseOverview>([OVERVIEWS_QUERY_KEY, id], {
    queryFn: () => getCourseOverviewSubject(id),
    onError: () => {
      queryClient.setQueryData([OVERVIEWS_QUERY_KEY], null);
    },
  });
}
