import { useMutation } from "@tanstack/react-query";
import {
  CreateSurveyQuestionPayload,
  createSurveyQuestion,
} from "../../services/Edu/surveyQuestions.services";
import { AxiosError } from "axios";

const useCreateSurveyQuestions = () => {
  return useMutation<void, AxiosError, CreateSurveyQuestionPayload>({
    mutationFn: createSurveyQuestion,
  });
};

export default useCreateSurveyQuestions;
