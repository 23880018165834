import adminAxiosInstance from "../../api/admin/admin.instance";
import { SURVEY_QUESTION_ANSWER_URL } from "../../api/constants";

export interface CreateSurveyQuestionAnswerPayload {
  value: string;
  isCorrect: boolean;
  surveyQuestionId: string;
}

export interface UpdateSurveyQuestionAnswerPayload {
  answerId: string;
  value: string;
  isCorrect: boolean;
}

export const createSurveyQuestionAnswers = async (
  payloads: CreateSurveyQuestionAnswerPayload[]
) => {
  try {
    const requests = payloads.map(
      async (p) =>
        await adminAxiosInstance.post(`${SURVEY_QUESTION_ANSWER_URL}`, p)
    );

    await Promise.allSettled(requests);
  } catch (ex) {
    console.warn(ex);
  }
};

export const deleteSurveyQuestionAnswer = async (answerId: string) => {
  const { data } = await adminAxiosInstance.delete(
    `${SURVEY_QUESTION_ANSWER_URL}/${answerId}`
  );

  return data;
};

export const updateSurveyQuestionAnswers = async (
  answers: UpdateSurveyQuestionAnswerPayload[]
) => {
  try {
    const requests = answers.map(
      async ({ answerId, isCorrect, value }) =>
        await adminAxiosInstance.put(
          `${SURVEY_QUESTION_ANSWER_URL}/${answerId}`,
          { value, isCorrect }
        )
    );

    await Promise.allSettled(requests);
  } catch (ex) {
    console.warn(ex);
  }
};
