import { IEduProjects } from "./types.projects";
import { IStack } from "./types.stacks";
import { IEduTeamLeads } from "./types.teamleads";
import { IRegion } from "./types.region";
import { IEduSquadLead } from "./types.eduSquadLead";
import { IGroup } from "./types.groups";
import { IMentorBase } from "./types.mentor";

export type IEduInternStatus =
  | "Not Started"
  | "In Process"
  | "Canceled"
  | "Ready to Level Up"
  | "Junior";

const EduInternStatuses = [1, 2, 3, 4, 5] as const;

export type EduInternStatus = (typeof EduInternStatuses)[number];

export const EduInternStatusDefinitions = {
  [EduInternStatuses[0]]: "NotStarted",
  [EduInternStatuses[1]]: "InternInProcess",
  [EduInternStatuses[2]]: "Canceled",
  [EduInternStatuses[3]]: "ReadyToLevelUp",
  [EduInternStatuses[4]]: "Junior",
};

interface IBaseEduIntern {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  idNumber: string;
  eduInternStatus: EduInternStatus;
}
export interface IEduIntern extends IBaseEduIntern {
  id: string;
  dateOfBirthUtc: string;
  internshipStartDateUtc: string;
  internshipEndDateUtc: string;
  eduStack: IStack | null;
  eduMentor?: IMentorBase;
  eduGroup: IGroup;
  region?: IRegion;
  eduTeamLead?: IEduTeamLeads;
  eduProjects?: IEduProjects[];
  eduSquadLead?: IEduSquadLead;
  userStatus: 1 | 2;
  userId: string | null;
}

export interface IEduInternFormPayload extends IBaseEduIntern {
  id?: string;
  userStatus: 1 | 2;
  stackId: string | null;
  projectIds: string[] | null;
  regionId: string | null;
  groupId: string | null;
  teamLeadId: string | null;
  squadLeadId: string | null;
  mentorId: string | null;
  dateOfBirth: string;
  internshipStartDate: string;
  internshipEndDate: string;
}

export interface IPaginatedEduInterns {
  eduInterns: IEduIntern[];
  page: number;
  pageSize: number;
  total: number;
}
