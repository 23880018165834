import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getTeamleads } from "../../../services/getTeamleads.services";
import {
  IGetTeamLeadsParams,
  IGetTeamLeadsResponse,
} from "../../../types/types.teamleads";
import { TEAMLEADS_QUERY_KEY } from "./useGetTeamleads.config";

export default function useGetTeamleads(params: IGetTeamLeadsParams) {
  const queryClient = useQueryClient();
  const { data: teamleads } = useQuery<IGetTeamLeadsResponse>(
    [TEAMLEADS_QUERY_KEY, params],
    {
      queryFn: () => getTeamleads(params),
      onError: () => {
        queryClient.setQueryData([TEAMLEADS_QUERY_KEY], null);
      },
      keepPreviousData: true,
    }
  );

  return teamleads;
}
