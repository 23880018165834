import DeleteModal from "../../DeleteModal/DeleteModal.component";
import Modal from "../../Modal/Modal.component";
import { IEduSquadLeadsModalProps } from "./SquadLeadModal.config";
// import MentorForm from "../MentorForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import SquadLeadForm from "../SquadLeadForm";
import { deleteSquadLead } from "../../../services/Edu/squadLeads.services";
import { SQUAD_LEADS_QUERY_KEY } from "../../../hooks/queries/useGetSquadLeads/useGetSquadLeads.config";

const SquadLeedModal = ({
  type,
  onClose,
  squadLead,
}: IEduSquadLeadsModalProps) => {
  const title = squadLead ? "Edit squad lead" : "Create a new squad lead";
  const squadLeadId = squadLead?.id || null;

  const queryClient = useQueryClient();

  const { mutate: deleteSquadLeadHandler } = useMutation({
    mutationFn: deleteSquadLead,
    onSettled: () => {
      queryClient.invalidateQueries([SQUAD_LEADS_QUERY_KEY]);
    },
  });

  const handleDelete = () => {
    if (!squadLeadId) return;
    deleteSquadLeadHandler(squadLeadId, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  if (type === "PERSIST") {
    return (
      <Modal title={title} onClose={onClose} showModal>
        <SquadLeadForm onClose={onClose} squadLead={squadLead} />
      </Modal>
    );
  }

  if (type === "DELETE" && squadLeadId) {
    return (
      <DeleteModal onClose={onClose} handleSubmit={handleDelete} showModal />
    );
  }

  return null;
};

export default SquadLeedModal;
