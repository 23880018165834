import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { STACK_URL } from "../api/constants";

export const deleteStack = async (id: string) => {
  const { data }: AxiosResponse = await adminAxiosInstance.delete(
    `${STACK_URL}/${id}`
  );
  return data;
};
