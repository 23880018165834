import { Button, TextField } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useSetQuestionScore from "../../hooks/mutations/useSetQuestionScore";
import { SURVEY_SUBMISSION_QUERY_KEY } from "../../hooks/queries/surveySubmission/useSurveySubmission";
import { SurveyConfigurationQuestion } from "../../types/types.surveyConfiguration";
import Modal from "../Modal/Modal.component";

interface SubmissionRatingModalProps {
  question: SurveyConfigurationQuestion | undefined;
  answer: string;
  onClose: () => void;
  submissionId: string;
}

const SubmissionRatingModal: FC<SubmissionRatingModalProps> = ({
  question,
  onClose,
  answer,
  submissionId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ score: number }>({ defaultValues: { score: 0 } });
  const queryClient = useQueryClient();

  const { mutate, isLoading: isRating } = useSetQuestionScore();

  const rateQuestion: SubmitHandler<{
    score: number;
  }> = (data, e) => {
    e?.preventDefault();

    if (!question) {
      return;
    }

    const { score } = data;

    mutate(
      { questionId: question.id, score, submissionId },
      {
        onSuccess: () => {
          toast.success("Question rated successfully");
          onClose();
        },
        onSettled: () => {
          queryClient.invalidateQueries([
            SURVEY_SUBMISSION_QUERY_KEY,
            submissionId,
          ]);
        },
      }
    );
  };

  if (!question) {
    return null;
  }

  return (
    <Modal title={"Rate question"} showModal={!!question} onClose={onClose}>
      <div className="flex flex-col items-start w-full pl-5">
        <p className="font-bold text-lg pb-4">
          {question.text} ({question.score} points)
        </p>
        <p className="pb-2">Answer:</p>
        <p>{answer}</p>
        <form
          className="mt-8 flex flex-col items-start w-full"
          onSubmit={handleSubmit(rateQuestion)}
        >
          <TextField
            type="number"
            label="Score"
            fullWidth={false}
            {...register("score", {
              required: true,
              max: {
                value: question.score,
                message: `Maximum value is ${question.score}`,
              },
              min: { value: 0, message: "Minimum value is 0" },
            })}
            error={!!errors.score}
            helperText={errors.score?.message}
          />
          <Button
            className="self-end"
            variant="contained"
            type="submit"
            disabled={isRating}
          >
            Rate
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default SubmissionRatingModal;
