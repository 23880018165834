import { useMutation } from "@tanstack/react-query";
import {
  UpdateSurveyQuestionPayload,
  updateSurveyQuestion,
} from "../../services/Edu/surveyQuestions.services";

const useUpdateSurveyQuestion = () => {
  return useMutation({
    mutationFn: (
      questions: { id: string; payload: UpdateSurveyQuestionPayload }[]
    ) => updateSurveyQuestion(questions),
  });
};

export default useUpdateSurveyQuestion;
