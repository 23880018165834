import { Button } from "@mui/material";
import { useState } from "react";
import TeamleadInfoModal from "../../components/TeamleadInfoModal";
import TeamleadModal from "../../components/TeamleadModal";
import TeamleadTable from "../../components/TeamleadTable";
import { IEduTeamLeads } from "../../types/types.teamleads";

const Teamleads = () => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [teamleadInfo, setTeamleadInfo] = useState<IEduTeamLeads>();

  const handleCloseModal = () => {
    setShowModal(false);
    setTeamleadInfo(undefined);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <section className="flex justify-between p-5 items-center">
        <div>
          <h1 className="py-5 text-3xl font-bold">Team leads</h1>
          <TeamleadInfoModal
            teamleadInfo={teamleadInfo}
            onClose={() => setShowInfoModal(false)}
            showModal={showInfoModal}
            title="Teamlead Information"
          />
          <TeamleadModal
            showModal={showModal}
            title={teamleadInfo ? "Update Teamlead" : "Add Teamlead"}
            teamleadInfo={teamleadInfo}
            onClose={handleCloseModal}
          />
        </div>
        <Button variant="contained" onClick={handleOpenModal}>
          Add Teamlead
        </Button>
      </section>
      <section className="p-5">
        <TeamleadTable
          setTeamleadInfo={setTeamleadInfo}
          setShowInfoModal={setShowInfoModal}
          setShowModal={setShowModal}
        />
      </section>
    </>
  );
};

export default Teamleads;
