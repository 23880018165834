import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTeamlead } from "../../services/deleteTeamlead";
import { TEAMLEADS_QUERY_KEY } from "../queries/useGetTeamleads/useGetTeamleads.config";

const useDeleteTeamlead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteTeamlead(id),
    onSuccess: () => {
      queryClient.refetchQueries([TEAMLEADS_QUERY_KEY]);
    },
  });
};

export default useDeleteTeamlead;
