import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { TEAMLEADS_URL } from "../api/constants";
import {
  IGetTeamLeadsParams,
  IGetTeamLeadsResponse,
} from "../types/types.teamleads";
import { DEFAULT_PAGE_SIZE } from "../components/Mentor/MentorsTable/MentrosTable.config";

export const getTeamleads = async ({
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  searchQuery = "",
}: IGetTeamLeadsParams) => {
  const { data }: AxiosResponse<IGetTeamLeadsResponse> =
    await adminAxiosInstance.get(
      `${TEAMLEADS_URL}?page=${page}&pageSize=${pageSize}&searchQuery=${searchQuery}`
    );
  return data;
};
