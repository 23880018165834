import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IUpdateComponent } from "../../types/types.components";
import { COMPONENTS_QUERY_KEY } from "../queries/useGetComponents/useGetComponents.config";
import { updateComponent } from "../../services/updateComponent.services";

const useAUpdateComponent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IUpdateComponent) => updateComponent(body),
    onSuccess: () => {
      queryClient.refetchQueries([COMPONENTS_QUERY_KEY]);
    },
  });
};

export default useAUpdateComponent;
