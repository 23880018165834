import { useQuery, useQueryClient } from "@tanstack/react-query";
import { OVERVIEWS_QUERY_KEY } from "./useGetCourseOverviewSubjects.config";
import {
  IGetCourseOverviewsResponse,
  IUseGetCourseOverviews,
} from "../../../types/types.courseOverviews";
import { getCourseOverviewSubjects } from "../../../services/getCourseOverviewSubjects.services";

export default function useGetCourseOverviewSubjects(
  params: IUseGetCourseOverviews,
) {
  const queryClient = useQueryClient();
  return useQuery<IGetCourseOverviewsResponse>([OVERVIEWS_QUERY_KEY, params], {
    queryFn: () => getCourseOverviewSubjects(params),
    onError: () => {
      queryClient.setQueryData([OVERVIEWS_QUERY_KEY], null);
    },
  });
}
