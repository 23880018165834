import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockIconOpen from "@mui/icons-material/LockOpen";
import useGetTeamleads from "../../hooks/queries/useGetTeamleads";
import { Button, TablePagination } from "@mui/material";
import { ITeamleadTable } from "./TeamleadTable.config";
import { IEduTeamLeads } from "../../types/types.teamleads";
import DeleteModal from "../DeleteModal";
import { useState } from "react";
import useDeleteTeamlead from "../../hooks/mutations/useDeleteTeamlead";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { activateTeamLead } from "../../services/Edu/teamLeads.services";
import { WEB_ACTIOVATION_URL } from "../../config";
import { toast } from "react-toastify";
import { useDebouncedState } from "../../hooks/debounce/useDebounce";
import useExportTeamLeads from "../../hooks/mutations/useExportTeamLeads";
import { useSearchParams } from "react-router-dom";

const TeamleadTable = ({
  setTeamleadInfo,
  setShowInfoModal,
  setShowModal,
}: ITeamleadTable) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [params, setParams] = useSearchParams();
  const page = +(params.get("page") || "1");
  const [teamleadId, setTeamleadId] = useState("");
  const [searchQuery, setSearchQuery] = useDebouncedState("" as string, 500);

  const { mutate: deleteTeamlead } = useDeleteTeamlead();
  const teamleads = useGetTeamleads({ page, searchQuery });

  const { mutate: activateTeamLeadHandler, isLoading: isActivatingTeamLead } =
    useMutation<void, AxiosError, { teamLeadId: string }>({
      mutationFn: ({ teamLeadId }) =>
        activateTeamLead(teamLeadId, WEB_ACTIOVATION_URL),
      onSuccess: () => {
        toast.success("Activation link is sent to intern", {
          position: "bottom-right",
          closeOnClick: true,
          autoClose: 3000,
        });
      },
    });

  const { mutate: exportTeamLeads, isLoading: isExporting } =
    useExportTeamLeads();

  const handleViewClick = (teamlead: IEduTeamLeads) => {
    setShowInfoModal(true);
    setTeamleadInfo(teamlead);
  };

  const handleEditClick = (teamlead: IEduTeamLeads) => {
    setTeamleadInfo(teamlead);
    setShowModal(true);
  };

  const handleDeleteClick = (id: string) => {
    setShowDeleteModal(true);
    setTeamleadId(id);
  };

  const handleDeleteConfirm = () => {
    deleteTeamlead(teamleadId);
    setShowDeleteModal(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value as string);
  };

  return (
    <>
      <DeleteModal
        showModal={showDeleteModal}
        handleSubmit={handleDeleteConfirm}
        onClose={() => setShowDeleteModal(false)}
      />
      <Box
        component="form"
        sx={{
          width: 300,
          marginLeft: "auto",
          marginBottom: "20px",
        }}
      >
        <TextField
          id="outlined-basic2"
          label="Search"
          variant="outlined"
          onChange={handleNameChange}
        />
      </Box>
      <div className="flex justify-end mb-4">
        <Button
          variant="contained"
          onClick={() => exportTeamLeads()}
          disabled={isExporting}
        >
          export
        </Button>
      </div>
      <Paper className="overflow-hidden">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Email </TableCell>
                <TableCell>Phone number</TableCell>
                <TableCell>Stack</TableCell>
                <TableCell>Active</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamleads?.eduTeamLeads?.map((teamlead) => (
                <TableRow
                  key={teamlead.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {teamlead.firstName}
                  </TableCell>
                  <TableCell>{teamlead.lastName}</TableCell>
                  <TableCell>{teamlead.email}</TableCell>
                  <TableCell>{teamlead.phoneNumber}</TableCell>
                  <TableCell>{teamlead.eduStack?.name}</TableCell>
                  <TableCell className="flex-col">
                    <span>INACTIVE</span>
                    <br />
                    <Button
                      variant="text"
                      endIcon={<LockIconOpen />}
                      sx={{ px: 0, fontWeight: 600 }}
                      size="small"
                      color={"success"}
                      onClick={() => {
                        activateTeamLeadHandler({ teamLeadId: teamlead.id });
                      }}
                      disabled={isActivatingTeamLead}
                    >
                      Activate
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      style={{ marginRight: "10px" }}
                      onClick={() => handleViewClick(teamlead)}
                    >
                      View
                    </Button>
                    <Button
                      variant="outlined"
                      style={{ marginRight: "10px" }}
                      onClick={() => handleEditClick(teamlead)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteClick(teamlead.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={teamleads?.total || 1}
          rowsPerPage={teamleads?.pageSize || 20}
          page={page - 1}
          onPageChange={(_, newPage: number) =>
            setParams((params) => {
              params.set("page", String(newPage + 1));
              return params;
            })
          }
          rowsPerPageOptions={[0]}
        />
      </Paper>
    </>
  );
};

export default TeamleadTable;
