import { Button, Input, InputLabel, MenuItem, Select } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "../Modal/Modal.component";
import { IProjectModal } from "./ProjectModal.config";
import useAddProject from "../../hooks/mutations/useAddProject";
import useUpdateProject from "../../hooks/mutations/useUpdateProject";

const ProjectModal = ({
  title,
  showModal,
  onClose,
  project,
}: IProjectModal) => {
  const [typeId, setTypeId] = useState("");
  const { handleSubmit, register, reset, setValue } = useForm();

  const { mutate: addProject } = useAddProject();
  const { mutate: updateProject } = useUpdateProject();

  const handleCloseModal = () => {
    setTypeId("");
    reset();
    onClose();
  };

  const onSubmit = (body: any) => {
    if (project) {
      updateProject({ ...body, type: +body.type });
    } else {
      addProject({ ...body, type: +body.type });
    }
    handleCloseModal();
  };

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTypeId(event.target.value);
  };

  useEffect(() => {
    if (project) {
      for (const [key, value] of Object.entries(project)) {
        setValue(key, value);
      }
      setTypeId(project.type.toString());
    }
  }, [setValue, project]);

  return (
    <Modal onClose={handleCloseModal} showModal={showModal} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-16">
          <div className="flex flex-row gap-10">
            <div className="flex flex-col">
              <label htmlFor="name">Name</label>
              <Input id="name" required {...register("name")} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="description">Description</label>
              <Input id="description" required {...register("description")} />
            </div>
          </div>
          <div>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={typeId}
              label="Type"
              className="w-full"
              {...register("type", {
                onChange: handleSelectChange,
              })}
            >
              <MenuItem value="1">Shadow</MenuItem>
              <MenuItem value="2">Internal</MenuItem>
              <MenuItem value="3">External</MenuItem>
            </Select>
          </div>
        </div>
        <div className="flex flex-row justify-end gap-3 mt-14">
          <Button type="submit" variant="contained">
            Submit
          </Button>
          <Button variant="outlined" color="error" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ProjectModal;
