import React from "react";
import { useParams } from "react-router-dom";
import { useGetSquadLead } from "../../hooks/queries/useGetSquadLead";
import MentorInternsTable from "../../components/Mentor/MentorInternsTable";
import { useGetStack } from "../../hooks/queries/useGetStack";

const SquadLeadInterns = () => {
  const { id = "" } = useParams<{ id: string }>();

  const { data: lead, isLoading: leadLoading } = useGetSquadLead(id);
  const { data: stack } = useGetStack(lead?.eduSquadLead.eduStack.id);

  if (!lead || leadLoading) {
    return <div />;
  }

  return (
    <section className="p-5">
      <div className="flex justify-between  items-center">
        <h1 className="py-5 text-3xl font-bold">{`${lead.eduSquadLead.firstName} ${lead.eduSquadLead.lastName} - Interns`}</h1>
      </div>
      <MentorInternsTable interns={stack?.eduStack.eduInterns || []} />
    </section>
  );
};

export default SquadLeadInterns;
