import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { produce } from "immer";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useCreateSurveyConfiguration from "../../hooks/mutations/useCreateSurveyConfiguration";
import { useSurvey } from "../../hooks/queries/surveys";
import { ISurveyQuestion, SurveyType } from "../../types/types.survey";
import { CreateSurveyConfigurationParams } from "../../types/types.surveyConfiguration";
import ConfigQuestionSelector, {
  SurveyQuestionScore,
} from "./ConfigQuestionSelector.component";
import SurveyParticipantsSelector from "./SurveyParticipantsSelector.component";

interface SurveyConfigProps {
  availabilityDate: string;
  expireDate: string;
  duration: number;
  passingScore: number;
}

interface NewConfigurationProps {
  surveyId: string;
}

const NewConfiguration: FC<NewConfigurationProps> = ({ surveyId }) => {
  // STATE
  const [surveyQuestionScores, setSurveyQuestionScores] = useState<
    SurveyQuestionScore[]
  >([]);

  const [userIds, setUserIds] = useState<string[]>([]);

  // HOOKS

  const { data: surveyData } = useSurvey(surveyId);

  const { mutateAsync, isLoading: isCreatingConfiguration } =
    useCreateSurveyConfiguration();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SurveyConfigProps>();

  // EFFECTS

  useEffect(() => {
    if (!surveyData) {
      return;
    }

    const scores = surveyData.survey.surveyQuestions.map((q) => ({
      surveyQuestionId: q.id,
      score: 0,
      text: q.text,
    }));

    setSurveyQuestionScores(scores);
  }, [surveyData]);

  // VARIABLES

  const totalScore = useMemo(
    () => surveyQuestionScores.reduce((a, b) => a + b.score, 0),
    [surveyQuestionScores]
  );

  // FUNCTIONS

  const handleScoreChange = (id: string, newScore: number) => {
    const newQuestionScores = produce(surveyQuestionScores, (draft) => {
      const toChangeIndex = draft.findIndex((d) => d.surveyQuestionId === id);

      if (toChangeIndex === -1) {
        return;
      }

      draft[toChangeIndex].score = newScore;
    });

    setSurveyQuestionScores([...newQuestionScores]);
  };

  const handleQuestionRemove = (questionId: string) => {
    const deleteIndex = surveyQuestionScores.findIndex(
      (s) => s.surveyQuestionId === questionId
    );

    if (deleteIndex === -1) {
      return;
    }

    const newQuestionScores = produce(surveyQuestionScores, (draft) => {
      draft.splice(deleteIndex, 1);
    });

    setSurveyQuestionScores(newQuestionScores);
  };

  const handleQuestionAdd = (question: ISurveyQuestion) => {
    const questionIndex = surveyQuestionScores.findIndex(
      (s) => s.surveyQuestionId === question.id
    );

    if (questionIndex !== -1) {
      return;
    }

    const newQuestions = produce(surveyQuestionScores, (draft) => {
      draft.push({
        surveyQuestionId: question.id,
        score: 0,
        text: question.text,
      });
    });

    setSurveyQuestionScores(newQuestions);
  };

  const saveConfiguration: SubmitHandler<SurveyConfigProps> = async (
    data,
    e
  ) => {
    e?.preventDefault();

    const { expireDate, availabilityDate, duration, passingScore } = data;

    const payload: CreateSurveyConfigurationParams = {
      availabilityDate: moment(availabilityDate).toISOString(),
      expireDate: moment(expireDate).toISOString(),
      duration,
      ...(passingScore && { passingScore }),
      userIds: userIds,
      surveyQuestions: surveyQuestionScores.map(
        ({ surveyQuestionId, score }) => ({
          surveyQuestionId,
          score,
        })
      ),
    };

    try {
      await mutateAsync({ id: surveyId, payload });
      setSurveyQuestionScores([]);
      setUserIds([]);
      reset();
      toast.success("New configuration successfully created", {
        position: "bottom-right",
        autoClose: 3000,
        closeOnClick: true,
      });
    } catch (ex) {
      console.warn(ex);
    }
  };

  if (!surveyData) {
    return null;
  }

  return (
    <Accordion className="mb-8">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>New configuration</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(saveConfiguration)}
        >
          <TextField
            label="Availability date"
            type="datetime-local"
            variant="outlined"
            autoFocus
            InputLabelProps={{ shrink: true }}
            {...register("availabilityDate", {
              required: {
                value: true,
                message: "Availability date is required",
              },
            })}
            error={!!errors.availabilityDate?.message}
          />
          <TextField
            label="Expire date"
            type="datetime-local"
            variant="outlined"
            autoFocus
            InputLabelProps={{ shrink: true }}
            {...register("expireDate", {
              required: { value: true, message: "Expiration date is required" },
            })}
            error={!!errors.expireDate?.message}
          />
          <TextField
            label="Passing score"
            type="number"
            variant="outlined"
            autoFocus
            {...register("passingScore", {
              required: surveyData.survey.surveyType === SurveyType.Exam,
              min: 0,
              max: totalScore,
            })}
            error={!!errors.passingScore?.message}
          />
          <TextField
            label="Duration (minutes)"
            type="number"
            variant="outlined"
            autoFocus
            {...register("duration", { required: true, min: 0 })}
            error={!!errors.duration?.message}
          />
          <div className="my-4 flex flex-col gap-4">
            <SurveyParticipantsSelector
              selectedUserIds={userIds}
              onChange={setUserIds}
            />
          </div>
          <div className="my-4">
            <ConfigQuestionSelector
              options={surveyData?.survey.surveyQuestions || []}
              selectedQuestions={surveyQuestionScores}
              onScoreChange={handleScoreChange}
              onAdd={handleQuestionAdd}
              onRemove={handleQuestionRemove}
            />
          </div>
          <div className="flex justify-end mt-4">
            <Button
              type="submit"
              variant="contained"
              disabled={isCreatingConfiguration}
            >
              Save
            </Button>
          </div>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default NewConfiguration;
