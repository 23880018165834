import { useMutation } from "@tanstack/react-query";
import {
  UpdateSurveyQuestionAnswerPayload,
  updateSurveyQuestionAnswers,
} from "../../services/Edu/surveyQuestionAnswers.services";

const useUpdateSurveyQuestionAnswers = () => {
  return useMutation({
    mutationFn: (answers: UpdateSurveyQuestionAnswerPayload[]) =>
      updateSurveyQuestionAnswers(answers),
  });
};

export default useUpdateSurveyQuestionAnswers;
