import { AxiosResponse } from "axios";
import adminAxiosInstance from "../../api/admin/admin.instance";

import { EDU_INTERN_URL } from "../../api/constants";
import {
  IEduIntern,
  IPaginatedEduInterns,
  IEduInternFormPayload,
} from "../../types/types.eduInterns";
import { IPaginationInput } from "../../types/types.pagination";
import { downloadFile } from "../../helpers/downloadFile";

export const getPaginatedEduInterns = async ({
  currentPage,
  pageSize,
  searchQuery,
  status,
}: IPaginationInput): Promise<IPaginatedEduInterns> => {
  const { data }: AxiosResponse<IPaginatedEduInterns> =
    await adminAxiosInstance.get(EDU_INTERN_URL, {
      params: {
        page: currentPage,
        pageSize,
        searchQuery,
        status,
      },
    });
  return data;
};

export const deleteEduIntern = async (internId: string) => {
  const { data }: AxiosResponse = await adminAxiosInstance.delete(
    `${EDU_INTERN_URL}/${internId}`
  );
  return data;
};

export const createEduIntern = async (intern: IEduInternFormPayload) => {
  const { data }: AxiosResponse<IEduIntern> = await adminAxiosInstance.post(
    EDU_INTERN_URL,
    intern
  );
  return data;
};

export const updateEduIntern = async (intern: IEduInternFormPayload) => {
  const { data }: AxiosResponse<IEduIntern> = await adminAxiosInstance.put(
    `${EDU_INTERN_URL}/${intern.id}`,
    intern
  );
  return data;
};

export const activateIntern = async (internId: string, callbackUrl: string) => {
  const { data }: AxiosResponse = await adminAxiosInstance.post(
    `${EDU_INTERN_URL}/${internId}/activate`,
    {
      callbackUrl,
    }
  );
  return data;
};

export const exportInterns = async () => {
  const response = await adminAxiosInstance.get(`${EDU_INTERN_URL}/export`, {
    responseType: "blob",
    headers: {
      Accept: "*/*",
    },
  });

  downloadFile(response, "EduInterns.xlsx");
};
