import { NavLink } from "react-router-dom";
import cn from "classnames";
import { liStyle, navLinkStyle } from "./Sidebar.styles";
import useAdminUser from "../../hooks/queries/useAdminUser/useAdminUser";

const Sidebar = () => {
  const adminUser = useAdminUser();

  if (!adminUser) return null;

  const { role } = adminUser;

  const navLinkClassname = (isActive: boolean) =>
    `${cn(navLinkStyle)} ${isActive ? "bg-gray-100 rounded-md" : ""}`;

  const navConfig = [
    { to: "/", label: "Home" },
    { to: "/groups", label: "Groups" },
    { to: "/interns", label: "Interns", skip: role === "SkillWill" },
    { to: "/mentors", label: "Mentors" },
    { to: "/squad-leads", label: "Squad leads" },
    { to: "/team-leads", label: "Team leads" },
    { to: "/stacks", label: "Stacks" },
    { to: "/projects", label: "Projects" },
    { to: "/surveys", label: "Questionnaires/Exams" },
    { to: "/components", label: "Components" },
  ];

  return (
    <nav className="fixed left-0 top-0 w-1/5 h-[calc(100vh-60px)] mt-[60px] shadow-xl p-5 bg-white">
      <ul className="flex flex-col gap-3">
        {navConfig.map((item) => {
          return item.skip ? null : (
            <li key={item.to} className={cn(liStyle)}>
              <NavLink
                to={item.to}
                className={({ isActive }) => navLinkClassname(isActive)}
              >
                {item.label}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;
