import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { STACK_URL } from "../api/constants";
import { IUpdateStackParams } from "../types/types.stacks";

export const updateStack = async (body: IUpdateStackParams) => {
  const { data }: AxiosResponse = await adminAxiosInstance.put(`${STACK_URL}/${body.id}`, {
    ...body,
  });
  return data;
};
