import { useGetGroup } from "../../hooks/queries/useGetGroup";
import { useParams } from "react-router-dom";
import useGetComponents from "../../hooks/queries/useGetComponents";
import RatingsTable from "../../components/Ratings";

const GroupRatings = () => {
  const { id = "" } = useParams<{ id: string }>();

  const { data, isLoading } = useGetGroup(id);
  const { data: components, isLoading: componentsIsLoading } = useGetComponents(
    { page: 1 },
  );

  if (!data || !components || isLoading) {
    return <div />;
  }

  return (
    <div className="p-5">
      <h1 className="text-3xl font-bold mb-8">{`${data.eduGroup.name} - Interns`}</h1>
      <RatingsTable
        interns={data.eduGroup.eduInterns.filter((intern) =>
          Boolean(intern.userId),
        )}
        components={components.components}
      />
    </div>
  );
};

export default GroupRatings;
