import { useMutation } from "@tanstack/react-query";
import { deleteSurveyQuestionAnswer } from "../../services/Edu/surveyQuestionAnswers.services";

const useDeleteSurveyQuestionAnswer = () => {
  return useMutation({
    mutationFn: (answerId: string) => deleteSurveyQuestionAnswer(answerId),
  });
};

export default useDeleteSurveyQuestionAnswer;
