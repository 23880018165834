import React from "react";
import Button from "@mui/material/Button";

import { CreateSurveyModal, SurveysTable } from "../../components/Surveys";
import useModalState from "../../hooks/useModalState/useModalState";
import { ISurvey } from "../../types/types.survey";

const Surveys = () => {
  const { modalState, updateModalState } = useModalState<ISurvey>();

  const handleOpenModal = () => {
    updateModalState({ open: true });
  };

  const handleEditPress = (survey: ISurvey) => {
    updateModalState({ open: true, payload: survey });
  };

  return (
    <div className="p-5">
      <div className="flex items-center justify-between">
        <h1 className="py-5 text-3xl font-bold">Questionnaires/Exams</h1>
        <Button variant="contained" onClick={handleOpenModal}>
          Add Survey
        </Button>
        <CreateSurveyModal
          showModal={modalState.open}
          onClose={() => updateModalState({ open: false })}
          survey={modalState.payload}
        />
      </div>
      <SurveysTable onPressEdit={handleEditPress} />
    </div>
  );
};

export default Surveys;
