import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IUpdateStackParams } from "../../types/types.stacks";
import { STACK_QUERY_KEY } from "../queries/useGetStacks/useGetStack.config";
import { updateStack } from "../../services/updateStack.services";

const useUpdateStack = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IUpdateStackParams) => updateStack(body),
    onSuccess: () => {
      queryClient.refetchQueries([STACK_QUERY_KEY]);
    },
  });
};

export default useUpdateStack;
