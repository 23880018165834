import { useParams } from "react-router-dom";
import GroupAnalyticsTable from "./GroupAnalyticsTable";
import { Box, CircularProgress, Input } from "@mui/material";
import { useGetUserAnalytics } from "../../hooks/queries/useGetUserAnalytics";
import { ChangeEvent, useState } from "react";
import { dateFormatter } from "../../helpers";

const GroupAnalytics = () => {
  const { id = "" } = useParams<{ id: string }>();
  const [currentDate, setCurrentDate] = useState(() =>
    dateFormatter(new Date().toString())
  );
  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentDate(event.target.value);
  };

  const { data, isLoading, isError } = useGetUserAnalytics(id, currentDate);

  if (isLoading) {
    return (
      <CircularProgress
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    );
  }

  if (isError) {
    return <div>Error</div>;
  }
  return (
    <div className="p-5">
      <h1 className="text-3xl font-bold mb-8">Analytics</h1>
      <Box
        sx={{
          width: 150,
          marginLeft: "auto",
          marginBottom: "25px",
        }}
      >
        <div className="flex flex-row gap-10 mt-2">
          <div className="flex flex-col">
            <label htmlFor="date">Date</label>
            <Input
              type="date"
              id="date"
              value={currentDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
      </Box>
      <GroupAnalyticsTable interns={data?.getUsersAnalytics!} />
    </div>
  );
};

export default GroupAnalytics;
