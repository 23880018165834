export const BASE_AUTH_URL = process.env.REACT_APP_SERVER_IDENTITY_API_ENDPOINT;
export const BASE_ADMIN_URL = process.env.REACT_APP_SERVER_ADMIN_API_ENDPOINT;

export const AUTH_URL = "/auth";
export const USER_URL = "/User";
export const EDU_INTERN_URL = "/EduIntern";

export const GROUP_URL = "/EduGroup";

export const TEAMLEADS_URL = "/EduTeamLead";

export const STACK_URL = "/EduStack";

export const PROJECT_URL = "/EduProject";

export const MENTOR_URL = "/EduMentor";

export const REGION_URL = "/Region";

export const SQUAD_LEAD_URL = "/EduSquadLead";

export const SURVEY_URL = "/Survey";

export const SURVEY_CONFIGURATION_URL = "/SurveyConfiguration";

export const SURVEY_QUESTION_URL = "/SurveyQuestion";

export const SURVEY_QUESTION_ANSWER_URL = "/SurveyQuestionAnswer";

export const SURVEY_SUBMISSION_URL = "/SurveySubmission";

export const COMPONENT_URL = "/Component";

export const COMPONENT_RATING_URL = "/ComponentRating";

export const COURSE_OVERVIEW_URL = "/CourseOverview";
