import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { COMPONENT_RATING_URL } from "../api/constants";
import {
  IGetComponentRatings,
  IUseGetComponentRatingsParams,
} from "../types/types.componentRatings";

export const getComponentRatings = async ({
  userId,
  componentId,
  ratingDate,
  page = 1,
  pageSize = 20,
}: IUseGetComponentRatingsParams) => {
  const { data }: AxiosResponse<IGetComponentRatings> =
    await adminAxiosInstance.get(`${COMPONENT_RATING_URL}`, {
      params: {
        userId,
        componentId,
        page,
        pageSize,
        ratingDate,
      },
    });
  return data;
};
