import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { GROUP_URL } from "../api/constants";
import { IUpdateGroup } from "../types/types.groups";

export const updateGroup = async (body:IUpdateGroup) => {
  const { data }: AxiosResponse =
    await adminAxiosInstance.put(`${GROUP_URL}/${body.id}`, {
      ...body
    });
  return data;
};
