import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { COMPONENT_RATING_URL } from "../api/constants";

export const addComponentRating = async (body: any) => {
    const { data }: AxiosResponse = await adminAxiosInstance.post(
        `${COMPONENT_RATING_URL}`,
        {
            ...body,
        },
    );
    return data;
};
