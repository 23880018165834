import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { COURSE_OVERVIEW_URL } from "../api/constants";

export const deleteCourseOverviewSubject = async (id: string) => {
  const { data }: AxiosResponse = await adminAxiosInstance.delete(
    `${COURSE_OVERVIEW_URL}/${id}`,
  );
  return data;
};
