import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SQUAD_LEADS_QUERY_KEY } from "./useGetSquadLeads.config";
import { DEFAULT_PAGE_SIZE } from "../../../components/Mentor/MentorsTable/MentrosTable.config";
import { useCallback, useState } from "react";
import { IGetSquadLeads } from "../../../types/types.squadLeads";
import { getSquadLeads } from "../../../services/Edu/squadLeads.services";
import { useDebouncedState } from "../../debounce/useDebounce";

interface IUseGetSquadLeads {
  page: number;
  pageSize?: number;
  initialQuery?: string;
}

export default function useGetSquadLeads(props?: IUseGetSquadLeads) {
  const {
    pageSize = DEFAULT_PAGE_SIZE,
    page = 1,
    initialQuery = "",
  } = props || {};
  const [searchQuery, setSearchQuery] = useDebouncedState(
    initialQuery as string,
    500
  );

  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery<IGetSquadLeads>({
    queryKey: [SQUAD_LEADS_QUERY_KEY, page, pageSize, searchQuery],
    queryFn: () => getSquadLeads(page, pageSize, searchQuery),
    onError: () => {
      queryClient.setQueryData([SQUAD_LEADS_QUERY_KEY], null);
    },
    keepPreviousData: true,
  });

  const updateSearchQuery = useCallback(
    (query: string) => {
      setSearchQuery(query);
    },
    [setSearchQuery]
  );

  return {
    data,
    isLoading,
    error,
    updateSearchQuery,
  };
}
