import { useAuth } from "../../hooks/useAuth/useAuth";
import useAdminUser from "../../hooks/queries/useAdminUser/useAdminUser";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const adminUser = useAdminUser();
  const { signout } = useAuth();
  const navigate = useNavigate();

  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  const handleLogout = () => {
    signout();
    handleNavigateToLogin();
  };

  return (
    <nav className="w-full flex justify-between items-center bg-white shadow-lg px-6 py-4 fixed top-0 left-0 z-20">
      <div className="flex items-center">
        <span className="font-bold text-lg">Admin Panel</span>
      </div>
      <div className="flex items-center">
        <span className="mr-4">{adminUser ? `${adminUser.email}` : ""}</span>

        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleLogout}
        >
          Sign out
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
