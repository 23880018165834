import { useQuery } from "@tanstack/react-query";
import { getUserAnalyticsService } from "../../../services/getUserAnalytics.services";

export const useGetUserAnalytics = (groupId: string, ratingDate: string) => {
  const {
    data: { data } = {},
    isLoading,
    isError,
  } = useQuery({
    queryFn: () => getUserAnalyticsService(groupId, ratingDate),
    queryKey: ["getUserAnalytics", ratingDate],
  });

  return {
    data,
    isError,
    isLoading,
  };
};
