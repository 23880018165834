import { useQuery } from "@tanstack/react-query";
import { getPaginatedEduInterns } from "../../../services/Edu/interns.services";
import { EDU_INTERNS_QUERY_KEY } from "./useEduInterns.config";

const useEduInterns = (currentPage: number = 1, pageSize: number = 20, searchQuery: string = '', status: string = '') => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [EDU_INTERNS_QUERY_KEY, { currentPage, searchQuery, status }],
    keepPreviousData: true,
    queryFn: () => getPaginatedEduInterns({ currentPage, pageSize, searchQuery, status }),
  });

  const total = data?.total || 0;
  const maxPostPage: number = total ? Math.ceil(total / pageSize) : 0;

  return {
    data,
    isLoading,
    isError,
    maxPostPage,
  };
};

export default useEduInterns;
