import {
  IEduInternStatus,
  IEduInternFormPayload,
  IEduIntern,
} from "../../../types/types.eduInterns";

interface ITextFieldData {
  fieldName: keyof IEduInternFormPayload;
  label: string;
  type: string;
  required: boolean;
  InputLabelProps?: {
    shrink: boolean;
  };
}

interface IInternStatus {
  value: number;
  label: IEduInternStatus;
}

interface IInternFormFields {
  textFields: ITextFieldData[];
  internStatus: IInternStatus[];
}

export interface IEduInternFormProps {
  onClose: () => void;
  eduIntern?: IEduIntern | null;
  onEditIntern: (intern: IEduInternFormPayload) => void;
  onCreateIntern: (intern: IEduInternFormPayload) => void;
  isLoading: boolean;
}

export const INTERN_FORM_KEYS: Array<keyof IEduInternFormPayload> = [
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "stackId",
  "mentorId",
  "dateOfBirth",
  "idNumber",
  "eduInternStatus",
  "internshipStartDate",
  "internshipEndDate",
  "groupId",
  "regionId",
  "teamLeadId",
  "projectIds",
  "squadLeadId",
  "userStatus",
];

export const INTERN_FORM_FIELDS: IInternFormFields = {
  textFields: [
    {
      fieldName: "firstName",
      label: "First Name",
      type: "text",
      required: true,
    },
    {
      fieldName: "lastName",
      label: "Last Name",
      type: "text",
      required: true,
    },
    {
      fieldName: "email",
      label: "Email",
      type: "email",
      required: true,
    },
    {
      fieldName: "phoneNumber",
      label: "Phone Number",
      type: "number",
      required: true,
    },
    {
      fieldName: "dateOfBirth",
      label: "Date of birth",
      type: "date",
      InputLabelProps: { shrink: true },
      required: true,
    },
    {
      fieldName: "internshipStartDate",
      label: "Internship start date",
      type: "date",
      InputLabelProps: { shrink: true },
      required: true,
    },
    {
      fieldName: "internshipEndDate",
      label: "Internship end date",
      type: "date",
      InputLabelProps: { shrink: true },
      required: true,
    },
    {
      fieldName: "idNumber",
      label: "ID Number",
      type: "number",
      required: true,
    },
  ],
  internStatus: [
    { value: 1, label: "Not Started" },
    { value: 2, label: "In Process" },
    { value: 3, label: "Canceled" },
    { value: 4, label: "Ready to Level Up" },
    { value: 5, label: "Junior" },
  ],
};
