import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SurveyQuestionsEditor } from "../../components/SurveyQuestions";
import NewConfiguration from "../../components/SurveyQuestions/NewConfiguration.component";
import { useSurvey } from "../../hooks/queries/surveys";
import ExistingConfigurations from "../../components/SurveyQuestions/ExistingConfigurations.component";

const SurveyConfiguration = () => {
  const { id = "" } = useParams();

  const { data: surveyData, isLoading: surveyLoading } = useSurvey(id);

  useEffect(() => {
    if (!surveyData) {
      return;
    }
  }, [surveyData]);

  if (surveyLoading || !surveyData) {
    return <div />;
  }

  return (
    <div className="p-5">
      <div className="mb-8">
        <h1 className="py-5 text-3xl font-bold">{surveyData.survey.title}</h1>
      </div>
      <div className="my-4">
        <ExistingConfigurations surveyId={id} />
      </div>
      <div className="my-4">
        <NewConfiguration surveyId={id} />
      </div>
      <h2 className="text-2xl font-bold">Questions</h2>
      <SurveyQuestionsEditor
        questions={surveyData.survey.surveyQuestions}
        surveyId={id}
        surveyType={surveyData.survey.surveyType}
      />
    </div>
  );
};

export default SurveyConfiguration;
