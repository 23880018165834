import { useQuery } from "@tanstack/react-query";
import { getSurveySubmission } from "../../../services/Edu/surveySubmission.service";

export const SURVEY_SUBMISSION_QUERY_KEY = "SURVEY_SUBMISSION";

const useSurveySubmission = (submissionId: string) => {
  return useQuery({
    queryKey: [SURVEY_SUBMISSION_QUERY_KEY, submissionId],
    queryFn: () => getSurveySubmission(submissionId),
  });
};

export default useSurveySubmission;
