import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { REGION_URL } from "../api/constants";
import { IGetRegions } from "../types/types.region";

export const getRegions = async ({ page = 1, pageSize = 20 }) => {
  const { data }: AxiosResponse<IGetRegions> =
    await adminAxiosInstance.get(
      REGION_URL, {
        params: {
          page,
          pageSize
        }
      }
    );
  return data;
};
