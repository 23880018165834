import { Button } from "@mui/material";
import { useState } from "react";
import StackTable from "../../components/StackTable/StackTable.component";
import StackModal from "../../components/StackModal/StackModal.component";
import { IStack } from "../../types/types.stacks";

const Stack = () => {
  const [showModal, setShowModal] = useState(false);
  const [stack, setStack] = useState<IStack>();

  const handleCloseModal = () => {
    setShowModal(false);
    setStack(undefined);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <section className="flex justify-between p-5 items-center">
        <div>
          <h1 className="py-5 text-3xl font-bold">Stacks</h1>
          <StackModal
            showModal={showModal}
            stack={stack}
            title={stack ? "Update Stack" : "Add Stack"}
            onClose={handleCloseModal}
          />
        </div>
        <Button variant="contained" onClick={handleOpenModal}>
          Add Stack
        </Button>
      </section>
      <section className="p-5">
        <StackTable setStack={setStack} setShowModal={setShowModal} />
      </section>
    </>
  );
};

export default Stack;
