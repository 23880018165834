import { createPortal } from "react-dom";
import { IModal } from "./Modal.config";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ children, showModal, onClose, title }: IModal) => {
  if (!showModal) return null;

  const handleClose = () => {
    onClose();
  };

  return createPortal(
    <div className="fixed top-0 left-0 w-full h-full bg-gray-200/40 flex justify-center items-center z-10">
      <div className="w-full md:max-w-[50%] max-h-[80%] overflow-y-auto bg-white rounded-lg p-4">
        <div className="flex flex-row justify-between w-full border-b-[1px] border-solid border-gray-100 p-5">
          <span>{title}</span>
          <button onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="flex flex-col items-center justify-center py-6 w-full">
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
