import Button from "@mui/material/Button";
import { useState } from "react";
import ComponentsModal from "../../components/ComponentsModal";
import ComponentsTable from "../../components/ComponentsTable";
import { IComponent } from "../../types/types.components";

const Components = () => {
  const [showModal, setShowModal] = useState(false);
  const [componentInfo, setComponentInfo] = useState<IComponent>();

  const handleAddGroupClick = () => {
    setComponentInfo(undefined);
    setShowModal(true);
  };

  return (
    <>
      <section className="flex justify-between p-5 items-center">
        <h1 className="py-5 text-3xl font-bold">Components</h1>
        <Button variant="contained" onClick={handleAddGroupClick}>
          Add Components
        </Button>
      </section>
      <section className="p-5">
        <ComponentsModal
          title={componentInfo ? "Update Component" : "Add Component"}
          showModal={showModal}
          onClose={setShowModal}
          componentInfo={componentInfo}
          setComponentInfo={setComponentInfo}
        />
      </section>
      <section className="p-5">
        <ComponentsTable
          setComponent={setComponentInfo}
          setShowModal={setShowModal}
        />
      </section>
    </>
  );
};

export default Components;
