import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, TablePagination } from "@mui/material";
import DeleteModal from "../DeleteModal";
import { useState } from "react";
import useGetComponents from "../../hooks/queries/useGetComponents";
import useDeleteComponent from "../../hooks/mutations/useDeleteComponent";
import { IComponentsTable } from "./ComponentsTable.config";
import { IUpdateComponent } from "../../types/types.components";
import { useSearchParams } from "react-router-dom";

const ComponentsTable = ({ setComponent, setShowModal }: IComponentsTable) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [params, setParams] = useSearchParams();
  const page = +(params.get("page") || "1");
  const [componentId, setComponentId] = useState("");

  const { data: components } = useGetComponents({ page });
  const { mutate: deleteComponent } = useDeleteComponent();

  const handleEditClick = (component: IUpdateComponent) => {
    setComponent(component);
    setShowModal(true);
  };

  const handleDeleteClick = (id: string) => {
    setComponentId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    deleteComponent(componentId);
    setShowDeleteModal(false);
  };

  return (
    <>
      <DeleteModal
        showModal={showDeleteModal}
        handleSubmit={handleDeleteConfirm}
        onClose={() => setShowDeleteModal(false)}
      />
      <Paper className="overflow-hidden">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Max Point</TableCell>
                <TableCell>Max Percent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {components?.components?.map((component) => (
                <TableRow
                  key={component.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {component.name}
                  </TableCell>
                  <TableCell>{component.maxPoint}</TableCell>
                  <TableCell>{component.maxPercent} %</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      style={{ marginRight: "10px" }}
                      onClick={() => handleEditClick(component)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteClick(component.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={components?.total || 1}
          rowsPerPage={components?.pageSize || 20}
          page={page - 1}
          onPageChange={(_, newPage: number) =>
            setParams((params) => {
              params.set("page", String(newPage + 1));
              return params;
            })
          }
          rowsPerPageOptions={[0]}
        />
      </Paper>
    </>
  );
};

export default ComponentsTable;
