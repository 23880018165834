import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
  IMentorInternsTableProps,
  MENTOR_INTERNS_TABLE_CONFIG,
} from "./MentorInterns.config";

const MentorInternsTable = ({
  interns,
  groupName,
}: IMentorInternsTableProps) => {
  return (
    <Paper className="overflow-hidden">
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="bg-gray-100 font-bold">
            <TableRow>
              {MENTOR_INTERNS_TABLE_CONFIG.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {interns.map((mentor) => {
              return (
                <TableRow
                  className="cursor-pointer"
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={mentor.id}
                >
                  <TableCell>{mentor.firstName}</TableCell>
                  <TableCell>{mentor.lastName}</TableCell>
                  <TableCell>{mentor.email}</TableCell>
                  <TableCell>{mentor.phoneNumber}</TableCell>
                  <TableCell>{groupName || mentor.eduGroup?.name}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MentorInternsTable;
