import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSurveyConfigurations } from "../../hooks/queries/surveys";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IUser } from "../../types/types.user";
import { getUser } from "../../services/user.services";
import moment from "moment";

const SurveySubmissions = () => {
  const { id = "", configId = "" } = useParams();

  const [users, setUsers] = useState<IUser[]>();

  const { data } = useSurveyConfigurations(id);

  const configuration = data?.surveyConfigurations.find(
    (config) => config.id === configId
  );

  useEffect(() => {
    if (!data || !configuration) {
      return;
    }
    const getUsers = async () => {
      const participantIds = configuration?.surveySubmissions.map(
        (s) => s.userId
      );

      try {
        const requests = await Promise.all(
          participantIds?.map((id) => getUser(id))
        );

        setUsers(requests.map((data) => data.user));
      } catch (ex) {
        console.warn(ex);
      }
    };

    getUsers();
  }, [data, configuration]);

  const getUserFirstAndLastNames = (id: string) => {
    if (!users) {
      return "";
    }
    const user = users.find((user) => user.id === id);

    if (!user) {
      return "";
    }

    return `${user.firstName} ${user.lastName}`;
  };

  if (!data) {
    return null;
  }

  if (!configuration) {
    return <div className="p-5">Configuration not found</div>;
  }

  return (
    <div className="p-5">
      <Paper className="overflow-hidden">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Submitted by</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Score</TableCell>
                <TableCell>Start time</TableCell>
                <TableCell>Finish time</TableCell>
                <TableCell>Result</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {configuration.surveySubmissions.map((submission) => (
                <TableRow
                  key={submission.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {getUserFirstAndLastNames(submission.userId)}
                  </TableCell>
                  <TableCell>{submission.statusText}</TableCell>
                  <TableCell>
                    {submission.totalScore}/{configuration.totalScore}
                  </TableCell>
                  <TableCell>
                    {moment(submission.startDateTime).format(
                      "DD-MM-YYYY HH:mm"
                    )}
                  </TableCell>
                  <TableCell>
                    {moment(submission.finishDateTime).format(
                      "DD-MM-YYYY HH:mm"
                    )}
                  </TableCell>
                  <TableCell>
                    {Number(submission.totalScore) >= configuration.passingScore
                      ? "Passed"
                      : "Failed"}
                  </TableCell>
                  <TableCell>
                    <Link to={`${submission.id}/answers`}>
                      <Button>View answers</Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default SurveySubmissions;
