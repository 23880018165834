import { useCallback, useState } from "react";
import { IModalState } from "./useModalState.config";

const useModalState = <T = unknown>() => {
  const [modalState, setModalState] = useState<IModalState<T>>({
    open: false,
  });

  const updateModalState = useCallback((newState: IModalState<T>) => {
    setModalState(newState);
  }, []);

  return {
    modalState,
    updateModalState,
  };
};

export default useModalState;
