import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteSurvey } from "../../services/Edu/surveys.services";
import { SURVEYS_QUERY_KEY } from "../queries/surveys/useSurveys.config";

const useDeleteSurvey = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteSurvey(id),
    onSettled: () => {
      client.invalidateQueries([SURVEYS_QUERY_KEY]);
    },
  });
};

export default useDeleteSurvey;
