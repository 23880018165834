import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import useDeleteGroup from "../../hooks/mutations/useDeleteGroup";
import { IGroupTable } from "./GroupTable.config";
import { IGroup } from "../../types/types.groups";
import DeleteModal from "../DeleteModal";
import { useState } from "react";
import FormattedData from "../FormattedData";
import useGetGroups from "../../hooks/queries/useGetGroups";
import { Input, TablePagination } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Link, useSearchParams } from "react-router-dom";
import { dateFormatter } from "../../helpers";

const GroupTable = ({ setGroupInfo, setShowModal }: IGroupTable) => {
  // const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [params, setParams] = useSearchParams();
  const page = +(params.get("page") || "1");

  const groups = useGetGroups({ page, searchQuery, startDate, endDate });
  const { mutate: deleteGroup } = useDeleteGroup();

  const handleDeleteClick = (id: string) => {
    setGroupId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    deleteGroup(groupId);
    setShowDeleteModal(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // if user clears the date, set the date to empty string
    if (event.target.valueAsDate === null) {
      setStartDate("");
    } else {
      setStartDate(dateFormatter(event.target.value));
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if user clears the date, set the date to empty string
    if (event.target.valueAsDate === null) {
      setEndDate("");
    } else {
      setEndDate(dateFormatter(event.target.value));
    }
  };

  const handleEditClick = (groupInfo: IGroup) => {
    setGroupInfo({
      ...groupInfo,
      endDate: groupInfo.endDateUtc,
      startDate: groupInfo.startDateUtc,
    });
    setShowModal(true);
  };

  return (
    <>
      <DeleteModal
        showModal={showDeleteModal}
        handleSubmit={handleDeleteConfirm}
        onClose={() => setShowDeleteModal(false)}
      />
      <Box
        sx={{
          width: 300,
          marginLeft: "auto",
          marginBottom: "20px",
        }}
      >
        <TextField
          id="outlined-basic3"
          label="Search"
          variant="outlined"
          onChange={handleNameChange}
        />
        <div className="flex flex-row gap-10 mt-2">
          <div className="flex flex-col">
            <label htmlFor="description">Start Date</label>
            <Input type="date" onChange={handleStartDateChange} />
          </div>
          <div className="flex flex-col">
            <label htmlFor="description">End Date</label>
            <Input type="date" onChange={handleEndDateChange} />
          </div>
        </div>
      </Box>
      <Paper className="overflow-hidden">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Start date</TableCell>
                <TableCell align="right">End date</TableCell>
                <TableCell align="center">Interns</TableCell>
                <TableCell align="left">Ratings</TableCell>
                <TableCell align="left">Analytics</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups?.eduGroups?.map((group) => (
                <TableRow
                  key={group.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {group.name}
                  </TableCell>
                  <TableCell align="right">{group.description}</TableCell>
                  <TableCell align="right">
                    <FormattedData>{group.startDateUtc}</FormattedData>
                  </TableCell>
                  <TableCell align="right">
                    <FormattedData>{group.endDateUtc}</FormattedData>
                  </TableCell>
                  <TableCell>
                    <Button sx={{ px: 0 }}>
                      <Link to={`/group/${group.id}/interns`}>
                        View interns
                      </Link>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button sx={{ px: 0 }}>
                      <Link to={`/group/${group.id}/ratings`}>
                        View Ratings
                      </Link>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button sx={{ px: 0 }}>
                      <Link to={`/group/${group.id}/analytics`}>
                        View Analytics
                      </Link>
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      style={{ marginRight: "10px" }}
                      onClick={() => handleEditClick(group)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteClick(group.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={groups?.total || 1}
          rowsPerPage={groups?.pageSize || 20}
          page={page - 1}
          onPageChange={(_, newPage: number) =>
            setParams((params) => {
              params.set("page", String(newPage + 1));
              return params;
            })
          }
          rowsPerPageOptions={[0]}
        />
      </Paper>
    </>
  );
};

export default GroupTable;
