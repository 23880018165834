import { Button } from "@mui/material";
import { useState } from "react";
import ProjectTable from "../../components/ProjectTable/ProjectTable.component";
import { IEduProjects } from "../../types/types.projects";
import ProjectModal from "../../components/ProjectModal/ProjectModal.component";

const Project = () => {
  const [showModal, setShowModal] = useState(false);
  const [project, setProject] = useState<IEduProjects>();

  const handleCloseModal = () => {
    setShowModal(false);
    setProject(undefined);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <section className="flex justify-between p-5 items-center">
        <div>
          <h1 className="py-5 text-3xl font-bold">Projects</h1>
          <ProjectModal
            showModal={showModal}
            project={project}
            title={project ? "Update Project" : "Add Project"}
            onClose={handleCloseModal}
          />
        </div>
        <Button variant="contained" onClick={handleOpenModal}>
          Add Project
        </Button>
      </section>
      <section className="p-5">
        <ProjectTable setProject={setProject} setShowModal={setShowModal} />
      </section>
    </>
  );
};

export default Project;
