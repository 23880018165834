import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OVERVIEWS_QUERY_KEY } from "../queries/useGetCourseOverviewSubjects/useGetCourseOverviewSubjects.config";
import { updateCourseOverviewSubject } from "../../services/updateCourseOverviewSubject.services";
import { ICourseOverviewUpdate } from "../../types/types.courseOverviews";
import { toast } from "react-toastify";

const useUpdateCourseOverviewSubject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: ICourseOverviewUpdate) =>
      updateCourseOverviewSubject(body),
    onSuccess: () => {
      queryClient.refetchQueries([OVERVIEWS_QUERY_KEY]);
      toast.success("Subject successfully updated", {
        position: "bottom-right",
        closeOnClick: true,
        autoClose: 3000,
      });
    },
    onError: () => {
      queryClient.setQueryData([OVERVIEWS_QUERY_KEY], null);
    },
  });
};

export default useUpdateCourseOverviewSubject;
