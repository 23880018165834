import { useQuery, useQueryClient } from "@tanstack/react-query";
import { COMPONENT_RATING_QUERY_KEY } from "./useGetComponentRating.config";
import { getComponentRatings } from "../../../services/getComponentRating.services";
import {
  IGetComponentRatings,
  IUseGetComponentRatingsParams,
} from "../../../types/types.componentRatings";

export default function useGetComponentRatings({
  page,
  pageSize,
  componentId,
  ratingDate,
  userId,
}: IUseGetComponentRatingsParams) {
  const queryClient = useQueryClient();
  return useQuery<IGetComponentRatings>(
    [
      COMPONENT_RATING_QUERY_KEY,
      page,
      pageSize,
      componentId,
      userId,
      ratingDate,
    ],
    {
      queryFn: () =>
        getComponentRatings({
          page,
          pageSize,
          componentId,
          ratingDate,
          userId,
        }),
      onError: () => {
        queryClient.setQueryData([], null);
      },
      enabled: !!userId,
    },
  );
}
