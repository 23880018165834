import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  dateToUtcFormatter,
  transformSquadLeadObjectForForm,
} from "../../../helpers";
import { SQUAD_LEADS_QUERY_KEY } from "../../../hooks/queries/useGetSquadLeads/useGetSquadLeads.config";
import useGetStacks from "../../../hooks/queries/useGetStacks/useGetStacks";
import {
  createSquadLead,
  editSquadLead,
} from "../../../services/Edu/squadLeads.services";
import { ISquadLeadFormPayload } from "../../../types/types.squadLeads";
import { ISquadLeadForm } from "./SquadLeadForm.config";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { eduSquadLeadSeniorityOptions } from "../../../types/types.eduSquadLead";

const SquadLeadForm = ({ squadLead, onClose }: ISquadLeadForm) => {
  const [stackPageSize, setStackPageSize] = useState(20);

  const defaultFormValues = useMemo(
    () => (squadLead ? transformSquadLeadObjectForForm(squadLead) : {}),
    [squadLead]
  );

  const queryClient = useQueryClient();

  const { mutate: addSquadLeadHandler, isLoading: creatingMentor } =
    useMutation<void, AxiosError, ISquadLeadFormPayload>({
      mutationFn: (payload) => createSquadLead(payload),
      onSettled: () => {
        queryClient.invalidateQueries([SQUAD_LEADS_QUERY_KEY]);
      },
    });

  const { mutate: editSquadLeadHandler, isLoading: editingMentor } =
    useMutation<void, AxiosError, ISquadLeadFormPayload>({
      mutationFn: (payload) => editSquadLead(payload),
      onSettled: () => {
        queryClient.invalidateQueries([SQUAD_LEADS_QUERY_KEY]);
      },
    });

  const stacks = useGetStacks({
    page: 1,
    pageSize: stackPageSize,
  });

  useEffect(() => {
    if (!stacks) return;
    setStackPageSize(stacks.total);
  }, [stacks]);

  const { control, handleSubmit, getValues } = useForm<ISquadLeadFormPayload>({
    defaultValues: defaultFormValues,
  });

  const onSubmit = (data: ISquadLeadFormPayload) => {
    const body: ISquadLeadFormPayload = { ...data };
    for (let key in body) {
      if (body[key as keyof ISquadLeadFormPayload] === "") {
        // @ts-ignore
        body[key] = null;
      }
    }

    const { dateOfBirthUtc } = body;
    body.dateOfBirthUtc = dateToUtcFormatter(dateOfBirthUtc);
    // @ts-ignore
    body.eduSquadLeadSeniority = Number(body.eduSquadLeadSeniority);
    if (squadLead) {
      body.id = squadLead.id;
      body.stackId = body.eduStackId;
      delete body.eduStackId;
      editSquadLeadHandler(body, {
        onSuccess: () => {
          onClose();
        },
      });
    } else {
      addSquadLeadHandler(body, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  };

  return (
    <form
      className="flex flex-col gap-5 w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="firstName"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            required
            type="text"
            label="First Name"
            // InputLabelProps={item.InputLabelProps}
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            required
            type="text"
            label="Last Name"
            // InputLabelProps={item.InputLabelProps}
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            required
            type="email"
            label="Email"
            // InputLabelProps={item.InputLabelProps}
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="dateOfBirthUtc"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="date"
            label="Date of birth"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="tel"
            label="Phone number"
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="idNumber"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            label="ID number"
            variant="outlined"
            fullWidth
          />
        )}
      />

      <Controller
        name="eduStackId"
        control={control}
        render={({ field }) => (
          <FormControl {...field}>
            <FormLabel id="intern-status-radio-buttons-group">Stack</FormLabel>
            <Select {...field}>
              {stacks?.eduStacks.map((stack) => (
                <MenuItem value={stack.id}>{stack.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Controller
        name="eduSquadLeadSeniority"
        control={control}
        render={({ field }) => (
          <FormControl {...field}>
            <FormLabel id="eduSquadLead-seniority-radio-buttons-group">
              Seniority
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="eduSquadLead-seniority-radio-buttons-group"
            >
              {eduSquadLeadSeniorityOptions.map((item) => (
                <FormControlLabel
                  checked={
                    Number(getValues("eduSquadLeadSeniority")) === item.value
                  }
                  key={item.id}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />

      <div className="flex gap-2 flex-row items-center justify-end">
        <Button
          className="block"
          type="button"
          variant="outlined"
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          disabled={creatingMentor || editingMentor}
          type="submit"
          variant="contained"
          color="success"
        >
          SUBMIT
        </Button>
      </div>
    </form>
  );
};

export default SquadLeadForm;
