import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IGetGroupsParams } from "../../../types/types.groups";
import { REGIONS_QUERY_KEY } from "./useRegions.config";
import { getRegions } from "../../../services/getRegions.services";
import { IGetRegions } from "../../../types/types.region";

export default function useGetRegions({
  page = 1,
  pageSize = 20,
}: IGetGroupsParams) {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery<IGetRegions>({
    queryKey: [REGIONS_QUERY_KEY, page, pageSize],
    queryFn: () => getRegions({ page, pageSize }),
    onError: () => {
      queryClient.setQueryData([REGIONS_QUERY_KEY], null);
    },
  });

  return {
    data,
    isLoading,
  };
}
