import { useEffect, useMemo, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  IMentorForm,
  eduMentorSeniorityOptions,
  eduMentorStatusOptions,
} from "./MentorForm.config";
import { Controller, useForm } from "react-hook-form";
import {
  EduMentorSeniority,
  EduMentorStatus,
  IMentorFormPayload,
} from "../../../types/types.mentors";
import {
  dateToUtcFormatter,
  transformMentorObjectForForm,
} from "../../../helpers";
import useGetStacks from "../../../hooks/queries/useGetStacks/useGetStacks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createMentor,
  editMentor,
} from "../../../services/Edu/mentors.services";
import { AxiosError } from "axios";
import { MENTORS_QUERY_KEY } from "../../../hooks/queries/useGetMentors/useGetMentors.config";

const MentorForm = ({ mentor, onClose }: IMentorForm) => {
  const [stackPageSize, setStackPageSize] = useState(20);

  const defaultFormValues = useMemo(
    () => (mentor ? transformMentorObjectForForm(mentor) : {}),
    [mentor]
  );

  const queryClient = useQueryClient();

  const { mutate: addNewMentor, isLoading: creatingMentor } = useMutation<
    void,
    AxiosError,
    IMentorFormPayload
  >({
    mutationFn: (payload) => createMentor(payload),
    onSettled: () => {
      queryClient.invalidateQueries([MENTORS_QUERY_KEY]);
    },
  });

  const { mutate: editExistingMentor, isLoading: editingMentor } = useMutation<
    void,
    AxiosError,
    IMentorFormPayload
  >({
    mutationFn: (payload) => editMentor(payload),
    onSettled: () => {
      queryClient.invalidateQueries([MENTORS_QUERY_KEY]);
    },
  });

  const stacks = useGetStacks({
    page: 1,
    pageSize: stackPageSize,
  });

  useEffect(() => {
    if (!stacks) return;
    setStackPageSize(stacks.total);
  }, [stacks]);

  const { control, handleSubmit, getValues } = useForm<IMentorFormPayload>({
    defaultValues: defaultFormValues,
  });

  const onSubmit = (data: IMentorFormPayload) => {
    const body: IMentorFormPayload = { ...data };
    for (let key in body) {
      if (body[key as keyof IMentorFormPayload] === "") {
        // @ts-ignore
        body[key] = null;
      }
    }

    const { dateOfBirth } = body;
    body.dateOfBirth = dateToUtcFormatter(dateOfBirth);
    body.mentorSeniority = Number(body.mentorSeniority) as EduMentorSeniority;
    body.mentorStatus = Number(body.mentorStatus) as EduMentorStatus;
    if (mentor) {
      body.id = mentor.id;
      editExistingMentor(body, {
        onSuccess: () => {
          onClose();
        },
      });
    } else {
      addNewMentor(body, {
        onSuccess: () => {
          onClose();
        },
      });
    }
  };

  return (
    <form
      className="flex flex-col gap-5 w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="firstName"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            required
            type="text"
            label="First Name"
            // InputLabelProps={item.InputLabelProps}
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            required
            type="text"
            label="Last Name"
            // InputLabelProps={item.InputLabelProps}
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            required
            type="email"
            label="Email"
            // InputLabelProps={item.InputLabelProps}
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="dateOfBirth"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="date"
            label="Date of birth"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="tel"
            label="Phone number"
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="idNumber"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            label="ID number"
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Controller
        name="stackId"
        control={control}
        render={({ field }) => (
          <FormControl {...field}>
            <FormLabel id="intern-status-radio-buttons-group">Stack</FormLabel>
            <Select {...field}>
              {stacks?.eduStacks.map((stack) => (
                <MenuItem value={stack.id}>{stack.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Controller
        name="mentorSeniority"
        control={control}
        render={({ field }) => (
          <FormControl {...field} required>
            <FormLabel id="intern-status-radio-buttons-group">
              Seniority
            </FormLabel>
            <RadioGroup row aria-labelledby="intern-status-radio-buttons-group">
              {eduMentorSeniorityOptions.map((item) => (
                <FormControlLabel
                  checked={Number(getValues("mentorSeniority")) === item.value}
                  key={item.key}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />

      <Controller
        name="mentorStatus"
        control={control}
        render={({ field }) => (
          <FormControl {...field} required>
            <FormLabel id="intern-status-radio-buttons-group">Status</FormLabel>
            <RadioGroup row aria-labelledby="intern-status-radio-buttons-group">
              {eduMentorStatusOptions.map((item) => (
                <FormControlLabel
                  checked={Number(getValues("mentorStatus")) === item.value}
                  key={item.key}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
      <div className="flex gap-2 flex-row items-center justify-end">
        <Button
          className="block"
          type="button"
          variant="outlined"
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          disabled={creatingMentor || editingMentor}
          type="submit"
          variant="contained"
          color="success"
        >
          SUBMIT
        </Button>
      </div>
    </form>
  );
};

export default MentorForm;
