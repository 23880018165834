import DeleteModal from "../../DeleteModal/DeleteModal.component";
import Modal from "../../Modal/Modal.component";
import { IEduInternsModalProps } from "./MentorModal.config";
import MentorForm from "../MentorForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteMentor } from "../../../services/Edu/mentors.services";
import { MENTORS_QUERY_KEY } from "../../../hooks/queries/useGetMentors/useGetMentors.config";

const MentorModal = ({ type, onClose, mentor }: IEduInternsModalProps) => {
  const title = mentor ? "Edit mentor" : "Create a new mentor";
  const mentorId = mentor?.id || null;

  const queryClient = useQueryClient();

  const { mutate: deleteMentorHandler } = useMutation({
    mutationFn: deleteMentor,
    onSettled: () => {
      queryClient.invalidateQueries([MENTORS_QUERY_KEY]);
    },
  });

  const handleDelete = () => {
    if (!mentorId) return;
    deleteMentorHandler(mentorId, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  if (type === "PERSIST") {
    return (
      <Modal title={title} onClose={onClose} showModal>
        <MentorForm mentor={mentor} onClose={onClose} />
      </Modal>
    );
  }

  if (type === "DELETE" && mentorId) {
    return (
      <DeleteModal onClose={onClose} handleSubmit={handleDelete} showModal />
    );
  }

  return null;
};

export default MentorModal;
