import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addComponentRating } from "../../services/AddComponentRating.services";
import { toast } from "react-toastify";
import { COMPONENT_RATING_QUERY_KEY } from "../queries/useGetComponentRating/useGetComponentRating.config";
import { IUseAddComponentRatings } from "../../types/types.componentRatings";

const useAddComponentRating = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: IUseAddComponentRatings) => addComponentRating(body),
    onSuccess: () => {
      queryClient.refetchQueries([COMPONENT_RATING_QUERY_KEY]);
      toast.success("Component rating added successfully", {
        position: "bottom-right",
        autoClose: 2000,
        closeOnClick: true,
      });
    },
  });
};

export default useAddComponentRating;
