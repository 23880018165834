import { Button, Input } from "@mui/material";
import Modal from "../Modal";
import { ComponentModalType } from "./ComponentsModal.config";
import { useForm } from "react-hook-form";
import useAddComponent from "../../hooks/mutations/useAddComponent";
import { useEffect } from "react";
import useUpdateComponent from "../../hooks/mutations/useUpdateComponent";
import { IAddComponent } from "../../types/types.components";

const ComponentsModal = ({
  showModal,
  title,
  onClose,
  componentInfo,
  setComponentInfo,
}: ComponentModalType) => {
  const { mutate: addComponent } = useAddComponent();
  const { mutate: updateComponent } = useUpdateComponent();
  const { handleSubmit, register, reset, setValue } = useForm({
    defaultValues: {
      name: "",
      maxPoint: 0,
      maxPercent: 0,
    },
  });
  const handleCancelClick = () => {
    setComponentInfo(undefined);
    reset();
    onClose(false);
  };

  const onSubmit = (body: Omit<IAddComponent, "id">) => {
    if (componentInfo) {
      updateComponent({ ...body, id: componentInfo.id });
    } else {
      addComponent({ ...body });
    }

    reset();
    onClose(false);
  };

  useEffect(() => {
    if (componentInfo) {
      setValue("name", componentInfo?.name);
      setValue("maxPoint", componentInfo?.maxPoint);
      setValue("maxPercent", componentInfo?.maxPercent as number);
    }
  }, [setValue, componentInfo]);

  if (!showModal) return null;

  return (
    <Modal showModal={showModal} title={title} onClose={handleCancelClick}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col">
            <label htmlFor="name">Name</label>
            <Input id="name" required {...register("name")} />
          </div>
          <div className="flex flex-col">
            <label htmlFor="maxPoint">Max Point</label>
            <Input
              id="maxPoint"
              required
              type="number"
              {...register("maxPoint")}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="maxPercent">Max Percent</label>
            <Input
              id="maxPercent"
              required
              type="number"
              {...register("maxPercent")}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end gap-3 mt-10">
          <Button type="submit" variant="contained">
            Submit
          </Button>
          <Button variant="outlined" color="error" onClick={handleCancelClick}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ComponentsModal;
