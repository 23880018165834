import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IAuthRequestBody } from "../useAuth/useAuth.config";
import { IAdminUser } from "../../types/types.user";
import { ADMIN_USER_QUERY_KEY } from "../queries/useAdminUser/useAdminUser.config";
import { useAuth } from "../useAuth/useAuth";

const useSignin = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { authServerCall } = useAuth();

  return useMutation({
    mutationFn: (body: IAuthRequestBody) => authServerCall(body),
    onSuccess: (admin: IAdminUser) => {
      queryClient.setQueryData([ADMIN_USER_QUERY_KEY], admin);
      navigate("/");
    },
  });
};

export default useSignin;
