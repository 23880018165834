import { useState, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { MENTORS_QUERY_KEY } from "./useGetMentors.config";
import { IGetMentors } from "../../../types/types.mentors";
import { getMentors } from "../../../services/getMentors.services";
import { DEFAULT_PAGE_SIZE } from "../../../components/Mentor/MentorsTable/MentrosTable.config";
import { useDebouncedState } from "../../debounce/useDebounce";
import { useLocation, useSearchParams } from "react-router-dom";
interface IUseGetMentors {
  page: number;
  pageSize?: number;
  initialQuery?: string;
  initialStatus?: string;
  initialSeniority?: string;
}

export default function useGetMentors(props?: IUseGetMentors) {
  const {
    pageSize = DEFAULT_PAGE_SIZE,
    page = 1,
    initialQuery = "",
    initialStatus = "",
    initialSeniority = "",
  } = props || {};
  const [searchQuery, setSearchQuery] = useDebouncedState(
    initialQuery as string,
    500
  );
  const [status, setStatus] = useState<string>(initialStatus);
  const [seniority, setSeniority] = useState<string>(initialSeniority);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery<IGetMentors>({
    queryKey: [
      MENTORS_QUERY_KEY,
      page,
      pageSize,
      searchQuery,
      status,
      seniority,
    ],

    queryFn: () => getMentors(page, pageSize, searchQuery, status, seniority),
    onError: () => {
      queryClient.setQueryData([MENTORS_QUERY_KEY], null);
    },
    keepPreviousData: true,
  });

  const updateSearchQuery = useCallback(
    (query: string) => {
      setSearchQuery(query);
    },
    [setSearchQuery]
  );

  const updateStatus = useCallback((status: string) => {
    setStatus(status);
  }, []);

  const updateSeniority = useCallback((seniority: string) => {
    setSeniority(seniority);
  }, []);

  return {
    data,
    isLoading,
    updateSearchQuery,
    updateStatus,
    status,
    seniority,
    updateSeniority,
  };
}
