import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { PROJECT_URL } from "../api/constants";
import { IUpdateProject } from "../types/types.projects";

export const updateProject = async (body: IUpdateProject) => {
  const { data }: AxiosResponse = await adminAxiosInstance.put(
    `${PROJECT_URL}/${body.id}`,
    {
      ...body,
    }
  );
  return data;
};
