import { FC } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useSurveyConfigurations } from "../../hooks/queries/surveys";
import moment from "moment";
import { Link } from "react-router-dom";

interface ExistingConfigurationsProps {
  surveyId: string;
}

const ExistingConfigurations: FC<ExistingConfigurationsProps> = ({
  surveyId,
}) => {
  const { data } = useSurveyConfigurations(surveyId);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Existing configurations</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Configuration date</TableCell>
                <TableCell>Availability date</TableCell>
                <TableCell>Expire date</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Passing score</TableCell>
                <TableCell>Total score</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.surveyConfigurations.map((config) => (
                <TableRow key={config.id}>
                  <TableCell>
                    {moment(config.configurationDate).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>
                    {moment(config.availabilityDate).format("DD-MM-YYYY HH:mm")}
                  </TableCell>
                  <TableCell>
                    {moment(config.expireDate).format("DD-MM-YYYY HH:mm")}
                  </TableCell>
                  <TableCell>{`${config.duration} minutes`}</TableCell>
                  <TableCell>{config.passingScore}</TableCell>
                  <TableCell>{config.totalScore}</TableCell>
                  <TableCell>
                    <Link to={`${config.id}/submissions`}>
                      <Button>View submissions</Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExistingConfigurations;
