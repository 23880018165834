import { useMutation, useQueryClient } from "@tanstack/react-query";
import { STACK_QUERY_KEY } from "../queries/useGetStacks/useGetStack.config";
import { deleteStack } from "../../services/deleteStack.services";

const useDeleteStack = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteStack(id),
    onSuccess: () => {
      queryClient.refetchQueries([STACK_QUERY_KEY]);
    },
  });
};

export default useDeleteStack;
