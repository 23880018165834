import { AxiosResponse } from "axios";
import adminAxiosInstance from "../api/admin/admin.instance";
import { COMPONENT_RATING_URL } from "../api/constants";
import { IUseUpdateComponentRatings } from "../types/types.componentRatings";

export const updateComponentRating = async ({
  id,
  ratingDate,
  point,
}: IUseUpdateComponentRatings) => {
  const { data }: AxiosResponse = await adminAxiosInstance.put(
    `${COMPONENT_RATING_URL}/${id}`,
    {
      point,
      ratingDate,
    },
  );
  return data;
};
